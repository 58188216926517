import { GoogleSearchResult } from "@/features/documents/api/getGoogleSearchResults";
import { getHostname } from "@/features/documents/utils/url";
import { useDocumentStore } from "@/stores/document";
import { useSerpStore } from "@/stores/serp";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

function Bar(props) {
  const {
    containerClassName,
    className,
    value,
    variant,
    stripColor,
    cycleCompleted,
  } = props;
  const [overlayValue, setOverlayValue] = useState(0);

  const progressWidth = {
    width: `${value}%`,
  };

  return (
    <div
      className={clsx(
        containerClassName,
        className,
        "rounded-full inline-flex relative bg-zinc-200 dark:bg-zinc-700 overflow-hidden shadow-glow"
      )}
    >
      <div
        className={clsx(
          "shadow-glow overflow-auto rounded-full",
          variant === "lg" && "h-[47.7064px]",
          variant === "md" && "h-[calc(47.7064*0.20px)]",
          variant === "sm" && "h-[calc(47.7064*0.15px)]",
          variant === "xs" && "h-[calc(47.7064*0.10px)]",
          variant === "2xs" && "h-[calc(47.7064*0.075px)]",
          className,
          stripColor
            ? `bg-emerald-600 shadow-emerald-600`
            : "bg-zinc-200 dark:bg-zinc-700",
          cycleCompleted && "fade-out duration-1000"
        )}
        style={progressWidth}
      ></div>
      <div
        className={clsx(
          variant === "lg" && "h-[47.7064px] mb-[47.7064px]",
          variant === "md" &&
            "h-[calc(47.7064*0.20px)] mb-[calc(47.7064*0.20px)]",
          variant === "sm" &&
            "h-[calc(47.7064*0.15px)] mb-[calc(47.7064*0.15px)]",
          variant === "xs" &&
            "h-[calc(47.7064*0.10px)] mb-[calc(47.7064*0.10px)]",
          variant === "2xs" &&
            "h-[calc(47.7064*0.075px)] mb-[calc(47.7064*0.075px)]",
          className,
          "absolute z-10",
          stripColor
            ? `bg-gradient-to-r from-emerald-400`
            : "bg-zinc-200 dark:bg-zinc-700",
          cycleCompleted && "fade-out duration-1000"
        )}
        style={progressWidth}
      ></div>
    </div>
  );
}

function Spot(props) {
  const {
    containerClassName,
    className,
    value,
    stripColor,
    variant,
    shadowColor,
    backgroundColor,
    gradientColor,
    cycleCompleted,
  } = props;

  const progressWidth = {
    width: `${value}%`,
  };

  return (
    <div
      className={clsx(
        "rounded-full inline-flex relative bg-zinc-200 dark:bg-zinc-700 overflow-hidden shadow-glow",
        containerClassName,
        className
      )}
    >
      <div
        className={clsx(
          "shadow-glow overflow-auto transition-all",
          variant === "lg" && "h-[47.7064px]",
          variant === "md" && "h-[calc(47.7064*0.20px)]",
          variant === "sm" && "h-[calc(47.7064*0.15px)]",
          variant === "xs" && "h-[calc(47.7064*0.10px)]",
          variant === "2xs" && "h-[calc(47.7064*0.075px)]",
          className,
          value > 0
            ? clsx("fade-in duration-500", backgroundColor)
            : "fade-out duration-500"
        )}
      ></div>
      <div
        className={clsx(
          variant === "lg" && "h-[47.7064px] mb-[47.7064px]",
          variant === "md" &&
            "h-[calc(47.7064*0.20px)] mb-[calc(47.7064*0.20px)]",
          variant === "sm" &&
            "h-[calc(47.7064*0.15px)] mb-[calc(47.7064*0.15px)]",
          variant === "xs" &&
            "h-[calc(47.7064*0.10px)] mb-[calc(47.7064*0.10px)]",
          variant === "2xs" &&
            "h-[calc(47.7064*0.075px)] mb-[calc(47.7064*0.075px)]",
          className,
          "absolute z-10",
          stripColor
            ? clsx(`bg-gradient-to-r`, gradientColor)
            : "bg-zinc-200 dark:bg-zinc-700",
          cycleCompleted && "fade-out duration-500"
        )}
        style={progressWidth}
      ></div>
    </div>
  );
}

export const LogoSpinner = ({
  variant = "xs",
  searchResults,
  className,
  loadingText = "Searching Google...",
}: {
  variant?: "lg" | "md" | "sm" | "xs" | "2xs";
  searchResults?: GoogleSearchResult[];
  className?: string;
  loadingText?: string;
}) => {
  const [values, setValues] = useState([0, 0, 0, 0, 0]);
  const [activeBar, setActiveBar] = useState(0);
  const [cycleCompleted, setCycleCompleted] = useState(false);
  const [activeTitle, setActiveTitle] = useState(
    (searchResults && searchResults.length > 0 && searchResults[0].title) || ""
  );
  const [activeUrl, setActiveUrl] = useState(
    (searchResults && searchResults.length > 0 && searchResults[0].url) || ""
  );
  const [activeResultIndex, setActiveResultIndex] = useState(0);
  const { document: documentStore } = useDocumentStore();
  const docId = documentStore.id;
  const { serp } = useSerpStore();
  const currentSerp = serp[docId] || {
    serpLoaded: false,
  };
  const { serpLoaded } = currentSerp;

  // loop through the searchResults and update the activeTitle and activeUrl, each result is displayed for 2 seconds, only do this when searchResults exists and is length greater than 0
  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      const interval = setInterval(() => {
        if (
          searchResults &&
          searchResults.length > 0 &&
          activeResultIndex < searchResults.length
        ) {
          const nextActiveTitle = searchResults[activeResultIndex].title || "";
          const nextActiveUrl = searchResults[activeResultIndex].url || "";
          setActiveTitle(nextActiveTitle);
          setActiveUrl(nextActiveUrl);
          setActiveResultIndex((prevIndex) => {
            // Reset to 0 if we've reached the end of results
            if (prevIndex >= searchResults.length - 1) {
              return 0;
            }
            return prevIndex + 1;
          });
        }
      }, 3000); // Increased to 3 seconds to give animations time to complete
      return () => clearInterval(interval);
    }
  }, [activeResultIndex, searchResults]);

  useEffect(() => {
    const updateValues = () => {
      setValues((prevValues) => {
        const newValues = [...prevValues];

        if (cycleCompleted) {
          return newValues;
        }

        if (newValues[activeBar] < 100) {
          newValues[activeBar] += 2;
        } else {
          newValues[activeBar] = 100;
          const nextActiveBar = (activeBar + 1) % 5;
          setActiveBar(nextActiveBar);
          if (nextActiveBar === 0) {
            setCycleCompleted(true);
            // wait for 500ms before resetting the values
            setTimeout(() => {
              setValues([0, 0, 0, 0, 0]);
              setCycleCompleted(false);
            }, 500);
          }
        }
        return newValues;
      });
    };

    const interval = setInterval(updateValues, 10);
    return () => clearInterval(interval);
  }, [activeBar, cycleCompleted]);

  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-row">
          <Bar
            value={100}
            className={clsx(
              variant === "lg" && "w-[144.037px]",
              variant === "md" && "w-[calc(144.037*0.20px)]",
              variant === "sm" && "w-[calc(144.037*0.15px)]",
              variant === "xs" && "w-[calc(144.037*0.10px)]",
              variant === "2xs" && "w-[calc(144.037*0.075px)]",
              "bg-#059669"
            )}
            containerClassName={clsx(
              variant === "lg" && "ml-[90.8257px]",
              variant === "md" && "ml-[calc(90.8257*0.20px)]",
              variant === "sm" && "ml-[calc(90.8257*0.15px)]",
              variant === "xs" && "ml-[calc(90.8257*0.10px)]",
              variant === "2xs" && "ml-[calc(90.8257*0.075px)]"
            )}
            variant={variant}
          />
          <Spot
            value={values[4]}
            stripColor="red"
            shadowColor="shadow-red-400"
            gradientColor="from-red-300"
            backgroundColor="bg-red-400"
            cycleCompleted={cycleCompleted}
            className={clsx(
              variant === "lg" && "w-[47.7064px]",
              variant === "md" && "w-[calc(47.7064*0.20px)]",
              variant === "sm" && "w-[calc(47.7064*0.15px)]",
              variant === "xs" && "w-[calc(47.7064*0.10px)]",
              variant === "2xs" && "w-[calc(47.7064*0.075px)]"
            )}
            containerClassName={clsx(
              "ml-[28px]",
              variant === "lg" && "ml-[28px]",
              variant === "md" && "ml-[calc(28*0.20px)]",
              variant === "sm" && "ml-[calc(28*0.15px)]",
              variant === "xs" && "ml-[calc(28*0.10px)]",
              variant === "2xs" && "ml-[calc(28*0.075px)]"
            )}
            variant={variant}
          />
        </div>
        <div
          className={clsx(
            "flex flex-row",
            variant === "lg" && "mt-[32px]",
            variant === "md" && "mt-[calc(32*0.20px)]",
            variant === "sm" && "mt-[calc(32*0.15px)]",
            variant === "xs" && "mt-[calc(32*0.10px)]",
            variant === "2xs" && "mt-[calc(32*0.075px)]"
          )}
        >
          <Spot
            value={values[3]}
            stripColor="amber"
            shadowColor="shadow-amber-300"
            gradientColor="from-amber-200"
            backgroundColor="bg-amber-300"
            cycleCompleted={cycleCompleted}
            className={clsx(
              variant === "lg" && "w-[71.5596px]",
              variant === "md" && "w-[calc(71.5596*0.20px)]",
              variant === "sm" && "w-[calc(71.5596*0.15px)]",
              variant === "xs" && "w-[calc(71.5596*0.10px)]",
              variant === "2xs" && "w-[calc(71.5596*0.075px)]"
            )}
            containerClassName={clsx(
              "ml-[14.6789px]",
              variant === "lg" && "ml-[14.6789px]",
              variant === "md" && "ml-[calc(14.6789*0.20px)]",
              variant === "sm" && "ml-[calc(14.6789*0.15px)]",
              variant === "xs" && "ml-[calc(14.6789*0.10px)]",
              variant === "2xs" && "ml-[calc(14.6789*0.075px)]"
            )}
            variant={variant}
          />
          <Bar
            value={values[0]}
            stripColor="#059669"
            className={clsx(
              variant === "lg" && "w-[170.642px]",
              variant === "md" && "w-[calc(170.642*0.20px)]",
              variant === "sm" && "w-[calc(170.642*0.15px)]",
              variant === "xs" && "w-[calc(170.642*0.10px)]",
              variant === "2xs" && "w-[calc(170.642*0.075px)]"
            )}
            containerClassName={clsx(
              "ml-[28px]",
              variant === "lg" && "ml-[28px]",
              variant === "md" && "ml-[calc(28*0.20px)]",
              variant === "sm" && "ml-[calc(28*0.15px)]",
              variant === "xs" && "ml-[calc(28*0.10px)]",
              variant === "2xs" && "ml-[calc(28*0.075px)]"
            )}
            variant={variant}
            cycleCompleted={cycleCompleted}
          />
          <Bar
            value={100}
            className={clsx(
              variant === "lg" && "w-[77.0642px]",
              variant === "md" && "w-[calc(77.0642*0.20px)]",
              variant === "sm" && "w-[calc(77.0642*0.15px)]",
              variant === "xs" && "w-[calc(77.0642*0.10px)]",
              variant === "2xs" && "w-[calc(77.0642*0.075px)]"
            )}
            containerClassName={clsx(
              "ml-[26px]",
              variant === "lg" && "ml-[26px]",
              variant === "md" && "ml-[calc(26*0.20px)]",
              variant === "sm" && "ml-[calc(26*0.15px)]",
              variant === "xs" && "ml-[calc(26*0.10px)]",
              variant === "2xs" && "ml-[calc(26*0.075px)]"
            )}
            variant={variant}
          />
        </div>
        <div
          className={clsx(
            "flex flex-row",
            variant === "lg" && "mt-[32px]",
            variant === "md" && "mt-[calc(32*0.20px)]",
            variant === "sm" && "mt-[calc(32*0.15px)]",
            variant === "xs" && "mt-[calc(32*0.10px)]",
            variant === "2xs" && "mt-[calc(32*0.075px)]"
          )}
        >
          <Bar
            value={100}
            className={clsx(
              variant === "lg" && "w-[92.6606px]",
              variant === "md" && "w-[calc(92.6606*0.20px)]",
              variant === "sm" && "w-[calc(92.6606*0.15px)]",
              variant === "xs" && "w-[calc(92.6606*0.10px)]",
              variant === "2xs" && "w-[calc(92.6606*0.075px)]"
            )}
            variant={variant}
          />
          <Bar
            stripColor="#059669"
            value={values[1]}
            className={clsx(
              variant === "lg" && "w-[114.679px]",
              variant === "md" && "w-[calc(114.679*0.20px)]",
              variant === "sm" && "w-[calc(114.679*0.15px)]",
              variant === "xs" && "w-[calc(114.679*0.10px)]",
              variant === "2xs" && "w-[calc(114.679*0.075px)]"
            )}
            containerClassName={clsx(
              "ml-[21px]",
              variant === "lg" && "ml-[21px]",
              variant === "md" && "ml-[calc(21*0.20px)]",
              variant === "sm" && "ml-[calc(21*0.15px)]",
              variant === "xs" && "ml-[calc(21*0.10px)]",
              variant === "2xs" && "ml-[calc(21*0.075px)]"
            )}
            variant={variant}
            cycleCompleted={cycleCompleted}
          />
          <Bar
            value={100}
            className={clsx(
              variant === "lg" && "w-[148.624px] ",
              variant === "md" && "w-[calc(148.624*0.20px)] ",
              variant === "sm" && "w-[calc(148.624*0.15px)]",
              variant === "xs" && "w-[calc(148.624*0.10px)] ",
              variant === "2xs" && "w-[calc(148.624*0.075px)] "
            )}
            containerClassName={clsx(
              "ml-[23px]",
              variant === "lg" && "ml-[23px]",
              variant === "md" && "ml-[calc(23*0.20px)]",
              variant === "sm" && "ml-[calc(23*0.15px)]",
              variant === "xs" && "ml-[calc(23*0.10px)]",
              variant === "2xs" && "ml-[calc(23*0.075px)]"
            )}
            variant={variant}
          />
        </div>
        <div
          className={clsx(
            "flex flex-row",
            variant === "lg" && "mt-[32px]",
            variant === "md" && "mt-[calc(32*0.20px)]",
            variant === "sm" && "mt-[calc(32*0.15px)]",
            variant === "xs" && "mt-[calc(32*0.10px)]",
            variant === "2xs" && "mt-[calc(32*0.075px)]"
          )}
        >
          <Bar
            value={100}
            className={clsx(
              variant === "lg" && "w-[71.5596px] ",
              variant === "md" && "w-[calc(71.5596*0.20px)]",
              variant === "sm" && "w-[calc(71.5596*0.15px)]",
              variant === "xs" && "w-[calc(71.5596*0.10px)]",
              variant === "2xs" && "w-[calc(71.5596*0.075px)]"
            )}
            containerClassName={clsx(
              "ml-[14.6789px]",
              variant === "lg" && "ml-[14.6789px]",
              variant === "md" && "ml-[calc(14.6789*0.20px)]",
              variant === "sm" && "ml-[calc(14.6789*0.15px)]",
              variant === "xs" && "ml-[calc(14.6789*0.10px)]",
              variant === "2xs" && "ml-[calc(14.6789*0.075px)]"
            )}
            variant={variant}
          />
          <Spot
            stripColor="emerald"
            gradientColor="from-emerald-300"
            backgroundColor="bg-emerald-600"
            shadowColor="shadow-emerald-400"
            value={values[2]}
            cycleCompleted={cycleCompleted}
            className={clsx(
              variant === "lg" && "w-[47.7064px]",
              variant === "md" && "w-[calc(47.7064*0.20px)]",
              variant === "sm" && "w-[calc(47.7064*0.15px)]",
              variant === "xs" && "w-[calc(47.7064*0.10px)]",
              variant === "2xs" && "w-[calc(47.7064*0.075px)]"
            )}
            containerClassName={clsx(
              "ml-[29px]",
              variant === "lg" && "ml-[29px]",
              variant === "md" && "ml-[calc(29*0.20px)]",
              variant === "sm" && "ml-[calc(29*0.15px)]",
              variant === "xs" && "ml-[calc(29*0.10px)]",
              variant === "2xs" && "ml-[calc(29*0.075px)]"
            )}
            variant={variant}
          />
          <Bar
            value={100}
            className={clsx(
              variant === "lg" && "w-[200.917px]",
              variant === "md" && "w-[calc(200.917*0.20px)]",
              variant === "sm" && "w-[calc(200.917*0.15px)] ",
              variant === "xs" && "w-[calc(200.917*0.10px)]",
              variant === "2xs" && "w-[calc(200.917*0.075px)] "
            )}
            containerClassName={clsx(
              "ml-[23px]",
              variant === "lg" && "ml-[23px]",
              variant === "md" && "ml-[calc(23*0.20px)]",
              variant === "sm" && "ml-[calc(23*0.15px)]",
              variant === "xs" && "ml-[calc(23*0.10px)]",
              variant === "2xs" && "ml-[calc(23*0.075px)]"
            )}
            variant={variant}
          />
        </div>
        <div
          className={clsx(
            "flex flex-row",
            variant === "lg" && "mt-[32px]",
            variant === "md" && "mt-[calc(32*0.20px)]",
            variant === "sm" && "mt-[calc(32*0.15px)]",
            variant === "xs" && "mt-[calc(32*0.10px)]",
            variant === "2xs" && "mt-[calc(32*0.075px)]"
          )}
        >
          <Bar
            value={100}
            className={clsx(
              variant === "lg" && "w-[223.853px] ",
              variant === "md" && "w-[calc(223.853*0.20px)] ",
              variant === "sm" && "w-[calc(223.853*0.15px)]",
              variant === "xs" && "w-[calc(223.853*0.10px)]",
              variant === "2xs" && "w-[calc(223.853*0.075px)]"
            )}
            containerClassName={clsx(
              "ml-[86.2385px]",
              variant === "lg" && "ml-[86.2385px]",
              variant === "md" && "ml-[calc(86.2385*0.20px)]",
              variant === "sm" && "ml-[calc(86.2385*0.15px)]",
              variant === "xs" && "ml-[calc(86.2385*0.10px)]",
              variant === "2xs" && "ml-[calc(86.2385*0.075px)]"
            )}
            variant={variant}
          />
        </div>
      </div>

      <div className="absolute left-0 items-center flex flex-col mt-4 w-full">
        {!activeUrl && (
          <p className="text-sm font-normal mt-2 text-zinc-500 fade-in duration-1000">
            {loadingText}
          </p>
        )}
        {searchResults && activeUrl && (
          <>
            <AnimatePresence mode="wait">
              <motion.p
                key={activeResultIndex}
                className="text-xs text-zinc-500 mt-2 truncate max-w-[80%]"
                title={activeTitle}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{
                  duration: 0.5,
                  ease: "easeInOut",
                }}
              >
                {activeTitle}
              </motion.p>
            </AnimatePresence>
            
            <div className="flex flex-row items-center justify-center mt-2">
              {activeUrl && (
                <AnimatePresence mode="wait">
                  <motion.div
                    key={activeResultIndex}
                    className="flex items-center"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{
                      duration: 0.5,
                      ease: "easeInOut",
                    }}
                  >
                    <motion.img
                      className="w-3 h-3 rounded-full bg-zinc-50 dark:bg-zinc-800 mr-2"
                      src={`http://www.google.com/s2/favicons?domain=${getHostname(
                        activeUrl
                      )}`}
                      alt=""
                      initial={{ scale: 0.8 }}
                      animate={{ scale: 1 }}
                      transition={{
                        duration: 0.3,
                        delay: 0.2,
                        type: "spring",
                        stiffness: 300,
                      }}
                    />
                    <p className="text-sm font-normal text-zinc-500 overflow-ellipsis whitespace-nowrap px-2">
                      {getHostname(activeUrl)}
                    </p>
                  </motion.div>
                </AnimatePresence>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
