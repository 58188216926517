import { cn } from "@/utils/style";

export function SemiCircleProgress({
  progress,
  className,
  size = 150,
  markerRadius = 8,
  averageProgress,
  textClassName,
  label,
}: {
  progress: number;
  className?: string;
  stripColor?: string;
  size?: number;
  markerRadius?: number;
  averageProgress?: number;
  textClassName?: string;
  label?: string;
}) {
  const circleSize = size;
  const strokeWidth = 16;
  const radius = (circleSize - strokeWidth) / 2;
  const circumference = Math.PI * radius;
  const progressOffset = ((100 - progress) / 100) * circumference;

  const gradientId = "progress-gradient";
  const maskId = "progress-mask";
  const filterId = "progress-filter";

  const markerAngle = ((100 - averageProgress) / 100) * 360 - 90;
  const markerPosition = {
    x: circleSize / 2 + radius * Math.cos((markerAngle * Math.PI) / 180),
    y: circleSize / 2 + radius * Math.sin((markerAngle * Math.PI) / 180),
  };

  const progressColor = () => {
    if (progress >= 0 && progress <= 25) {
      return {
        startColor: "#f87171",
        endColor: "#dc2626",
        endOffset: "25%",
      };
    }

    if (progress > 25 && progress < 50) {
      return {
        startColor: "#fde047",
        endColor: "#facc15",
        endOffset: "50%",
      };
    }

    if (progress >= 50) {
      return {
        startColor: "#10b981",
        endColor: "#047857",
        endOffset: "100%",
      };
    }
  };

  let colorCodes = progressColor();
  let startColor = colorCodes?.startColor;
  let endColor = colorCodes?.endColor;
  let endOffset = colorCodes?.endOffset;

  return (
    <div className={cn("relative", className)}>
      <svg
        viewBox={`0 0 ${circleSize} ${circleSize / 1.5}`}
        width={circleSize}
        className="h-full w-full"
      >
        <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%">
          <stop offset="0%" stopColor={startColor} stopOpacity={0.75} />
          <stop offset={endOffset} stopColor={endColor} />
        </linearGradient>
        <path
          className="stroke-current text-zinc-200 dark:text-zinc-600"
          d={`M ${circleSize / 2} ${
            circleSize / 2
          } m 0, -${radius} a ${radius},${radius} 0 1 1 0,${2 * radius}`}
          fill="none"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          transform="rotate(-90, 75, 75)"
        />
        <path
          id="blue"
          fill="none"
          className="blue"
          stroke="url(#gradient)"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={progressOffset}
          transform="rotate(-90, 75, 75)"
          strokeLinecap="round"
          d={`M ${circleSize / 2} ${
            circleSize / 2
          } m 0, -${radius} a ${radius},${radius} 0 1 1 0,${2 * radius}`}
        />
      </svg>
      <div className="absolute inset-0 top-6 flex flex-col items-center justify-center">
        {label && (
          <span className="text-2xs font-medium text-zinc-600 dark:text-white mt-0">
            {label}
          </span>
        )}
        <span
          className={cn(
            "text-2xl font-bold text-zinc-600 dark:text-white",
            textClassName
          )}
        >
          {Math.floor(progress)}%
        </span>
      </div>
    </div>
  );
}
