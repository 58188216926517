import { useState } from "react";
import { Input, Switch } from "../../../components/Elements";
import { InstructionAsset } from "../../../components/Elements/Asset/InstructionAsset";
import { TopicTag } from "../../../components/Elements/Tag/TopicTag";

export const ReviewStep = ({
  query,
  title,
  headings,
  selectedTopics,
  instructions,
  globalInstructions,
  tone,
  customTone,
}) => {
  const [showInstructions, setShowInstructions] = useState(false);

  return (
    <div className="flex-col items-center justify-between p-4 py-0 w-[600px] max-w-[700px] mx-auto pb-4">
      <ul className="w-full">
        <li className="text-xs text-zinc-800 dark:text-zinc-200">
          <h2 className="text-sm mb-1 text-zinc-600 font-medium">
            Search Query
          </h2>
          <Input
            value={query}
            className="text-zinc-800 dark:text-zinc-200 disabled:opacity-100"
            disabled
          />
        </li>
        <li className="text-xs mt-4 text-zinc-800 dark:text-zinc-200">
          <h2 className="text-sm mt-4 mb-1 text-zinc-600 font-medium">Title</h2>{" "}
          <Input
            value={title}
            className="text-zinc-800 dark:text-zinc-200 disabled:opacity-100"
            disabled
          />
        </li>
        <li className="text-xs mt-4 text-zinc-800 dark:text-zinc-200">
          <h2 className="text-sm mt-4 mb-1 text-zinc-600 font-medium">
            Topics
          </h2>{" "}
          <div className="flex items-center border dark:border-zinc-600 flex-wrap px-2 pt-2 rounded-md max-h-36 overflow-y-scroll">
            {selectedTopics.map((topic) => {
              return (
                <TopicTag
                  key={topic.entity}
                  onRemove={() => {}}
                  showRemove={false}
                >
                  {topic.entity}
                </TopicTag>
              );
            })}
          </div>
        </li>
        <li className="text-xs mt-4 text-zinc-800 dark:text-zinc-200">
          <h2 className="text-sm mt-4 mb-1 text-zinc-600 font-medium">
            Tone of Voice
          </h2>{" "}
          <Input
            value={
              tone === "custom"
                ? customTone.length > 0
                  ? customTone.charAt(0).toUpperCase() + customTone.slice(1)
                  : "Custom"
                : tone.charAt(0).toUpperCase() + tone.slice(1)
            }
            className="text-zinc-800 dark:text-zinc-200 disabled:opacity-100"
            disabled
          />
        </li>
        <li className="mt-4 text-zinc-800 dark:text-zinc-200">
          <div className="flex items-center justify-between">
            <h2 className="text-sm text-zinc-600 font-medium">
              Outline & Instructions
            </h2>
            <div className="flex items-center space-x-2">
              <Switch
                checked={!showInstructions}
                onCheckedChange={() => setShowInstructions(!showInstructions)}
                className="ring-zinc-300"
                size="sm"
              />
              <span className="text-xs text-zinc-900 dark:text-zinc-400">
                Hide instructions
              </span>
            </div>
          </div>
          <InstructionAsset
            key={"title"}
            heading={{
              header: title,
              user_header_tag: "h1",
              instruction: JSON.stringify(globalInstructions) || "[]",
            }}
            completed={globalInstructions.length > 0}
            loading={false}
            showInstructions={showInstructions}
            onClick={function (): void {
              () => {};
            }}
          />
          {headings.map((heading) => {
            return (
              <InstructionAsset
                key={heading.header}
                heading={heading}
                completed={
                  heading.instruction &&
                  JSON.parse(heading.instruction).length > 0
                }
                loading={false}
                showInstructions={showInstructions}
                onClick={function (): void {
                  () => {};
                }}
              />
            );
          })}
        </li>
      </ul>
    </div>
  );
};
