import { Button, Tag } from "@/components/Elements";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/Elements/Card";
import { Skeleton } from "@/components/Elements/Skeleton";
import { ContentLayout } from "@/components/Layout";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useGetAiArticleUsage } from "@/features/subscription/api/getAiArticleLimit";
import { cn } from "@/utils/style";
import { Calendar, Check, Search } from "lucide-react";
import { Checkout } from "./Checkout";

interface DocumentBundle {
  documents: number;
  price: number;
  saving: number;
  popular?: boolean;
  features?: string[];
}

const documentBundles: DocumentBundle[] = [
  {
    documents: 1,
    price: 3.5,
    saving: 0,
    features: ["One-time payment"],
  },
  {
    documents: 5,
    price: 15.0,
    saving: 14,
    features: ["Save $0.50 per article"],
  },
  {
    documents: 10,
    price: 25.0,
    saving: 29,
    popular: true,
    features: ["Save $1.00 per article"],
  },
  {
    documents: 20,
    price: 45.0,
    saving: 36,
    features: ["Save $1.25 per article"],
  },
  {
    documents: 30,
    price: 60.0,
    saving: 43,
    features: ["Save $1.50 per article"],
  },
];

// Helper component to display bundle warnings
const BundleWarning = ({
  bundleSize,
  purchasesRemaining,
}: {
  bundleSize: number;
  purchasesRemaining: number;
}) => {
  if (purchasesRemaining === 0) {
    return (
      <p className="text-xs text-amber-600 dark:text-amber-400 mt-2">
        Monthly limit reached. No more purchases available.
      </p>
    );
  }

  if (bundleSize > purchasesRemaining) {
    return (
      <p className="text-xs text-amber-600 dark:text-amber-400 mt-2">
        Exceeds your remaining limit of {purchasesRemaining} credits this month.
      </p>
    );
  }

  return null;
};

const BundleCard = ({
  bundle,
  purchasesRemaining = 40,
}: {
  bundle: DocumentBundle;
  purchasesRemaining?: number;
}) => {
  const pricePerDocument = bundle.price / bundle.documents;
  const wouldExceedLimit = bundle.documents > purchasesRemaining;

  // Calculate if this bundle would push the user over the monthly limit
  const isNearLimit =
    purchasesRemaining > 0 && purchasesRemaining < bundle.documents;

  return (
    <Card
      className={cn(
        "flex flex-col relative transition-all duration-200",
        !wouldExceedLimit && "hover:border-primary/50",
        wouldExceedLimit && "opacity-70",
        bundle.popular &&
          "border-emerald-600/30 bg-upgrade-gradient-linear ring-1 ring-emerald-600/30"
      )}
    >
      {bundle.popular && (
        <Tag
          variant="small"
          className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-full mt-3 whitespace-nowrap bg-emerald-50 z-10"
          textClassName="font-medium"
          color="emerald"
        >
          Most popular
        </Tag>
      )}
      <CardHeader className="pb-4">
        <div className="flex items-center justify-between">
          <CardTitle className="text-lg text-zinc-900 dark:text-white font-semibold w-fit">
            {bundle.documents}{" "}
            {bundle.documents === 1 ? "Document" : "Documents"}
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent className="pb-4 flex-1">
        <div className="flex items-baseline gap-2">
          <div className="text-2xl font-semibold">
            ${bundle.price.toFixed(2)}
          </div>
          {bundle.saving > 0 && (
            <div className="text-sm text-zinc-500 dark:text-zinc-400">
              <s>${(bundle.documents * 3.5).toFixed(2)}</s>
            </div>
          )}
        </div>
        <p className="text-sm text-zinc-500 dark:text-zinc-400 mt-1">
          ${pricePerDocument.toFixed(2)} per document
        </p>
        {bundle.saving > 0 && (
          <p className="text-sm font-medium text-primary mt-1">
            Save {bundle.saving}%
          </p>
        )}

        {/* Display warning if bundle would exceed monthly limit */}
        <BundleWarning
          bundleSize={bundle.documents}
          purchasesRemaining={purchasesRemaining}
        />

        <ul className="mt-4 space-y-2">
          {bundle.features?.map((feature, index) => (
            <li
              key={index}
              className="flex items-center text-zinc-700 dark:text-zinc-300 text-sm"
            >
              <Check className="mr-2 h-4 w-4 text-primary flex-shrink-0" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
      </CardContent>
      <CardFooter className="pt-0">
        {wouldExceedLimit ? (
          <Button
            variant={bundle.popular ? "primaryBlur" : "outlineBlur"}
            className="w-full justify-center"
            disabled={true}
          >
            {purchasesRemaining === 0
              ? "Limit Reached"
              : `Exceeds Limit (${purchasesRemaining} Left)`}
          </Button>
        ) : (
          <Checkout
            actionType="one-time"
            planDetails={{
              name: `${bundle.documents} Article Bundle`,
              title: `${bundle.documents} Article Credits`,
              price: bundle.price,
              stripeName: `addon_${bundle.documents}`,
            }}
            successMessage={{
              title: "Article bundle purchased",
              message: "You have successfully purchased the article bundle.",
            }}
            defaultIncludeAddOn={false}
            triggerButton={
              <Button
                variant={bundle.popular ? "primaryBlur" : "outlineBlur"}
                className="w-full justify-center"
              >
                {isNearLimit
                  ? `Purchase (${purchasesRemaining} Left)`
                  : "Purchase credits"}
              </Button>
            }
            title={`Purchase ${bundle.documents} Article Credits`}
          />
        )}
      </CardFooter>
    </Card>
  );
};

const Header = ({ isEnterprise = false }: { isEnterprise?: boolean }) => {
  return (
    <div className="w-full max-w-[1100px] space-y-4">
      <div className="flex-col space-y-2">
        <h2 className="text-xl dark:text-white font-medium">
          Rank-Ready AI Document Credits
        </h2>
        {isEnterprise ? (
          <p className="text-sm text-zinc-500 dark:text-zinc-400 mt-2 min-w-[550px] mb-4">
            Your Enterprise plan includes monthly Rank-Ready AI document
            credits. Your team has access to generate high-quality,
            SEO-optimized content with no additional purchase required.
          </p>
        ) : (
          <p className="text-sm text-zinc-500 dark:text-zinc-400 mt-2 min-w-[550px] mb-4">
            Purchase credits to generate long-form, SEO-optimized content with
            Rank-Ready AI. Choose the bundle size that fits your needs, with no
            expiration date.
          </p>
        )}
      </div>
    </div>
  );
};

const KeyFeatures = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-16 w-[900px]">
      <div className="flex flex-col items-center text-center">
        <div className="rounded-full bg-primary/10 p-3 mb-4">
          <Search className="h-5 w-5 text-primary dark:text-white" />
        </div>
        <h3 className="text-base font-medium text-zinc-900 dark:text-white mb-2">
          SEO-Optimized
        </h3>
        <p className="text-sm text-zinc-600 dark:text-zinc-300">
          Generate high-quality articles designed to rank in search results
        </p>
      </div>
      <div className="flex flex-col items-center text-center">
        <div className="rounded-full bg-primary/10 p-3 mb-4">
          <Calendar className="h-5 w-5 text-primary dark:text-white" />
        </div>
        <h3 className="text-base font-medium text-zinc-900 dark:text-white mb-2">
          Flexible Usage
        </h3>
        <p className="text-sm text-zinc-600 dark:text-zinc-300">
          Credits never expire and roll over month to month
        </p>
      </div>
    </div>
  );
};

export const DocumentBundles = () => {
  const {
    data: subscriptionData,
    isLoading: isLoadingSubscription,
    isFetching: isFetchingSubscription,
  } = useSubscription();
  const {
    data: aiArticleUsage,
    isLoading: isLoadingAiArticleUsage,
    isFetching: isFetchingAiArticleUsage,
  } = useGetAiArticleUsage();

  // Consider both initial loading and refetching states
  const isLoading =
    isLoadingSubscription ||
    isLoadingAiArticleUsage ||
    isFetchingSubscription ||
    isFetchingAiArticleUsage;

  // Check if user is on enterprise plan
  const isEnterprisePlan = subscriptionData?.plan
    ?.toLowerCase()
    .includes("enterprise");

  // Access purchase limit values with defensive programming
  const monthlyPurchases = aiArticleUsage?.monthly_purchases ?? 0;
  const purchaseLimit = aiArticleUsage?.purchase_limit ?? 40;
  const purchasesRemaining = Math.max(0, purchaseLimit - monthlyPurchases);

  // Calculate purchase limit status if not provided
  const purchaseLimitReached =
    aiArticleUsage?.purchase_limit_reached ?? monthlyPurchases >= purchaseLimit;

  // Unified Enterprise card component for both current enterprise users and prospective users
  const EnterpriseCard = ({
    isCurrentEnterprise = false,
    showPurchaseLimit = false,
  }: {
    isCurrentEnterprise: boolean;
    showPurchaseLimit?: boolean;
  }) => {
    // Use emerald green styling for both versions
    const theme = {
      bgGradient:
        "from-emerald-50 to-indigo-50 dark:from-emerald-950/30 dark:to-indigo-950/30",
      border: "border-zinc-200 dark:border-zinc-800",
      cardBorder: "border-zinc-200 dark:border-zinc-800",
      titleColor: "text-zinc-600 dark:text-zinc-300",
      buttonBorder: "border-emerald-200 dark:border-emerald-800",
      buttonText: "text-emerald-700 dark:text-emerald-300",
      valueColor: "text-emerald-700 dark:text-emerald-400",
      warningBg: "bg-amber-50 dark:bg-amber-950/30",
      warningBorder: "border-amber-200 dark:border-amber-800",
      warningText: "text-amber-700 dark:text-amber-300",
    };

    // Define content based on user's plan
    const content = isCurrentEnterprise
      ? {
          title: "Enterprise Plan Document Limits",
          description:
            "Your Enterprise plan includes a customized number of Rank-Ready AI documents each month. Contact your account manager to adjust your monthly document limit or discuss other plan options.",
        }
      : showPurchaseLimit
      ? {
          title: "Monthly Purchase Limit Reached",
          description: `You have reached the limit of ${purchaseLimit} purchasable Rank-Ready AI document credits this month. To get more documents, consider upgrading to our Enterprise plan, which includes a customized number of documents each month, plus additional seats for your team.`,
        }
      : {
          title: "Enterprise Plan Document Limits",
          description:
            "Need a higher volume of Rank Ready documents? Our Enterprise plan includes a customized number of documents each month, plus additional seats for your team.",
        };

    return (
      <div
        className={`bg-gradient-to-r ${
          showPurchaseLimit ? theme.warningBg : theme.bgGradient
        } rounded-lg p-6 border ${
          showPurchaseLimit ? theme.warningBorder : theme.border
        } w-[900px] mb-4`}
      >
        <div className="flex justify-between items-start">
          <div>
            <h3
              className={`text-lg font-semibold ${
                showPurchaseLimit ? theme.warningText : theme.titleColor
              } mb-2`}
            >
              {content.title}
            </h3>
            <p className="text-sm text-zinc-600 dark:text-zinc-400 mb-4 max-w-2xl">
              {content.description}
            </p>
            <Button
              variant="outline"
              size="sm"
              className={`${
                showPurchaseLimit ? theme.warningBorder : theme.buttonBorder
              } ${showPurchaseLimit ? theme.warningText : theme.buttonText}`}
              onClick={() =>
                window.open(
                  "https://www.frase.io/contact-us/",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              Contact Sales
            </Button>
          </div>
        </div>
      </div>
    );
  };

  // Create a disabled version of the bundle card for enterprise users
  const DisabledBundleCard = ({ bundle }: { bundle: DocumentBundle }) => {
    const pricePerDocument = bundle.price / bundle.documents;

    return (
      <Card
        className={cn(
          "flex flex-col relative transition-all duration-200 opacity-50",
          bundle.popular &&
            "border-emerald-600/30 bg-upgrade-gradient-linear/50 ring-1 ring-emerald-600/30"
        )}
      >
        {bundle.popular && (
          <Tag
            variant="small"
            className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-full mt-3 whitespace-nowrap bg-emerald-50 z-10"
            textClassName="font-medium"
            color="emerald"
          >
            Most popular
          </Tag>
        )}
        <CardHeader className="pb-4">
          <div className="flex items-center justify-between">
            <CardTitle className="text-lg text-zinc-900 dark:text-white font-semibold w-fit">
              {bundle.documents}{" "}
              {bundle.documents === 1 ? "Document" : "Documents"}
            </CardTitle>
          </div>
        </CardHeader>
        <CardContent className="pb-4 flex-1">
          <div className="flex items-baseline gap-2">
            <div className="text-2xl font-semibold">
              ${bundle.price.toFixed(2)}
            </div>
            {bundle.saving > 0 && (
              <div className="text-sm text-zinc-500 dark:text-zinc-400">
                <s>${(bundle.documents * 3.5).toFixed(2)}</s>
              </div>
            )}
          </div>
          <p className="text-sm text-zinc-500 dark:text-zinc-400 mt-1">
            ${pricePerDocument.toFixed(2)} per document
          </p>
          {bundle.saving > 0 && (
            <p className="text-sm font-medium text-primary mt-1">
              Save {bundle.saving}%
            </p>
          )}
          <ul className="mt-4 space-y-2">
            {bundle.features?.map((feature, index) => (
              <li
                key={index}
                className="flex items-center text-zinc-700 dark:text-zinc-300 text-sm"
              >
                <Check className="mr-2 h-4 w-4 text-primary flex-shrink-0" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </CardContent>
        <CardFooter className="pt-0">
          <Button
            variant={bundle.popular ? "primaryBlur" : "outlineBlur"}
            className="w-full justify-center"
            disabled={true}
          >
            Included in Enterprise
          </Button>
        </CardFooter>
      </Card>
    );
  };

  // Skeleton for bundle cards when loading
  const BundleCardSkeleton = () => (
    <div className="flex flex-col rounded-lg border border-zinc-200 dark:border-zinc-800 h-[300px]">
      <div className="p-6 pb-4">
        <Skeleton className="h-6 w-32 mb-4" />
      </div>
      <div className="px-6 pb-4 flex-1">
        <Skeleton className="h-8 w-24 mb-2" />
        <Skeleton className="h-4 w-36 mb-4" />
        <Skeleton className="h-4 w-20 mb-6" />
        <div className="space-y-2">
          <div className="flex items-center">
            <Skeleton className="h-4 w-4 mr-2" />
            <Skeleton className="h-4 w-32" />
          </div>
          <div className="flex items-center">
            <Skeleton className="h-4 w-4 mr-2" />
            <Skeleton className="h-4 w-40" />
          </div>
        </div>
      </div>
      <div className="p-6 pt-0">
        <Skeleton className="h-10 w-full rounded-md" />
      </div>
    </div>
  );

  // Skeleton for enterprise card when loading
  const EnterpriseCardSkeleton = () => (
    <div className="bg-gradient-to-r from-zinc-50 to-zinc-50 dark:from-zinc-900 dark:to-zinc-900 rounded-lg p-6 border border-zinc-200 dark:border-zinc-800 w-[900px] mb-4">
      <div className="flex justify-between items-start">
        <div className="w-full">
          <Skeleton className="h-6 w-64 mb-2" />
          <Skeleton className="h-4 w-full max-w-2xl mb-4" />
          <Skeleton className="h-8 w-32" />
        </div>
      </div>
    </div>
  );

  // Skeleton for key features section when loading
  const KeyFeaturesSkeleton = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-16 w-[900px]">
      <div className="flex flex-col items-center text-center">
        <Skeleton className="h-12 w-12 rounded-full mb-4" />
        <Skeleton className="h-5 w-32 mb-2" />
        <Skeleton className="h-4 w-48" />
      </div>
      <div className="flex flex-col items-center text-center">
        <Skeleton className="h-12 w-12 rounded-full mb-4" />
        <Skeleton className="h-5 w-32 mb-2" />
        <Skeleton className="h-4 w-48" />
      </div>
    </div>
  );

  return (
    <ContentLayout>
      <div className="flex flex-col items-start h-screen pt-5 px-8 w-full space-y-6 overflow-y-scroll pb-20">
        <Header isEnterprise={isEnterprisePlan} />

        {isLoading ? (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-[900px]">
              {[1, 2, 3, 4, 5].map((i) => (
                <BundleCardSkeleton key={i} />
              ))}
            </div>
            <EnterpriseCardSkeleton />
            <KeyFeaturesSkeleton />
          </>
        ) : (
          <>
            {/* Show disabled cards for enterprise users, purchase limit message for users who hit the limit, or active cards for others */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-[900px]">
              {isEnterprisePlan ? (
                <>
                  <div className="col-span-full mb-4">
                    <div className="bg-zinc-100 dark:bg-zinc-800 rounded-lg p-4 text-center">
                      <p className="text-sm text-zinc-600 dark:text-zinc-400">
                        Individual document bundles are not available with your
                        Enterprise plan. Your plan already includes a monthly
                        document allocation.
                      </p>
                    </div>
                  </div>
                  {documentBundles.map((bundle) => (
                    <DisabledBundleCard
                      key={bundle.documents}
                      bundle={bundle}
                    />
                  ))}
                </>
              ) : purchaseLimitReached ? (
                <>
                  <div className="col-span-full mb-6">
                    <div className="bg-amber-50 dark:bg-amber-950/30 rounded-lg p-5 border border-amber-200 dark:border-amber-800 shadow-sm flex flex-col gap-4">
                      <p className="text-sm text-amber-700 dark:text-amber-300 ">
                        You've reached the limit of{" "}
                        <span className="font-semibold">{purchaseLimit}</span>{" "}
                        Rank-Ready AI documents that can be purchased this
                        month. Contact sales to learn about our Enterprise plan
                        for higher volume needs.
                      </p>
                      <Button
                        onClick={() =>
                          window.open(
                            "https://www.frase.io/contact-us/",
                            "_blank",
                            "noopener,noreferrer"
                          )
                        }
                        size="sm"
                        variant="primary"
                        className="w-fit bg-amber-600 hover:bg-amber-700"
                      >
                        Contact sales
                      </Button>
                    </div>
                  </div>
                  {documentBundles.map((bundle) => (
                    <DisabledBundleCard
                      key={bundle.documents}
                      bundle={bundle}
                    />
                  ))}
                </>
              ) : (
                documentBundles.map((bundle) => (
                  <BundleCard
                    key={bundle.documents}
                    bundle={bundle}
                    purchasesRemaining={purchasesRemaining}
                  />
                ))
              )}
            </div>

            {!purchaseLimitReached && (
              <EnterpriseCard
                isCurrentEnterprise={isEnterprisePlan}
                showPurchaseLimit={!isEnterprisePlan && purchaseLimitReached}
              />
            )}

            <KeyFeatures />
          </>
        )}
      </div>
    </ContentLayout>
  );
};
