import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";
import { Skeleton } from "@/components/Elements/Skeleton";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { useUser } from "@/features/auth";
import { getCouponStatus } from "@/features/subscription/api/getCouponStatus";
import { tiers } from "@/features/subscription/utils/tiers";
import { queryClient } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";
import { cn } from "@/utils/style";
import { zodResolver } from "@hookform/resolvers/zod";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { Elements } from "@stripe/react-stripe-js";
import { Appearance, loadStripe } from "@stripe/stripe-js";
import { AnimatePresence, motion } from "framer-motion";
import { CheckIcon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TbInfoCircle } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { CheckoutForm } from "../components/CheckoutForm";

const STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY;
const PLAN_ID = "basic_monthly_45";
const STRIPE_APPEARANCE: Appearance = {
  theme: "stripe",
  variables: {
    fontFamily:
      'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
    fontSizeBase: "14px",
    colorPrimary: "#0F172A",
    colorBackground: "#FFFFFF",
    colorText: "#18181B",
    colorDanger: "#EF4444",
    borderRadius: "6px",
    spacingUnit: "4px",
    fontWeightNormal: "400",
    fontWeightMedium: "500",
    fontWeightBold: "600",
  },
  rules: {
    ".Input": {
      border: "1px solid rgb(212, 212, 216)",
      boxShadow: "none",
      padding: "6px 12px",
      transition: "all 150ms ease",
    },
    ".Input:hover": {
      borderColor: "rgb(161, 161, 170)",
    },
    ".Input:focus": {
      borderColor: "#0F172A",
      boxShadow: "rgb(15 23 42 / 0.1) 0 0 0 4px",
    },
    ".Input--invalid": {
      borderColor: "#EF4444",
    },
    ".Label": {
      fontSize: "14px",
      fontWeight: "500",
      color: "rgb(24, 24, 27)",
      opacity: "1",
      marginBottom: "8px",
      display: "block",
    },
    ".Label--disabled": {
      cursor: "not-allowed",
      opacity: "0.7",
    },
    ".dark .Label": {
      color: "rgb(228, 228, 231)",
    },
    ".Error": {
      fontSize: "14px",
      color: "#EF4444",
    },
  },
};

const stripePromise = loadStripe(STRIPE_KEY);

// Schema for form validation
const schema = z.object({
  email: z
    .string()
    .min(1, "Email is required.")
    .email("Invalid email address."),
  fullName: z.string().min(1, "Full name is required."),
  promoCode: z.string().optional(),
});

interface CheckoutProps {
  className?: string;
  triggerButton?: React.ReactNode;
  onSuccess?: () => void;
  planId?: string;
  actionType?: "upgrade" | "downgrade" | "resubscribe" | "change" | "one-time";
  planDetails?: {
    name: string;
    title: string;
    price: number;
    stripeName: string;
  };
  pricingPeriod?: "monthly" | "yearly";
  title?: string;
  successMessage?: {
    title: string;
    message: string;
    showNotification?: boolean;
  };
  navigateOnSuccess?: boolean;
  articleId?: string;
  defaultIncludeAddOn?: boolean;
}

type Coupon = {
  id: string;
  duration: "forever" | "once" | "repeating";
  durationInMonths?: number;
  percent_off: number;
};

export const CustomerInfoFields = ({
  control,
  isLoading,
}: {
  control: any;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return (
      <div className="space-y-4">
        <div className="space-y-2">
          <Skeleton className="h-5 w-20" />
          <Skeleton className="h-10 w-full" />
        </div>
        <div className="space-y-2">
          <Skeleton className="h-5 w-24" />
          <Skeleton className="h-10 w-full" />
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-4">
      <FormField
        control={control}
        name="fullName"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel>Full name</FormLabel>
            <FormControl>
              <Input placeholder="" {...field} />
            </FormControl>
            {fieldState.error && (
              <FormMessage>{fieldState.error.message}</FormMessage>
            )}
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="email"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel>Email address</FormLabel>
            <FormControl>
              <Input type="email" placeholder="" {...field} />
            </FormControl>
            {fieldState.error && (
              <FormMessage>{fieldState.error.message}</FormMessage>
            )}
          </FormItem>
        )}
      />
    </div>
  );
};

export const PromoCodeSection = ({
  control,
  showPromoInput,
  setShowPromoInput,
  isValidatingCoupon,
  setPendingCouponValidation,
  handleCouponValidation,
  pendingCouponValidation,
  coupon,
  form,
  setCoupon,
  isLoading,
}: {
  control: any;
  showPromoInput: boolean;
  setShowPromoInput: (show: boolean) => void;
  isValidatingCoupon: boolean;
  setPendingCouponValidation: (pending: boolean) => void;
  handleCouponValidation: (code: string) => void;
  pendingCouponValidation: boolean;
  coupon: Coupon | null;
  form: any;
  setCoupon: (coupon: Coupon | null) => void;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return (
      <div className="space-y-2">
        <Skeleton className="h-5 w-24" />
        <Skeleton className="h-10 w-full" />
      </div>
    );
  }

  const promoInputVariants = {
    hidden: { opacity: 0, height: "auto" },
    visible: { opacity: 1, height: "auto" },
  };

  return (
    <AnimatePresence>
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={promoInputVariants}
        transition={{ duration: 0.2 }}
      >
        {!showPromoInput ? (
          <Button
            variant="none"
            className="px-0"
            textClassName="px-0 text-emerald-600 hover:text-emerald-700"
            onClick={() => setShowPromoInput(true)}
          >
            Add promotion code
          </Button>
        ) : (
          <FormField
            control={form.control}
            name="promoCode"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>Promotion code</FormLabel>
                <FormControl>
                  <Input
                    startIcon={
                      coupon
                        ? ((
                            <CheckIcon strokeWidth={4} />
                          ) as React.ReactElement)
                        : undefined
                    }
                    startIconClassName="h-6 text-emerald-600"
                    placeholder="Enter promotion code"
                    {...field}
                    autoFocus
                    onChange={(value) => {
                      field.onChange(value);
                      setCoupon(null);
                      setPendingCouponValidation(!!value);

                      if (fieldState.error) {
                        form.clearErrors("promoCode");
                      }
                    }}
                    onBlur={() => {
                      if (!field.value) {
                        setShowPromoInput(false);
                        setPendingCouponValidation(false);
                      }
                    }}
                    secondButtonVariant="text"
                    secondButtonClassName={cn("h-6")}
                    secondButtonDisabled={
                      !field.value ||
                      (coupon !== null && !pendingCouponValidation) ||
                      isValidatingCoupon ||
                      fieldState.error
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleCouponValidation(field.value);
                      }
                    }}
                    secondButtonText="Apply"
                    secondButtonTextClassName={cn(
                      "text-xs font-medium text-emerald-600",
                      !field.value && "opacity-0 cursor-not-allowed"
                    )}
                    secondButtonAction={() => {
                      handleCouponValidation(field.value);
                    }}
                    secondButtonIsLoading={isValidatingCoupon}
                    showSecondButtonOnHover={false}
                  />
                </FormControl>
                {coupon && (
                  <div className="text-xs">
                    {coupon.durationInMonths && (
                      <p>
                        <span>{coupon.id}</span> lasts {coupon.durationInMonths}{" "}
                        {coupon.durationInMonths === 1 ? "month" : "months"},
                        and provides a {coupon.percent_off}% discount.
                      </p>
                    )}
                    {coupon.duration === "forever" && (
                      <p>
                        <span>{coupon.id}</span> provides a {coupon.percent_off}
                        % discount forever.
                      </p>
                    )}
                  </div>
                )}

                {fieldState.error && (
                  <FormMessage>{fieldState.error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export const PriceBreakdown = ({
  planDetails,
  coupon,
  pricingPeriod,
  actionType,
  upgradeProAddOn = false,
  includeAddOn,
  setIncludeAddOn,
  isLoading,
}: {
  planDetails: NonNullable<CheckoutProps["planDetails"]>;
  coupon: Coupon | null;
  pricingPeriod: string;
  actionType?: string;
  upgradeProAddOn?: boolean;
  includeAddOn?: boolean;
  setIncludeAddOn?: (include: boolean) => void;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return (
      <div className="space-y-3 pt-4">
        <div className="flex justify-between">
          <Skeleton className="h-5 w-32" />
          <Skeleton className="h-5 w-20" />
        </div>
        <div className="flex justify-between">
          <Skeleton className="h-5 w-24" />
          <Skeleton className="h-5 w-20" />
        </div>
        <div className="flex justify-between font-medium">
          <Skeleton className="h-5 w-20" />
          <Skeleton className="h-5 w-24" />
        </div>
      </div>
    );
  }

  const addOnCost = pricingPeriod === "monthly" ? 35 : 357;
  const baseCost = planDetails.price;
  const discount = coupon ? (baseCost * (coupon.percent_off || 0)) / 100 : 0;
  const total =
    baseCost - discount + (upgradeProAddOn || includeAddOn ? addOnCost : 0);
  const isDocumentBundle = planDetails.stripeName.startsWith("addon_");

  const getPriceDisplay = () => {
    if (actionType === "one-time") return `$${baseCost}`;

    if (actionType === "change") {
      return `$${baseCost} ${
        pricingPeriod === "monthly" ? "per year" : "per month"
      }`;
    }

    return `$${baseCost} ${
      pricingPeriod === "monthly" ? "per month" : "per year"
    }`;
  };

  const getTotalLabel = () => {
    if (actionType === "one-time") return "Total cost";

    if (actionType === "change") {
      return `Total ${pricingPeriod === "monthly" ? "yearly" : "monthly"} cost`;
    }

    return `Total ${pricingPeriod === "monthly" ? "monthly" : "yearly"} cost`;
  };

  return (
    <div className="space-y-2 pt-4">
      <div className="px-4">
        <table className="min-w-full divide-y divide-zinc-200 table-fixed dark:divide-zinc-700">
          <thead className="text-left">
            <tr>
              <th className="py-1.5 font-medium text-xs text-zinc-600 dark:text-zinc-400">
                Item
              </th>
              <th className="py-1.5 font-medium text-xs text-zinc-600 dark:text-zinc-400">
                Total
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-zinc-200 dark:divide-zinc-700">
            <tr className="text-sm text-zinc-600 dark:text-zinc-400">
              <td className="py-1.5 font-medium">{planDetails.title}</td>
              <td className="py-1.5">{getPriceDisplay()}</td>
            </tr>
            {!isDocumentBundle && (
              <tr className="text-sm text-zinc-600 dark:text-zinc-400">
                <td className="py-1.5 font-medium flex items-center">
                  Rank-Ready AI Documents
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger
                        asChild={false}
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                        }}
                      >
                        <TbInfoCircle className="ml-1" />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent className="z-[10000]">
                          Add-on provides access to Rank-Ready AI Documents with
                          GPT-4 (30 articles/month - billed automatically with
                          subscription, unused credits do not roll over)
                        </TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                  </TooltipProvider>
                  {!upgradeProAddOn && (
                    <Switch
                      checked={includeAddOn}
                      onCheckedChange={setIncludeAddOn}
                      className="ml-4"
                      size="sm"
                    >
                      Rank-Ready AI Documents
                    </Switch>
                  )}
                </td>
                <td className="py-1.5">
                  {upgradeProAddOn || includeAddOn
                    ? actionType === "change"
                      ? pricingPeriod === "monthly"
                        ? `$${addOnCost} per year`
                        : `$${addOnCost} per month`
                      : pricingPeriod === "monthly"
                      ? `$${addOnCost} per month`
                      : `$${addOnCost} per year`
                    : actionType === "change"
                    ? pricingPeriod === "monthly"
                      ? "$357 per year"
                      : "$35 per month"
                    : pricingPeriod === "monthly"
                    ? "$35 per month"
                    : "$357 per year"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex items-center justify-between px-4 pb-4">
        <div className="flex flex-row items-center space-x-2">
          <p className="text-lg font-medium text-zinc-900 dark:text-white">
            {getTotalLabel()}
          </p>
          {coupon?.percent_off && (
            <div className="flex items-center font-medium text-xs bg-emerald-500/20 text-emerald-800 px-1.5 rounded dark:bg-emerald-900 dark:text-emerald-200 h-5 leading-none">
              {coupon.percent_off}% off applied
            </div>
          )}
        </div>
        <p className="text-lg font-medium text-zinc-900 dark:text-white">
          ${total.toFixed(2)}
        </p>
      </div>
    </div>
  );
};

const DialogTitleComponent = ({
  title,
  actionTypeDisplay,
  actionType,
  pricingPeriod,
  planDetails,
}: {
  title?: string;
  actionTypeDisplay: string;
  actionType?: string;
  pricingPeriod: string;
  planDetails: NonNullable<CheckoutProps["planDetails"]>;
}) =>
  title ? (
    <DialogTitle>{title}</DialogTitle>
  ) : (
    <DialogTitle>
      {actionType === "one-time"
        ? "Purchase credits"
        : `${actionTypeDisplay}to ${
            actionType === "change"
              ? pricingPeriod === "monthly"
                ? `${planDetails.name} Yearly`
                : `${planDetails.name} Monthly`
              : planDetails.name
          }`}
    </DialogTitle>
  );

const formatPlanName = (stripeName: string) => {
  // Handle addon plans first
  if (stripeName.startsWith("addon")) {
    const isYearly = stripeName.includes("yearly");
    return `Addon (${isYearly ? "Yearly" : "Monthly"})`;
  }

  // Handle regular plans
  const tier = tiers.find(
    (t) =>
      t.monthly.stripeName === stripeName || t.yearly.stripeName === stripeName
  );

  if (!tier) return stripeName;

  const isYearly = stripeName.includes("yearly");
  return `${tier.name} (${isYearly ? "Yearly" : "Monthly"})`;
};

export const Checkout = ({
  planId = PLAN_ID,
  onSuccess,
  className,
  triggerButton,
  planDetails = {
    name: "Basic Plan",
    title: "Basic",
    price: 45,
    stripeName: "basic",
  },
  actionType = "upgrade",
  pricingPeriod = "monthly",
  title,
  successMessage = {
    title: "Success",
    message: "Subscription activated successfully!",
  },
  navigateOnSuccess = true,
  articleId,
  upgradeProAddOn = false,
  defaultIncludeAddOn = true,
}: CheckoutProps) => {
  const [hasTracked, setHasTracked] = useState(false);
  const trackEvent = useTrackEvent();
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
      fullName: "",
      promoCode: "",
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [isValidatingCoupon, setIsValidatingCoupon] = useState(false);
  const [pendingCouponValidation, setPendingCouponValidation] = useState(false);
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const { addNotification } = useNotificationStore();
  const { data: user } = useUser();
  const navigate = useNavigate();

  const handleTrackPopup = async () => {
    if (!hasTracked) {
      // Determine the event name based on the checkout context
      let eventName = "new_customer_popup";

      // Track bundle purchase checkout dialog opening
      if (
        actionType === "one-time" &&
        planDetails.stripeName.startsWith("addon_") &&
        planDetails.stripeName !== "addon_1"
      ) {
        eventName = "bundle_checkout_popup";
      }
      // Track single article purchase checkout dialog opening
      else if (
        actionType === "one-time" &&
        planDetails.stripeName === "addon_1"
      ) {
        eventName = "single_article_checkout_popup";
      }

      trackEvent.mutate({
        event: eventName,
        properties: JSON.stringify({
          plan_name: planDetails.name,
          plan_id: planDetails.stripeName,
          price: planDetails.price,
          pricing_period: pricingPeriod,
          action_type: actionType,
          article_id: articleId,
          location: window.location.pathname,
        }),
      });
      setHasTracked(true);
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleTrackPopup();
    } else {
      setHasTracked(false);
    }
  }, [isOpen]);

  const handleError = useCallback(
    (error: Error | { message: string }) => {
      addNotification({
        type: "error",
        title: "Error",
        message: error.message || "Something went wrong",
      });
    },
    [addNotification]
  );

  const handleOpenChange = useCallback(
    async (open: boolean) => {
      setIsOpen(open);
      if (open) {
        form.setValue("email", user?.username || "");
        form.setValue("fullName", user?.fullName || "");
      } else {
        form.reset();
        setCoupon(null);
        setPendingCouponValidation(false);
        setShowPromoInput(false);
      }
    },
    [handleError, form, user]
  );

  const handleCouponValidation = async (couponId: string) => {
    try {
      setIsValidatingCoupon(true);

      // Prevent coupon codes for bundle purchases (one-time)
      if (actionType === "one-time") {
        setCoupon(null);
        form.setError("promoCode", {
          type: "manual",
          message:
            "Promotion codes cannot be applied to document bundle purchases.",
        });
        setPendingCouponValidation(false);
        return;
      }

      const data = await getCouponStatus(
        couponId,
        planDetails.stripeName.toLowerCase()
      );

      if (data.status === "valid") {
        form.clearErrors("promoCode");
        setCoupon({ ...data, id: couponId });
        setPendingCouponValidation(false);
      } else {
        setCoupon(null);
        let errorMessage = "The coupon code is invalid or expired.";

        switch (data.status) {
          case "invalid_plan":
            errorMessage = `This coupon is only valid for the following plans: ${data.validPlans
              ?.map((plan) => formatPlanName(plan))
              .join(", ")}`;
            break;
          case "expired":
            errorMessage = data.expirationDate
              ? `This coupon expired on ${data.expirationDate}`
              : "This coupon has expired";
            break;
          case "invalid_coupon":
            errorMessage = "The coupon code is invalid. Please try again.";
            break;
        }

        form.setError("promoCode", {
          type: "manual",
          message: errorMessage,
        });
        setPendingCouponValidation(false);
      }
    } catch (error: any) {
      setCoupon(null);
      form.setError("promoCode", {
        type: "manual",
        message:
          "An error occurred while validating the coupon. Please try again.",
      });
      setPendingCouponValidation(false);
    } finally {
      setIsValidatingCoupon(false);
    }
  };

  const actionTypeDisplay = actionType
    ? actionType.charAt(0).toUpperCase() + actionType.slice(1) + " "
    : "";

  const dialogTriggerButton = triggerButton || (
    <Button
      variant={actionType === "upgrade" ? "primary" : "outlineBlur"}
      className={cn("w-full", className)}
      size="xs"
    >
      {actionTypeDisplay}{" "}
      {actionType === "change"
        ? `to ${pricingPeriod === "monthly" ? "yearly" : "monthly"}`
        : ""}
    </Button>
  );

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{dialogTriggerButton}</DialogTrigger>
      <DialogContent className="p-0 m-0">
        <DialogHeader className="pb-0 flex items-center justify-between">
          <DialogTitleComponent
            title={title}
            actionTypeDisplay={actionTypeDisplay}
            actionType={actionType}
            pricingPeriod={pricingPeriod}
            planDetails={planDetails}
          />
          <DialogCloseButton close={() => setIsOpen(false)} />
        </DialogHeader>
        <div className="space-y-6">
          <Elements
            stripe={stripePromise}
            options={{
              mode: actionType === "one-time" ? "payment" : "subscription",
              amount: planDetails.price * 100,
              currency: "usd",
              appearance: STRIPE_APPEARANCE,
              loader: "auto",
              payment_method_types:
                actionType === "one-time" ? ["card"] : undefined,
              setup_future_usage:
                actionType === "one-time" ? "off_session" : undefined,
            }}
          >
            <CheckoutForm
              className="w-full"
              onSuccess={() => {
                if (successMessage?.showNotification !== false) {
                  addNotification({
                    type: "success",
                    title: successMessage?.title || "Success",
                    message:
                      successMessage?.message ||
                      "Purchase completed successfully.",
                  });
                }
                queryClient.invalidateQueries(["subscription"]);
                queryClient.invalidateQueries(["aiArticleUsage"]);
                setIsOpen(false);
                if (navigateOnSuccess) {
                  navigate("/app/settings/subscription?status=updating");
                }
                onSuccess?.();
              }}
              onCancel={() => setIsOpen(false)}
              onError={handleError}
              planDetails={planDetails}
              coupon={coupon}
              pricingPeriod={pricingPeriod}
              actionType={actionType}
              control={form.control}
              showPromoInput={showPromoInput}
              setShowPromoInput={setShowPromoInput}
              isValidatingCoupon={isValidatingCoupon}
              setPendingCouponValidation={setPendingCouponValidation}
              handleCouponValidation={handleCouponValidation}
              pendingCouponValidation={pendingCouponValidation}
              form={form}
              setCoupon={setCoupon}
              articleId={articleId}
              upgradeProAddOn={upgradeProAddOn}
              defaultIncludeAddOn={defaultIncludeAddOn}
            />
          </Elements>
        </div>
      </DialogContent>
    </Dialog>
  );
};
