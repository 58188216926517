import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { PageDashboardData, PageDashboardResponse } from "../types";

export const getPageDashboard = async ({
  site_url,
  page_url,
  weeks,
}: {
  site_url: string;
  page_url: string;
  weeks: string;
}): Promise<PageDashboardData> => {
  const response: PageDashboardResponse = await axios.post(
    "/content-recommendations/page-dashboard",
    {
      site_url,
      page_url,
      weeks,
    }
  );

  // Check if the response indicates an error
  if (response.status === "error") {
    throw new Error(
      response.message || "An error occurred fetching page dashboard"
    );
  }

  return response.data as PageDashboardData;
};

type QueryFnType = typeof getPageDashboard;

type UseGetPageDashboardOptions = {
  site_url: string;
  page_url: string;
  weeks: string;
  config?: QueryConfig<QueryFnType>;
};

export const useGetPageDashboard = ({
  site_url,
  page_url,
  weeks,
  config,
}: UseGetPageDashboardOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "contentOpportunities",
      "pageDashboard",
      site_url,
      page_url,
      weeks,
    ],
    queryFn: () => getPageDashboard({ site_url, page_url, weeks }),
  });
};
