import { legacyTiers, tiers } from "./tiers";

export const getBasePrice = (
  stripeName: string,
  isAppsumoUser: boolean,
  pricingPeriod: string
) => {
  if (isAppsumoUser) {
    return 0;
  }

  const tier =
    tiers.find((t) => t[pricingPeriod]?.stripeName === stripeName) ||
    legacyTiers.find((t) => t[pricingPeriod]?.stripeName === stripeName);

  return tier?.[pricingPeriod]?.price || 0;
};
