import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@/components/Elements";
import { useContentOpportunitiesStore } from "../store";

interface SelectSiteDialogProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export const SelectSiteDialog = ({
  open,
  onClose,
  onCancel,
}: SelectSiteDialogProps) => {
  const { gscSites, setAuthorizedSite, setSiteUrl } =
    useContentOpportunitiesStore();

  // Handler for site selection
  const handleSiteSelect = async (site: string) => {
    try {
      await setAuthorizedSite(site);
      setSiteUrl(site);
      onClose();
    } catch (error) {
      console.error("Error setting authorized site:", error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <h2 className="text-lg font-medium text-zinc-900 dark:text-white">
            Select a site to continue
          </h2>
        </DialogHeader>
        <div className="flex flex-col space-y-4 p-4">
          <p className="text-sm text-zinc-500 dark:text-zinc-400">
            You need to select a site to track with Content Opportunities.
          </p>
          <div className="flex flex-col space-y-2">
            {gscSites.map((site) => (
              <Button
                key={site}
                variant="outline"
                className="justify-start"
                onClick={() => handleSiteSelect(site)}
              >
                {site}
              </Button>
            ))}
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={onCancel} size="sm">
              Cancel
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SelectSiteDialog;
