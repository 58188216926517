import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  Tag,
} from "@/components/Elements";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useAuth } from "@/lib/auth";
import { useNotificationStore } from "@/stores/notifications";
import { useDialogStore } from "@/stores/upgrade";
import { DialogPortal } from "@radix-ui/react-dialog";
import { useState } from "react";
import { TbAlertCircle, TbSparkles, TbX } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

export const UpgradeProAddonDialog = ({ onClose }) => {
  const { addNotification } = useNotificationStore();
  const { user } = useAuth();
  const [hasTracked, setHasTracked] = useState(false);
  const trackEvent = useTrackEvent();
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription({});
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const isAdmin = user?.org_role === 1;
  const isAppsumo = subscriptionData?.appsumo || false;
  const isSubscriptionActive = subscriptionData?.status === "active";

  const isLoading = isLoadingSubscription;

  const close = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  const handleTrackFreeTrialLimitPopup = async () => {
    if (!hasTracked) {
      trackEvent.mutate({
        event: "addon_upgrade_popup",
        properties: JSON.stringify({}),
      });
      setHasTracked(true);
    }
  };

  if (open) {
    handleTrackFreeTrialLimitPopup();
  }

  const handleUpgradeClick = () => {
    if (!isAdmin) {
      addNotification({
        type: "error",
        title: "Team admin permissions required",
        message: "You must be a team admin to upgrade to the Pro add-on.",
      });
      return;
    }

    useDialogStore.getState().closeDialog();
    navigate("/app/settings/plans");
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose(close);
        }
      }}
    >
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between ">
            {/*<img
              src={ai_wizard_preview}
              alt="AI Article Wizard"
              className="w-full h-full"
    />*/}

            <Button
              variant="buttonIcon"
              buttonIcon={<TbX />}
              className="absolute top-2 right-2"
              onClick={() => {
                close();
              }}
            ></Button>
          </DialogHeader>
          <DialogDescription className="px-4 space-y-2">
            <div className="flex flex-row items-center space-x-1">
              <TbSparkles
                className="text-emerald-600 w-5 h-5 shrink-0"
                style={{
                  fill: "#059669",
                }}
              />
              <h1 className="text-lg font-semibold text-zinc-900 dark:text-zinc-100 flex items-center">
                Rank-Ready AI Documents Add-on
                <Tag
                  variant="small"
                  className="ml-2 h-[20px]"
                  color="emerald"
                  textClassName="font-medium text-2xs"
                >
                  New
                </Tag>
              </h1>
            </div>
            <p className="text-sm text-zinc-600 dark:text-zinc-400 ">
              Generate full articles with a step-by-step process. Use search
              results, or provide your own information.
            </p>
            {!isSubscriptionActive && (
              <p className="text-xs text-zinc-600 dark:text-zinc-400 border rounded-md p-2 items-center flex space-x-2">
                <TbAlertCircle className="w-4 h-4 flex-shrink-0 mr-2" />
                Please select a base plan to upgrade to the Pro add-on.
              </p>
            )}
          </DialogDescription>
          <DialogFooter className="px-4 pb-4 text-center flex-col">
            <Button
              type="submit"
              variant="primaryBlur"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={handleUpgradeClick}
            >
              View plans
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
