import { Button } from "@/components/Elements";
import { useAuth } from "@/lib/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { GoogleLogin } from "@react-oauth/google";
import { motion } from "framer-motion";
import { decodeJwt } from "jose";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "../../../components/Elements";
import { useNotificationStore } from "../../../stores/notifications";

const schema = z.object({
  email: z.string().email("Invalid email address").min(1, "Email is required."),
  fullName: z.string().min(1, "Full name is required."),
  password: z
    .string()
    .min(8, "Password must be at least 8 characters long.")
    .regex(
      /(?=.*[a-zA-Z])(?=.*[0-9]|.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
      "Password must contain at least one letter, one number or special character."
    ),
});

export const RegisterForm = () => {
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);
  const { register, isRegistering } = useAuth();
  const { addNotification } = useNotificationStore();

  const [searchParams] = useSearchParams();
  const emailId = searchParams.get("emailID") || "";
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      email: emailId || "",
      fullName: "",
      password: "",
    },
  });

  const registerButtonRef = useRef<HTMLButtonElement>(null);
  const [registerButtonWidth, setRegisterButtonWidth] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (registerButtonRef.current) {
      setRegisterButtonWidth(registerButtonRef.current.offsetWidth);
    }
  }, []);

  const onSubmit = async (values) => {
    try {
      await register({
        email: values.email,
        password: values.password,
        firstName: values.fullName.split(" ")[0],
        lastName: values.fullName.split(" ")[1] || "",
        signUpSource: "web",
        timezoneOffset: new Date().getTimezoneOffset(),
        mobileRegistration: "false",
        googleRegistration: "false",
      });
    } catch (error) {
      addNotification({
        type: "error",
        title: "Registration failed",
        message: error.response.data.errors,
      });
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const decodedToken = decodeJwt(credentialResponse.credential);
      const email = decodedToken.email || "";
      const firstName = decodedToken.given_name || "";
      const lastName = decodedToken.family_name || "";
      await register({
        email: email,
        password: credentialResponse.credential,
        firstName: firstName || "",
        lastName: lastName || "",
        signUpSource: "web",
        timezoneOffset: new Date().getTimezoneOffset(),
        mobileRegistration: "false",
        googleRegistration: "true",
      });
    } catch (error) {
      addNotification({
        type: "error",
        title: "Registration failed",
        message: error.response.data.errors,
      });
    }
  };

  const passwordRequirementsVariants = {
    hidden: { opacity: 0, display: "none" },
    visible: { opacity: 1, display: "block" },
  };

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="email"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>Email Address</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    className="shadow-sm ring-zinc-900/10 focus:ring-zinc-900/30 focus:border-zinc-900/30"
                    placeholder="Enter your email"
                    {...field}
                  />
                </FormControl>
                {fieldState.error && (
                  <FormMessage>{fieldState.error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="fullName"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>Full Name</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    className="shadow-sm ring-zinc-900/10 focus:ring-zinc-900/30 focus:border-zinc-900/30"
                    placeholder="Enter your full name"
                    {...field}
                  />
                </FormControl>
                {fieldState.error && (
                  <FormMessage>{fieldState.error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    className="shadow-sm ring-zinc-900/10 focus:ring-zinc-900/30 focus:border-zinc-900/30"
                    placeholder="Enter your password"
                    {...field}
                    onFocus={() => setShowPasswordRequirements(true)}
                    onBlur={(e) => {
                      setShowPasswordRequirements(false);
                      field.onBlur(e); // Call the default onBlur handler from react-hook-form
                    }}
                  />
                </FormControl>
                {!fieldState.error && (
                  <motion.div
                    variants={passwordRequirementsVariants}
                    initial="hidden"
                    animate={showPasswordRequirements ? "visible" : "hidden"}
                    transition={{ duration: 0.4 }} // Customize the transition as needed
                    className="text-xs text-gray-500"
                  >
                    Password must be at least 8 characters long and include at
                    least one letter, one number or special character.
                  </motion.div>
                )}
                {fieldState.error && (
                  <FormMessage>{fieldState.error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <Button
            isLoading={isRegistering}
            ref={registerButtonRef}
            type="submit"
            className="w-full"
            variant="primaryBlur"
          >
            Sign up
          </Button>
        </form>
      </Form>

      <div className="flex items-center justify-center mt-6">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="text-sm text-gray-500 dark:text-zinc-400 px-4">
          or
        </span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>
      <div className="mt-4 w-full">
        <GoogleLogin
          containerProps={{
            className:
              "w-full rounded-md backdrop-blur-lg bg-white/90 dark:bg-zinc-900/50 shadow-sm",
          }}
          size="medium"
          theme="outline"
          width={registerButtonWidth}
          logo_alignment="center"
          text="signup_with"
          onSuccess={handleGoogleSuccess}
          onError={() => {
            addNotification({
              type: "error",
              title: "Error",
              message: "There was an error registering with Google.",
            });
          }}
        />
      </div>
      <div className="mt-8 text-center">
        <span className="text-sm font-normal text-zinc-500 dark:text-zinc-400">
          Already have an account?{" "}
          <Link
            to="/login"
            className="text-emerald-600 hover:text-emerald-400 font-medium"
          >
            Sign in
          </Link>
        </span>
      </div>
    </div>
  );
};
