import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";

export interface EnterpriseSeatInfo {
  success: boolean;
  isEnterprise: boolean;
  totalSeats?: number;
  usedSeats?: number;
  remainingSeats?: number;
  pendingInvites?: number;
  limitReached?: boolean;
  message?: string;
}

export const getEnterpriseSeatInfo = async (): Promise<EnterpriseSeatInfo> => {
  try {
    const response = await axios.get("/api/enterprise/seats");
    return response;
  } catch (error) {
    console.error("Error fetching enterprise seat info:", error);
    return {
      success: false,
      isEnterprise: false,
      message: "Error fetching enterprise seat information",
    };
  }
};

export const useGetEnterpriseSeatInfo = (options = {}) => {
  return useQuery<EnterpriseSeatInfo>(
    "enterpriseSeatInfo",
    getEnterpriseSeatInfo,
    {
      ...options,
      retry: 1,
    }
  );
};
