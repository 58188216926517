import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { QueryDashboardData, QueryDashboardResponse } from "../types";

export const getQueryDashboard = async ({
  site_url,
  query,
  weeks,
}: {
  site_url: string;
  query: string;
  weeks: string;
}): Promise<QueryDashboardData> => {
  const response: QueryDashboardResponse = await axios.post(
    "/content-recommendations/query-dashboard",
    {
      site_url,
      query,
      weeks,
    }
  );

  // Check if the response indicates an error
  if (response.status === "error") {
    throw new Error(
      response.message || "An error occurred fetching query dashboard"
    );
  }

  return response.data as QueryDashboardData;
};

type QueryFnType = typeof getQueryDashboard;

type UseGetQueryDashboardOptions = {
  site_url: string;
  query: string;
  weeks: string;
  config?: QueryConfig<QueryFnType>;
};

export const useGetQueryDashboard = ({
  site_url,
  query,
  weeks,
  config,
}: UseGetQueryDashboardOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "contentOpportunities",
      "queryDashboard",
      site_url,
      query,
      weeks,
    ],
  });
};
