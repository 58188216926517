import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LoadSpinner } from "@/components/AdvancedEditor/plugins/ResearchPlugin/SerpPlaceholder";
import { Head } from "@/components/Head";
import { useEditorStore } from "@/stores/editor";
import { DialogPortal } from "@radix-ui/react-dialog";
import { isEqual } from "lodash";
import { TbCircleCheckFilled, TbX } from "react-icons/tb";
import { useQueryClient } from "react-query";
import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/Elements";
import {
  countryOptions,
  languageOptions,
} from "../../../components/Elements/Dialog/newDocumentOptions";
import { useSidebarNavigationStore } from "../../../components/Layout/SidebarToggle";
import { defaultDocument, useDocumentStore } from "../../../stores/document";
import { useSerpStore } from "../../../stores/serp";
import { useTrackEvent } from "../../analytics/api/trackUser";
import { useUser } from "../../auth";
import { useCreateDocument } from "../../documents/api/createDocument";
import { useGoogleSearchResults } from "../../documents/api/getGoogleSearchResults";
import { useUrlBatch } from "../../documents/api/getUrlBatch";
import { useUpdateDocument } from "../../documents/api/updateDocument";
import {
  isEnterpriseDocumentLimitReached,
  useGetAiArticleUsage,
  useGetEnterpriseWizardInfo,
} from "../../subscription/api/getAiArticleLimit";
import { EnterpriseWizardLimitDialog } from "../../upgrade/components/EnterpriseWizardLimitDialog";
import { useGetGptArticleId } from "../api/getGptArticleId";
import { wizardSteps } from "../utils/wizardSteps";
import { AiWizardNavigation } from "./AiWizardNavigation";
import { addParentToHeadings } from "./OutlineStep";
import { WizardHeader } from "./WizardHeader";
import { WizardNavigation } from "./WizardNavigation";
import { WizardWrapper } from "./WizardWrapper";

export const useCreateFraseDocument = () => {
  const createDocumentMutation = useCreateDocument({ notifyOnSuccess: false });
  const { data: user } = useUser();
  const trackEvent = useTrackEvent();

  const createFraseDocument = async (
    query: string,
    selectedLanguage: string,
    selectedCountry: string
  ) => {
    const fraseDocument = await createDocumentMutation
      .mutateAsync({
        doc_owner: user.id,
        doc_owner_name: user.fullName,
        doc_status: 1,
        metadata: {
          serp_loaded: true,
          ai_article_started: true,
          name: query || "Untitled",
          code: selectedCountry || "us",
          display_code:
            countryOptions.find((country) => country.value === selectedCountry)
              ?.label || "United States",
          lang_code: selectedLanguage || "en",
          lang_display:
            languageOptions.find(
              (language) => language.value === selectedLanguage
            )?.label || "English",
          url: null,
          topics: "[]",
          outline: "{}",
          instructions: "[]",
          title: "",
        },
        org_id: user.orgId,
        query: query || "",
        template: null,
        text: [
          {
            html: "",
            title: "Untitled",
            name: "Tab 1",
          },
        ],
      })
      .catch((error) => {
        console.error("Failed to create document:", error);
      });
    trackEvent.mutate({
      event: "new_document",
      properties: JSON.stringify({
        type: "wizard",
        query: query || "",
      }),
    });
    return fraseDocument;
  };

  return { createFraseDocument, createDocumentMutation };
};

export const AiDocumentWizardForm = () => {
  const [query, setQuery] = useState("");
  const [title, setTitle] = useState("");
  const [instructions, setInstructions] = useState([]);
  const [tone, setTone] = useState("concise and readable");
  const [customTone, setCustomTone] = useState("");
  const [processedArticles, setProcessedArticles] = useState([]);
  const [globalInstructions, setGlobalInstructions] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [furthestStepReached, setFurthestStepReached] = useState(1);
  const [mode, setMode] = useState("default");
  const [processQuery, setProcessQuery] = useState(false);
  const [isLoadingSerp, setIsLoadingSerp] = useState(false);
  const [isLoadingArticleId, setIsLoadingArticleId] = useState(false);
  const [isGeneratingInstructions, setIsGeneratingInstructions] =
    useState(false);
  const [isArticleQueued, setIsArticleQueued] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [isMetadataUpdated, setIsMetadataUpdated] = useState(false);
  const [isArticleGenerated, setIsArticleGenerated] = useState(false);
  const [recommendedTopics, setRecommendedTopics] = useState([]);
  const [prevTopics, setPrevTopics] = useState();
  const [showOverlay, setShowOverlay] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [isPrepopulated, setIsPrepopulated] = useState(false);
  const queryClient = useQueryClient();
  const { isSidebarOpen, closeSidebar } = useSidebarNavigationStore();
  const { document: fraseDocument, setDocument } = useDocumentStore();
  const [isInstructionDialogOpen, setIsInstructionDialogOpen] = useState(false);

  // Enterprise plan checks
  const [showEnterpriseLimitDialog, setShowEnterpriseLimitDialog] =
    useState(false);
  const [enterpriseLimitType, setEnterpriseLimitType] = useState("DOCUMENT");
  const [isEnterpriseLimitReached, setIsEnterpriseLimitReached] = useState(false);
  const { data: aiArticleUsageData, isLoading: isLoadingUsageData } =
    useGetAiArticleUsage();
  const { data: enterpriseInfo, isLoading: isLoadingEnterpriseInfo } =
    useGetEnterpriseWizardInfo({});

  const [isSticky, setIsSticky] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const outlineHeadings =
    Object.values(
      JSON.parse(fraseDocument.metadata?.outline || "{}") || "{}"
    ) || [];
  const [headings, setHeadings] = useState(outlineHeadings);
  const [modifiedHeadings, setModifiedHeadings] = useState(outlineHeadings);

  useEffect(() => {
    setHeadings(outlineHeadings);
  }, [fraseDocument.metadata?.outline]);

  useEffect(() => {
    setGlobalInstructions(
      JSON.parse(fraseDocument.metadata?.global_instructions || "[]") || []
    );
  }, [fraseDocument.metadata?.global_instructions]);

  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
    isResolvingConflict: true,
  });
  const { createFraseDocument } = useCreateFraseDocument();
  const location = useLocation();
  const shouldPrepopulate = location.state?.shouldPrepopulate || false;
  const { data: user } = useUser();
  const trackEvent = useTrackEvent();
  const geography = JSON.parse(user?.geography || "{}");
  const [selectedLanguage, setSelectedLanguage] = useState(
    fraseDocument.metadata?.lang_code || geography?.lang || "en"
  );
  const [selectedCountry, setSelectedCountry] = useState(
    fraseDocument.metadata?.code || geography?.code || "us"
  );
  const [isSerpProcessed, setIsSerpProcessed] = useState(
    location.state?.isSerpProcessed || false
  );
  const { editor } = useEditorStore();
  const { activeTabIndex } = editor;
  const { serp, resetSerp } = useSerpStore();
  const { urls, results, topics, articles } = serp[fraseDocument.id] || {
    urls: [],
    results: [],
    topics: [],
    articles: [],
  };
  const navigate = useNavigate();
  const headingsWithParents = addParentToHeadings(modifiedHeadings);

  const getArticleIdQuery = useGetGptArticleId({
    query: query,
    title: title,
    topics: selectedTopics.map((topic) => topic.entity).join(", "),
    lang: selectedLanguage,
    country: selectedCountry,
    tone:
      customTone.length > 0
        ? customTone
        : tone === "Concise and readable"
        ? "You are an SEO specialist and experienced writer producing concise sentences with a high readability score, and 2-3 sentences max per paragraph. Avoid flowery language"
        : tone,
    global_instructions: JSON.stringify(globalInstructions),
    outline: headingsWithParents.map((heading) => {
      return {
        id: heading.id,
        heading: heading.header,
        parent: heading.parent,
        heading_type: heading.user_header_tag,
        instructions: heading.instruction
          ? JSON.parse(heading.instruction || "[]").join(", ")
          : "",
      };
    }),
    doc_hash: fraseDocument.hash,
    config: {
      enabled: submit,
    },
  });

  const resetWizardState = () => {
    setDocument(defaultDocument);
    if (!shouldPrepopulate) {
      resetSerp();
    }
    setInstructions([]);
    setCurrentStep(1);
    setProcessQuery(false);
    setIsLoadingSerp(false);
    setIsLoadingArticleId(false);
    setIsArticleQueued(false);
    setSelectedTopics([]);
    setSubmit(false);
    setIsMetadataUpdated(false);
    setIsArticleGenerated(false);
    setRecommendedTopics([]);
    setIsSerpProcessed(false);
  };

  useEffect(() => {
    resetWizardState();
    queryClient.removeQueries(["googleSearchResults"]);
    queryClient.removeQueries(["processUrlBatch"]);

    queryClient.removeQueries(["gptArticleId"]);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrollTop = containerRef.current.scrollTop;
        setIsSticky(scrollTop > 170);
      }
    };

    const handleResize = () => {
      // Force update to re-render the component
      setForceUpdate((prev) => prev + 1);
    };

    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);

      return () => {
        containerElement.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [containerRef.current]);

  async function handleProcessSerp() {
    setMode("loading");
    setIsLoadingSerp(true);
    googleSearchResultsQuery.refetch().then(() => {
      urlBatchQuery.refetch().then(() => {
        setIsLoadingSerp(false);
        setMode("default");
        setIsSerpProcessed(true);
      });
    });
  }

  async function handleAiArticleStarted() {
    const updatedDocument = {
      ...fraseDocument,
      metadata: {
        ...fraseDocument.metadata,
        ai_article_started: true,
      },
    };

    updateDocumentMutation.mutateAsync(updatedDocument);
  }

  async function handleCreateDocument() {
    setMode("loading");
    setIsLoadingSerp(true);
    const fraseDocument = await createFraseDocument(
      query,
      selectedLanguage,
      selectedCountry
    );

    await setDocument(fraseDocument);
    handleProcessSerp();
  }

  const handleTrackSubmitWizard = async () => {
    trackEvent.mutate({
      event: "submit_wizard",
      properties: JSON.stringify({}),
    });
  };

  const handleUpdateDocumentMetadata = () => {
    const updatedDocument = {
      ...fraseDocument,
      metadata: {
        ...fraseDocument.metadata,
        ai_article: {
          id: getArticleIdQuery.data?.id,
          title: title,
          query: query,
          generated: false,
          accepted: false,
        },
        ai_article_started: undefined,
      },
    };

    return updateDocumentMutation.mutateAsync(updatedDocument);
  };

  const googleSearchResultsQuery = useGoogleSearchResults({
    searchQuery: query,
    document: fraseDocument,
    country: selectedCountry,
    language: selectedLanguage,
    isAiArticleRequest: true,
    config: {
      enabled: false,
    },
  });
  const blacklistUrls = fraseDocument.metadata?.blacklist || {};

  const urlBatchQuery = useUrlBatch({
    urls: urls?.filter((url) => !Object.keys(blacklistUrls).includes(url)),
    document: fraseDocument,
    shouldSetSerpProcessed: true,
    config: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (currentStep > 1 && currentStep < 5) {
      handleAiArticleStarted();
    }
  }, [currentStep]);

  useEffect(() => {
    if (topics && !isEqual(topics, prevTopics)) {
      const filteredTopics = topics
        .filter((topic) => topic.entity.split(" ").length > 1)
        .slice(0, 30);
      setRecommendedTopics(filteredTopics);
      setPrevTopics(topics);
    }

    // Set or reset selected topics based on fraseDocument.metadata.topics
    const metadataTopics = JSON.parse(fraseDocument.metadata?.topics || "[]");
    if (metadataTopics.length > 0) {
      setSelectedTopics(metadataTopics);
    } else {
      setSelectedTopics([]);
    }
  }, [topics, prevTopics, fraseDocument.metadata?.topics, fraseDocument.hash]);

  useEffect(() => {
    if (fraseDocument.lastEdited > 0) {
      setIsSaved(true);
    }
  }, [fraseDocument.lastEdited]);

  useEffect(() => {
    if (getArticleIdQuery.isLoading) {
      setIsLoadingArticleId(true);
    }
    if (getArticleIdQuery.isError) {
      setIsLoadingArticleId(false);
    }
  }, [getArticleIdQuery, isMetadataUpdated]);

  useEffect(() => {
    if (getArticleIdQuery.isSuccess && !isMetadataUpdated) {
      setIsLoadingArticleId(false);
      setIsArticleQueued(true);
      handleTrackSubmitWizard();
      handleUpdateDocumentMetadata()
        .then(() => {
          setIsMetadataUpdated(true);
        })
        .catch((error) => {
          // Handle error if the update fails
          console.error("Failed to update document metadata:", error);
        });
    }
  }, [getArticleIdQuery.isSuccess]);

  useEffect(() => {
    if (isMetadataUpdated) {
      navigate(`/app/documents/${fraseDocument.hash}`);
    }
  }, [
    isMetadataUpdated,
    fraseDocument.hash,
    isSidebarOpen,
    navigate,
    closeSidebar,
  ]);

  const stepValidations = {
    1: query !== "" && !isLoadingSerp && processedArticles.length >= 5,
    2: title !== "",
    3: selectedTopics.length > 0,
    4: modifiedHeadings.length > 0 && !isGeneratingInstructions,
    5: true,
  };

  const stepToName: { [key: number]: string } = {
    1: "search_query",
    2: "title",
    3: "topics",
    4: "outline_instructions",
    5: "settings",
    6: "review",
  };

  const handleTrackWizardStep = async (index: number) => {
    trackEvent.mutate({
      event: "wizard_step",
      properties: JSON.stringify({
        step: stepToName[index] || "",
        furthest_step: stepToName[furthestStepReached] || "",
      }),
    });
  };

  useEffect(() => {
    handleTrackWizardStep(currentStep);
  }, [currentStep]);

  const handleSubmit = async () => {
    try {
      setSubmit(true);
      setIsSubmitting(true);

      const response = await generateArticle({
        query,
        outline: headingsWithParents,
        title,
        topics: selectedTopics.map((topic) => topic.entity).join(", "),
        globalInstructions: JSON.stringify(globalInstructions),
        tone:
          customTone.length > 0
            ? customTone
            : tone === "Concise and readable"
            ? "You are an SEO specialist and experienced writer producing concise sentences with a high readability score, and 2-3 sentences max per paragraph. Avoid flowery language"
            : tone,
        lang: selectedLanguage,
        provider: "gpt",
      });

      if (response.success) {
        navigate(`/ai/article/${response.data.id}`);
      } else {
        // Handle specific error types
        if (response.error_type === "no_credits") {
          toast.error(
            "You have no remaining document credits. Please purchase more credits to continue."
          );
          setShowCheckout(true);
        } else {
          toast.error("Failed to generate article. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error generating article:", error);
      toast.error("Failed to generate article. Please try again.");
    } finally {
      setIsSubmitting(true);
    }
  };

  useEffect(() => {
    if (shouldPrepopulate && fraseDocument && !isPrepopulated) {
      (async () => {
        setDocument(fraseDocument || defaultDocument);
        setQuery(fraseDocument.query || "");
        setTitle(
          fraseDocument.metadata?.title ||
            fraseDocument.text[activeTabIndex].title ||
            ""
        );
        setHeadings(
          Object.values(
            JSON.parse(fraseDocument.metadata?.outline || "{}") || "{}"
          ) || []
        );
        setInstructions(
          JSON.parse(fraseDocument.metadata?.instructions || "[]") || []
        );
        setSelectedTopics(
          JSON.parse(fraseDocument.metadata?.topics || "[]") || []
        );
        setGlobalInstructions(
          JSON.parse(fraseDocument.metadata?.global_instructions || "[]") || []
        );
        setIsLoadingSerp(false);
        setShowOverlay(
          JSON.parse(fraseDocument.metadata.instructions || "[]").every(
            (instruction) => instruction.instructions.join("").length === 0
          )
        );
        setSelectedLanguage(
          fraseDocument.metadata.lang_code || geography?.lang || "en"
        );
        setSelectedCountry(
          fraseDocument.metadata.code || geography?.code || "us"
        );
        setTone(fraseDocument.metadata.tone || "concise and readable");
        setCustomTone(fraseDocument.metadata.custom_tone || "");
        setIsPrepopulated(true);
        if (isSerpProcessed) {
          setIsSerpProcessed(true);
          stepValidations[1] = true;
          setProcessedArticles(articles || []);
        }
      })();
    }
  }, [
    shouldPrepopulate,
    fraseDocument,
    isSidebarOpen,
    closeSidebar,
    setDocument,
    queryClient,
  ]);

  // Check for enterprise document limit before fetching article ID
  useEffect(() => {
    if (submit && isEnterpriseLimitReached) {
      // Reset submission state when limit is reached
      setSubmit(false);
      setIsLoadingArticleId(false);
    }
  }, [submit, isEnterpriseLimitReached]);

  return (
    <div className="h-full w-full min-h-screen overflow-x-hidden flex flex-row">

      <AiWizardNavigation
        wizardSteps={wizardSteps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isLoadingSerp={isLoadingSerp}
        stepValidations={stepValidations}
        furthestStepReached={furthestStepReached}
        setIsEnterpriseLimitReached={setIsEnterpriseLimitReached}
      />
      <div className="flex flex-col w-full h-full">
        <Head title={"Rank-Ready AI Document"} />

        <div className="h-screen w-full flex flex-col py-4">
          <div className="flex flex-col">
            <div className="flex flex-col items-center space-y-4 h-full w-full border-b border-b-zinc-200 dark:border-b-zinc-700 pb-4">
              <div className="flex items-center justify-between w-full h-full">
                <WizardHeader
                  query={query}
                  articles={articles}
                  isLoadingSerp={isLoadingSerp}
                  currentStep={currentStep}
                />
                <div className="flex items-center justify-end w-full">
                  {isSaved && (
                    <div className="flex items-center justify-center text-xs font-normal text-zinc-500 dark:text-white">
                      <TbCircleCheckFilled className="mr-2 text-emerald-600 flex-shrink-0" />
                      <span className="whitespace-nowrap">Progress saved</span>
                    </div>
                  )}
                  <Button
                    variant="buttonIcon"
                    tooltipContent="Save & exit wizard"
                    buttonIcon={<TbX />}
                    onClick={() => {
                      resetWizardState();
                      navigate("/app/documents");
                    }}
                    className="mx-4 flex-shrink-0 z-[50]"
                  ></Button>
                </div>
              </div>
            </div>
          </div>

          {mode === "loading" && (
            <div className="relative w-full h-full mx-auto my-auto">
              <LoadSpinner searchResults={results} loadingText={undefined} />
            </div>
          )}

          {mode === "default" && (
            <div
              className="flex flex-col pt-4 w-full h-full overflow-y-scroll overflow-x-hidden"
              ref={containerRef}
            >
              {wizardSteps.map((step) => {
                if (currentStep === step.id) {
                  return (
                    <WizardWrapper
                      key={step.id}
                      results={results}
                      isLoadingSerp={isLoadingSerp}
                      setQuery={setQuery}
                      handleCreateDocument={handleCreateDocument}
                      query={query}
                      setTitle={setTitle}
                      globalInstructions={globalInstructions}
                      setGlobalInstructions={setGlobalInstructions}
                      title={title}
                      setHeadings={setHeadings}
                      headings={headings}
                      headingsWithParents={headingsWithParents}
                      instructions={instructions}
                      setInstructions={setInstructions}
                      topics={recommendedTopics}
                      selectedTopics={selectedTopics}
                      setProcessQuery={
                        shouldPrepopulate
                          ? () => handleProcessSerp()
                          : handleCreateDocument
                      }
                      setProcessedArticles={setProcessedArticles}
                      setModifiedHeadings={setModifiedHeadings}
                      modifiedHeadings={modifiedHeadings}
                      processedArticles={processedArticles}
                      setSelectedTopics={setSelectedTopics}
                      user={user}
                      urls={urls}
                      steps={wizardSteps}
                      currentStep={currentStep}
                      navigate={navigate}
                      articles={articles}
                      fraseDocument={fraseDocument}
                      showOverlay={showOverlay}
                      setShowOverlay={setShowOverlay}
                      setSelectedLanguage={setSelectedLanguage}
                      selectedLanguage={selectedLanguage}
                      setSelectedCountry={setSelectedCountry}
                      selectedCountry={selectedCountry}
                      fraseDocumentBlacklist={
                        fraseDocument.metadata?.blacklist || {}
                      }
                      setIsGeneratingInstructions={setIsGeneratingInstructions}
                      tone={tone}
                      setTone={setTone}
                      customTone={customTone}
                      setCustomTone={setCustomTone}
                      isSticky={isSticky}
                      containerRef={containerRef}
                    />
                  );
                }
                return null;
              })}
            </div>
          )}

          {!isArticleQueued && !isArticleGenerated && (
            <WizardNavigation
              articles={articles}
              headings={modifiedHeadings}
              query={query}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              stepValidations={stepValidations}
              handleSubmit={handleSubmit}
              isLoadingSerp={isLoadingSerp}
              isSubmitting={getArticleIdQuery.isLoading}
              setFurthestStepReached={setFurthestStepReached}
              setIsInstructionDialogOpen={setIsInstructionDialogOpen}
              disabled={isEnterpriseLimitReached}
            />
          )}
        </div>
      </div>
      <Dialog
        open={isInstructionDialogOpen}
        onOpenChange={(open) => {
          if (!open) {
            setIsInstructionDialogOpen(false);
          }
        }}
      >
        <DialogPortal>
          <DialogContent>
            <DialogHeader className="pb-0 flex items-center justify-between">
              <DialogTitle>Continue without heading instructions?</DialogTitle>
              <DialogCloseButton
                close={() => {
                  setIsInstructionDialogOpen(false);
                }}
              />
            </DialogHeader>
            <DialogDescription className="px-4 space-y-2 flex flex-col">
              <span>
                Heading instructions guide Frase's AI in generating the best
                possible article for you.
              </span>
            </DialogDescription>

            <DialogFooter className="px-4 pb-4">
              <Button
                variant="outlineBlur"
                onClick={() => {
                  setIsInstructionDialogOpen(false);
                  setIsGeneratingInstructions(true);
                  // Dispatch custom event to trigger instruction generation in OutlineStep
                  window.dispatchEvent(
                    new CustomEvent("generateInstructions", {
                      detail: { trigger: true },
                    })
                  );
                }}
              >
                Generate instructions with AI
              </Button>
              <Button
                variant="primaryBlur"
                onClick={() => {
                  setIsInstructionDialogOpen(false);
                  setCurrentStep(5);
                }}
              >
                Continue without instructions
              </Button>
            </DialogFooter>
          </DialogContent>
        </DialogPortal>
      </Dialog>
    </div>
  );
};
