import { Badge } from "@/components/Elements/Badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements/Tooltip";
import { GSCContentStatus } from "../types";
import { getStatusColor } from "../utils";

/**
 * Props for the StatusBadge component
 */
interface StatusBadgeProps {
  /** The content status to display */
  status: GSCContentStatus;
  /** Optional size variant for the badge */
  size?: "default" | "xs";
}

// Status descriptions for tooltips
const statusDescriptions: Record<GSCContentStatus, string> = {
  decay: "Page has lost more than 2 positions and clicks decreased",
  "trending up": "Position is 10-50, but impressions are growing fast",
  "top result":
    "Position is 1-10 and has not recently experienced ranking loss",
  ranked: "Generating clicks, but no leading avg. position",
  unranked: "No clicks generated, and weak position",
  "quick win": "Achieved 1-10 position within 3 months from publishing",
  missing: "This content is missing from the site",
  unknown: "Status could not be determined",
};

/**
 * A component that displays a status badge with appropriate styling based on the status value
 * Automatically formats hyphenated status values for display (e.g., "not-indexed" → "not indexed")
 */
export default function StatusBadge({
  status,
  size = "default",
}: StatusBadgeProps) {
  // Format the status text by replacing hyphens with spaces for better readability
  const displayText = status.replace(/-/g, " ");

  // Apply size-specific classes
  const sizeClasses = size === "xs" ? "text-xs py-0 px-1.5" : "";

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="cursor-help">
            <Badge className={`${getStatusColor(status)} ${sizeClasses}`}>
              {displayText}
            </Badge>
          </div>
        </TooltipTrigger>
        <TooltipContent>{statusDescriptions[status]}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
