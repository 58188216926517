import { Button } from "@/components/Elements/Button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements/Tooltip";
import { ArrowDown, ArrowUp, Info } from "lucide-react";
import { DateRange } from "../types";
import {
  formatDateRange,
  formatGrowth,
  formatNumber,
  formatPercentage,
} from "../utils";

/**
 * Props for the MetricCard component
 */
interface MetricCardProps {
  /** Title of the metric */
  title: string;
  /** Current value of the metric */
  value: number;
  /** Growth rate compared to previous period (as a decimal or position change) */
  growth: number;
  /** Icon to display with the metric */
  icon: React.ReactNode;
  /** Whether the value should be formatted as a percentage */
  isPercentage?: boolean;
  /** Whether this metric represents a position (where lower is better) */
  isPosition?: boolean;
  /** Current date range for the metric */
  dateRange: DateRange;
  /** Previous date range for comparison */
  comparisonDateRange: DateRange;
  /** Whether to display a compact version of the card */
  mini?: boolean;
}

/**
 * A card component that displays a metric with its current value and growth compared to a previous period
 * Handles different types of metrics including percentages and position metrics
 *
 * @param mini - When true, displays a more compact version suitable for smaller spaces
 */
export default function MetricCard({
  title,
  value,
  growth,
  icon,
  isPercentage = false,
  isPosition = false,
  dateRange,
  comparisonDateRange,
  mini = false,
}: MetricCardProps) {
  // Format the value based on its type
  const formattedValue = isPosition
    ? value.toFixed(1)
    : isPercentage
    ? formatPercentage(value)
    : formatNumber(value);

  // Determine if the growth is positive (improvement)
  // For position metrics, negative growth is good (moving up in rankings)
  // For other metrics, growth > 1 is good (increasing by more than 0%)
  const isPositive = isPosition ? growth < 0 : growth >= 1;

  // Format the growth value based on metric type
  const formattedGrowth = isPosition
    ? growth < 0
      ? `-${Math.abs(growth).toFixed(1)}`
      : `+${Math.abs(growth).toFixed(1)}`
    : formatGrowth(growth);

  // Render mini version
  if (mini) {
    return (
      <div className="w-full bg-white dark:bg-zinc-900 rounded-md shadow-sm border border-zinc-200 dark:border-zinc-800">
        <div className="p-2">
          <div className="flex items-center justify-between mb-0.5">
            <div className="flex items-center gap-1">
              <span className="text-zinc-700 dark:text-zinc-300">{icon}</span>
              <h3 className="text-xs font-medium text-zinc-900 dark:text-white">
                {title}
              </h3>
            </div>
            <TooltipProvider delayDuration={300}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="outline" size="sm" className="h-4 w-4 p-0">
                    <Info className="h-2.5 w-2.5" />
                    <span className="sr-only">Info</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="bottom" className="p-2 text-2xs">
                  <p>Current: {formatDateRange(dateRange)}</p>
                  <p>Previous: {formatDateRange(comparisonDateRange)}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-base font-bold text-zinc-900 dark:text-white">
              {formattedValue}
            </div>
            <div className="flex items-center text-xs">
              {isPositive ? (
                <ArrowUp className="mr-0.5 h-3 w-3 text-green-500" />
              ) : (
                <ArrowDown className="mr-0.5 h-3 w-3 text-red-500" />
              )}
              <span className={isPositive ? "text-green-500" : "text-red-500"}>
                {formattedGrowth}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render standard version
  return (
    <div className="w-full bg-white dark:bg-zinc-900 rounded-md shadow-sm border border-zinc-200 dark:border-zinc-800">
      <div className="flex flex-row items-center justify-between p-4 pb-2">
        <div className="flex items-center gap-2">
          <span className="text-zinc-700 dark:text-zinc-300">{icon}</span>
          <h3 className="text-sm font-medium text-zinc-900 dark:text-white">
            {title}
          </h3>
        </div>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="outline" size="sm" className="h-6 w-6 p-0">
                <Info className="h-4 w-4" />
                <span className="sr-only">Info</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Current: {formatDateRange(dateRange)}</p>
              <p>Previous: {formatDateRange(comparisonDateRange)}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="px-4 pb-4">
        <div className="text-2xl font-bold text-zinc-900 dark:text-white">
          {formattedValue}
        </div>
        <div className="flex items-center pt-1 text-xs">
          {isPositive ? (
            <ArrowUp className="mr-1 h-3 w-3 text-green-500" />
          ) : (
            <ArrowDown className="mr-1 h-3 w-3 text-red-500" />
          )}
          <span className={isPositive ? "text-green-500" : "text-red-500"}>
            {formattedGrowth}
          </span>
          <span className="text-zinc-500 dark:text-zinc-400 ml-1">
            vs previous period
          </span>
        </div>
      </div>
    </div>
  );
}
