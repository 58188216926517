import { ContentLayout } from "@/components/Layout";
import { SubmitTicketButton } from "@/features/help/components/SubmitTicketDialog";
import { useDeletePaymentIntentSecret } from "@/features/subscription/api/deletePaymentIntentSecret";
import { useGetUpcomingInvoice } from "@/features/subscription/api/getUpcomingInvoices";
import { useRestoreSubscription } from "@/features/subscription/api/restorePlan";
import { queryClient } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, InputLabel, Tag } from "../../../components/Elements";
import { Skeleton } from "../../../components/Elements/Skeleton";
import { useSubscription } from "../../auth/api/getSubscription";
import { getPlanNickname } from "../../subscription/utils/getPlanNickname";
import { isLegacyPlan, tiers } from "../../subscription/utils/tiers";
import { ConfirmCancelAddOnDialog } from "../components/ConfirmCancelAddOnDialog";
import { ConfirmCancelSubscriptionDialog } from "../components/ConfirmCancelSubscriptionDialog";
import { SubscriptionChangeDialog } from "../components/SubscriptionChangeDialog";
import { Checkout } from "./Checkout";

export const Subscription = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { mutate: restoreSubscription, isLoading: isRestoring } =
    useRestoreSubscription();
  const [selectedTier, setSelectedTier] = useState<{
    tier: typeof currentTier;
    isUpgrade: boolean;
    title: string;
    description: string;
    confirmButtonText: string;
    plan: string;
    hasAddOn?: boolean;
    pricingPeriod?: "monthly" | "yearly";
  } | null>(null);

  const deletePaymentIntentMutation = useDeletePaymentIntentSecret();

  // Derive basic subscription info
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription({
      config: {
        onSuccess: async (data) => {
          if (
            data?.status === "pending_authentication" &&
            !deletePaymentIntentMutation.isLoading
          ) {
            deletePaymentIntentMutation.mutate(undefined, {
              onSuccess: () => {
                queryClient.invalidateQueries("subscription");
              },
            });
          }
        },
      },
    });
  const planNickname = useMemo(
    () => getPlanNickname(subscriptionData?.plan || "Free"),
    [subscriptionData?.plan]
  );

  const [pricingPeriod, setPricingPeriod] = useState<"monthly" | "yearly">(
    subscriptionData?.billing_cycle === "year" ? "yearly" : "monthly"
  );
  const [proAddon, setProAddon] = useState(
    subscriptionData?.add_on ? "Active" : "Inactive"
  );

  // Keep pricingPeriod in sync with subscription
  useEffect(() => {
    setPricingPeriod(
      subscriptionData?.billing_cycle === "year" ? "yearly" : "monthly"
    );
    setProAddon(subscriptionData?.add_on ? "Active" : "Inactive");
  }, [subscriptionData?.billing_cycle, subscriptionData?.add_on]);

  // Derive current tier info
  const currentTier = useMemo(
    () => tiers.find((t) => t.name === planNickname),
    [planNickname]
  );

  // Derive target tier for billing cycle changes
  const targetTier = useMemo(
    () =>
      currentTier
        ? {
            ...currentTier,
            stripeName:
              pricingPeriod === "monthly"
                ? currentTier.yearly.stripeName
                : currentTier.monthly.stripeName,
            price:
              pricingPeriod === "monthly"
                ? currentTier.yearly.price
                : currentTier.monthly.price,
          }
        : null,
    [currentTier, pricingPeriod]
  );

  // Derive all subscription details
  const subscriptionDetails = useMemo(
    () => ({
      plan: planNickname,
      pricingPeriod,
      planName: subscriptionData?.plan || "Free Plan",
      proAddon: proAddon,
      isAppsumoActive: subscriptionData?.appsumo || false,
      price: currentTier?.[pricingPeriod]?.price || 0,
      hasAddOn: !!subscriptionData?.add_on,
    }),
    [
      planNickname,
      pricingPeriod,
      subscriptionData?.plan,
      subscriptionData?.add_on,
      subscriptionData?.appsumo,
      currentTier,
    ]
  );

  // Derive subscription status flags
  const isSubscriptionActive = subscriptionData?.status === "active";
  const isAppsumoUser = subscriptionData?.appsumo || false;
  const shouldRenderUpcomingInvoice = subscriptionData?.status === "active";
  const isSubscriptionCancelled =
    subscriptionData?.status === "canceled" ||
    subscriptionData?.status === "active_canceled";
  const isSubscriptionInactive = subscriptionData?.status === "inactive";
  const isSubscriptionPaused = subscriptionData?.status === "paused";

  const { data: upcomingInvoice, isLoading: isLoadingUpcomingInvoice } =
    useGetUpcomingInvoice({
      config: {
        enabled: shouldRenderUpcomingInvoice,
      },
    });

  const subscriptionEndDate = subscriptionData?.subscription_end_date
    ? dayjs(subscriptionData.subscription_end_date).format("MMM DD, YYYY")
    : null;

  // Show loading state during initial load or when clearing payment intent
  const isLoading =
    isLoadingSubscription ||
    isLoadingUpcomingInvoice ||
    deletePaymentIntentMutation.isLoading ||
    subscriptionData?.status === "pending_authentication";

  if (isSubscriptionInactive) {
    navigate("/app/settings/plans");
  }

  return (
    <ContentLayout>
      <div className="flex flex-col items-start h-screen pt-5 px-8 w-full space-y-4 overflow-y-scroll">
        <div>
          <h2 className="text-xl dark:text-white font-medium">Subscription</h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400 mt-2 min-w-[550px] mb-4">
            Manage your subscription details.
          </p>

          {/* Plan Section */}
          <section className="space-y-4">
            <h3 className="text-md dark:text-white font-medium pt-4 border-t dark:border-t-zinc-800 w-full">
              Plan
            </h3>
            <div>
              <InputLabel label="Current plan" />
              <div className="text-sm dark:text-white flex items-center justify-between">
                {isLoading ? (
                  <Skeleton className="h-6 w-full" />
                ) : isSubscriptionActive ? (
                  <>
                    <p className="text-sm dark:text-white">
                      {subscriptionDetails.plan}
                    </p>
                    {!isAppsumoUser && !subscriptionDetails.plan.toLowerCase().includes('enterprise') && (
                      <Button
                        variant="outlineBlur"
                        onClick={() => {
                          navigate("/app/settings/plans");
                        }}
                        size="xs"
                      >
                        Change plan
                      </Button>
                    )}
                  </>
                ) : isSubscriptionCancelled ? (
                  <>
                    <span className="flex items-center flex-wrap">
                      Your{" "}
                      <span className="mx-1 font-medium">
                        {subscriptionDetails.plan} Plan
                      </span>{" "}
                      has been canceled and will remain active until{" "}
                      {subscriptionEndDate}.
                    </span>
                  </>
                ) : isSubscriptionPaused ? (
                  <>
                    <span className="flex items-center flex-wrap">
                      Your{" "}
                      <span className="mx-1 font-medium">
                        {subscriptionDetails.plan} Plan
                      </span>{" "}
                      {(() => {
                        if (!subscriptionData?.subscription_renewal_date)
                          return null;
                        const renewalDate = new Date(
                          subscriptionData.subscription_renewal_date
                        );
                        const now = new Date("2025-02-03");
                        const monthsRemaining = Math.ceil(
                          (renewalDate.getTime() - now.getTime()) /
                            (1000 * 60 * 60 * 24 * 30)
                        );
                        return `is paused for ${monthsRemaining} ${
                          monthsRemaining === 1 ? "month" : "months"
                        }.`;
                      })()}
                    </span>
                    <Button
                      variant="outlineBlur"
                      onClick={() => restoreSubscription()}
                      size="xs"
                      disabled={isRestoring}
                      isLoading={isRestoring}
                      className="w-fit"
                    >
                      Restore Subscription
                    </Button>
                  </>
                ) : (
                  <span className="flex items-center flex-wrap">
                    Your{" "}
                    <span className="mx-1 font-medium">
                      {subscriptionDetails.plan} Plan
                    </span>{" "}
                    expired on {subscriptionEndDate}.
                  </span>
                )}
              </div>
            </div>

            {/* Only show Rank-Ready Add-on section for non-enterprise plans */}
            {isSubscriptionActive && 
              !subscriptionDetails.plan.toLowerCase().includes('enterprise') && (
              <div>
                <InputLabel label="Rank-Ready AI Documents Add-on" />
                <div className="flex items-center justify-between">
                  {isLoading ? (
                    <Skeleton className="h-6 w-32" />
                  ) : (
                    <>
                      {proAddon === "Active" ? (
                        <>
                          <Tag color="emerald" className="w-fit">
                            {proAddon}
                          </Tag>
                          <ConfirmCancelAddOnDialog />
                        </>
                      ) : (
                        <div className="flex items-center justify-between w-full">
                          <p className="text-sm dark:text-white">{proAddon}</p>
                          <Button
                            variant={"outlineBlur"}
                            className="w-fit"
                            size="xs"
                            onClick={() => {
                              setSelectedTier({
                                tier: currentTier,
                                isUpgrade: true,
                                title:
                                  "Upgrade to Rank-Ready AI Documents Add-on",
                                description:
                                  "Your account will be upgraded immediately to the Rank-Ready AI Documents add-on and we will charge the amount to your existing payment method.",
                                confirmButtonText: "Upgrade",
                                plan: subscriptionData?.plan || "",
                                hasAddOn: true,
                                pricingPeriod:
                                  subscriptionData?.billing_cycle === "year"
                                    ? "yearly"
                                    : "monthly",
                              });
                              setIsDialogOpen(true);
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? "Loading..." : "Upgrade to add-on"}
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}

            <div>
              <InputLabel label="Billing cycle" />
              {isLoading ? (
                <Skeleton className="h-6 w-full" />
              ) : (
                <div className="flex items-center justify-between">
                  <p className="text-sm dark:text-white">
                    {pricingPeriod.charAt(0).toUpperCase() +
                      pricingPeriod.slice(1)}
                  </p>
                  {isSubscriptionActive &&
                    !isSubscriptionCancelled &&
                    !isAppsumoUser &&
                    !isLegacyPlan(subscriptionDetails.planName) &&
                    !subscriptionDetails.plan.toLowerCase().includes('enterprise') && (
                      <Button
                        variant="outlineBlur"
                        size="xs"
                        onClick={() => {
                          setSelectedTier({
                            tier: currentTier,
                            isUpgrade: false,
                            title: "Change the billing period?",
                            description: `Your account's billing period will be changed from ${
                              pricingPeriod === "monthly"
                                ? "monthly to yearly"
                                : "yearly to monthly"
                            } immediately.`,
                            confirmButtonText: "Change billing period",
                            plan: targetTier?.stripeName || "",
                            hasAddOn: subscriptionDetails.hasAddOn,
                            pricingPeriod:
                              pricingPeriod === "monthly"
                                ? "yearly"
                                : "monthly",
                          });
                          setIsDialogOpen(true);
                        }}
                        disabled={isLoading}
                      >
                        {isLoading
                          ? "Loading..."
                          : `Switch to ${
                              pricingPeriod === "monthly" ? "yearly" : "monthly"
                            } billing`}
                      </Button>
                    )}
                </div>
              )}
            </div>

            {isLoading ? (
              <Skeleton className="h-6 w-32" />
            ) : subscriptionDetails.isAppsumoActive ? (
              <div>
                <InputLabel label="Appsumo LTD" />
                <div className="flex items-center justify-between">
                  <Tag color="emerald" className="w-fit mt-1">
                    Active
                  </Tag>
                </div>
                {isAppsumoUser && (
                  <div className="text-xs text-zinc-500 mt-2">
                    <span>Need to change your LTD plan?</span>
                    <SubmitTicketButton
                      triggerButton={
                        <Button
                          variant="link"
                          className="ml-2 hover:underline"
                          textClassName="text-xs"
                        >
                          Contact support
                        </Button>
                      }
                    />
                  </div>
                )}
              </div>
            ) : null}

            {isLoading ? (
              <Skeleton className="h-6 w-full" />
            ) : subscriptionEndDate ? (
              <div>
                <InputLabel
                  label={
                    subscriptionData?.status === "canceled" ||
                    subscriptionData?.status === "active_canceled"
                      ? "Subscription End Date"
                      : "Subscription Renewal Date"
                  }
                />
                <div className="flex items-center justify-between">
                  <p className="text-sm dark:text-white">
                    {subscriptionEndDate}
                  </p>
                  <div>
                    {subscriptionDetails.plan.toLowerCase().includes('enterprise') ? (
                      <Button
                        variant="outlineBlur"
                        size="xs"
                        onClick={() => {
                          addNotification({
                            type: "info",
                            title: "Enterprise Subscription",
                            message: "Please contact our sales team at team@frase.io to resubscribe to your Enterprise plan.",
                            duration: 8000,
                          });
                        }}
                      >
                        Resubscribe
                      </Button>
                    ) : (
                      <Checkout
                        planDetails={{
                          name: subscriptionDetails.plan,
                          stripeName:
                            currentTier?.[pricingPeriod]?.stripeName || "",
                          title: `${subscriptionDetails.plan} Plan`,
                          price: currentTier?.[pricingPeriod]?.price || 0,
                        }}
                        actionType={
                          isSubscriptionInactive &&
                          !subscriptionData?.subscription_start_date
                            ? "upgrade"
                            : "resubscribe"
                        }
                        pricingPeriod={pricingPeriod}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {isLoading ? (
              <Skeleton className="h-6 w-full" />
            ) : shouldRenderUpcomingInvoice && upcomingInvoice ? (
              <div>
                <InputLabel label="Next invoice" />
                <div className="flex items-center justify-between">
                  <p className="text-sm dark:text-white">
                    {dayjs(upcomingInvoice.date * 1000).format("MMM DD, YYYY")}{" "}
                    for{" "}
                    {(upcomingInvoice.amountDue / 100).toLocaleString("en-US", {
                      style: "currency",
                      currency: upcomingInvoice.currency,
                    })}
                  </p>
                  <Button
                    variant="outlineBlur"
                    onClick={() => navigate("/app/settings/billing")}
                    size="xs"
                  >
                    View billing
                  </Button>
                </div>
              </div>
            ) : null}
          </section>

          {isLoading ? (
            <Skeleton className="h-6 w-full" />
          ) : isSubscriptionActive && !isAppsumoUser ? (
            <div className="w-full border-t dark:border-t-zinc-800 py-4 mt-4">
              <ConfirmCancelSubscriptionDialog
                planDetails={{
                  name: subscriptionDetails.plan,
                  stripeName: subscriptionDetails.planName,
                  title: subscriptionDetails.plan,
                  price: subscriptionDetails.price,
                }}
                hasAddOn={subscriptionDetails.hasAddOn}
                tiers={tiers}
                pricingPeriod={subscriptionDetails.pricingPeriod}
                status={subscriptionData?.status}
              />
            </div>
          ) : null}
        </div>
      </div>
      {/* Single SubscriptionChangeDialog for all operations */}
      <SubscriptionChangeDialog
        isOpen={isDialogOpen}
        close={() => {
          setIsDialogOpen(false);
          queryClient.invalidateQueries(["subscription"]);
        }}
        open={() => setIsDialogOpen(true)}
        title={selectedTier?.title || ""}
        description={selectedTier?.description || ""}
        confirmButtonText={selectedTier?.confirmButtonText || ""}
        plan={{ stripeName: selectedTier?.plan || "", ...selectedTier }}
        hasAddOn={selectedTier?.hasAddOn}
        pricingPeriod={selectedTier?.pricingPeriod}
      />
    </ContentLayout>
  );
};
