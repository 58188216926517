// src/features/settings/components/ConfirmCancelSubscriptionDialog.tsx
import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Textarea,
} from "@/components/Elements";
import { useIdentifyUser } from "@/features/analytics/api/identifyUser";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { useUpdatePlan } from "@/features/subscription/api/updatePlan";
import { useNotificationStore } from "@/stores/notifications";
import { DialogPortal } from "@radix-ui/react-dialog";
import React, { useState } from "react";
import { InputLabel } from "../../../components/Elements";
import { useDeletePlan } from "../../subscription/api/deletePlan";
import { usePausePlan } from "../../subscription/api/pausePlan";

export const ConfirmCancelSubscriptionDialog: React.FC = ({
  planDetails,
  hasAddOn,
  tiers,
  pricingPeriod,
  status,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [discountApplied, setDiscountApplied] = useState(false);
  const cancelSubscriptionMutation = useDeletePlan();
  const pauseSubscriptionMutation = usePausePlan();
  const { mutate: updatePlan, isLoading: updatePlanLoading } = useUpdatePlan();
  const trackEvent = useTrackEvent();
  const { addNotification } = useNotificationStore();
  const identifyUserMutation = useIdentifyUser();
  const [featureName, setFeatureName] = useState<string>("");
  const [productName, setProductName] = useState<string>("");

  const handleTrackCancellation = async () => {
    trackEvent.mutate({
      event: "customer_cancellation",
      properties: JSON.stringify({ reason: selectedReason }),
    });
  };

  const handleCancelSubscription = () => {
    cancelSubscriptionMutation.mutate(undefined, {
      onSuccess: () => {
        handleTrackCancellation();
        if (
          selectedReason === "Missing features" ||
          selectedReason === "Found a different product I like better"
        ) {
          identifyUserMutation.mutate(
            JSON.stringify({
              feature_name: featureName,
              product_name: productName,
            })
          );
        }
        setOpen(false);
        // Add any additional logic you need to handle after successful cancellation
      },
    });
  };

  const handlePauseSubscription = () => {
    pauseSubscriptionMutation.mutate(
      { pause_interval: 1 },
      {
        // Send pause_interval as 1 month
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  const renderCancellationReasons = () => (
    <div className="space-y-4">
      <div>
        <InputLabel label="We are sorry to see you go 😢"></InputLabel>
        <p>
          Please help us improve by letting us know why you are cancelling your
          subscription:
        </p>
      </div>
      <ul className="space-y-2">
        <li>
          <Button
            variant="outlineBlur"
            onClick={() => setSelectedReason("Not sure how to use it")}
          >
            Not sure how to use it
          </Button>
        </li>
        {pricingPeriod === "monthly" &&
          !planDetails?.stripeName?.toLowerCase().includes("enterprise") && (
            <li>
              <Button
                variant="outlineBlur"
                onClick={() => setSelectedReason("Need to pause billing")}
              >
                Need to pause billing
              </Button>
            </li>
          )}
        <li>
          <Button
            variant="outlineBlur"
            onClick={() => setSelectedReason("Missing features")}
          >
            Missing features
          </Button>
        </li>
        {!planDetails?.stripeName?.toLowerCase().includes("enterprise") && (
          <li>
            <Button
              variant="outlineBlur"
              onClick={() => setSelectedReason("Too expensive")}
            >
              Too expensive
            </Button>
          </li>
        )}
        <li>
          <Button
            variant="outlineBlur"
            onClick={() =>
              setSelectedReason("Found a different product I like better")
            }
          >
            Found a different product I like better
          </Button>
        </li>
        <li>
          <Button
            variant="outlineBlur"
            onClick={() =>
              setSelectedReason("I want to cancel my entire account")
            }
          >
            I want to cancel my entire account
          </Button>
        </li>
      </ul>
    </div>
  );

  const renderPauseOption = () => (
    <div className="space-y-2">
      <p>
        Life happens, and we get it. Pause your Frase subscription for a month
        with no penalties. Your account, data, and custom settings will be
        waiting when your subscription automatically resumes next month.
      </p>
      <Button
        variant="outlineBlur"
        onClick={handlePauseSubscription}
        isLoading={pauseSubscriptionMutation.isLoading}
      >
        Pause my subscription for 1 month
      </Button>
      <p
        className="text-red-500 cursor-pointer"
        onClick={handleCancelSubscription}
      >
        No thanks, I just want to cancel
      </p>
    </div>
  );

  const handleApplyDiscount = async () => {
    try {
      let requestPayload = {
        plans: [
          {
            plan: planDetails.stripeName.toLowerCase(),
          },
        ],
        coupon: "churn_recovery",
      };

      // Add add-on to the request if included
      if (hasAddOn) {
        let addonPlan =
          pricingPeriod === "monthly" ? "addon" : "addon_yearly_357";
        requestPayload.plans.push({ plan: addonPlan });
      }

      await updatePlan(requestPayload, {
        onSuccess: () => {
          setDiscountApplied(true);
        },
        onError: (error) => {
          addNotification({
            title: "Failed to apply coupon",
            type: "error",
            message: "Please try again later.",
          });
        },
      });
    } catch (error) {
      console.error("Error applying coupon:", error);
    }
  };

  const renderDiscountOption = () => (
    <div className="space-y-2">
      {discountApplied ? (
        <p>
          A 50% coupon has been applied to your subscription for the next three
          months.
        </p>
      ) : (
        <>
          <p>
            We hear you. How about 50% off for the next 3 months? Full access,
            no commitment. See how Frase can boost your productivity at a
            reduced cost. Interested?
          </p>
          <Button
            variant="outlineBlur"
            onClick={handleApplyDiscount}
            isLoading={updatePlanLoading}
          >
            Yes, apply a 50% discount
          </Button>
          <p
            className="text-red-500 cursor-pointer"
            onClick={handleCancelSubscription}
          >
            No thanks, I just want to cancel
          </p>
        </>
      )}
    </div>
  );

  const renderFeatureRequest = () => (
    <div className="space-y-2">
      <p>
        We're always improving Frase. What features would you find valuable?
        Your input helps shape our product development.
      </p>
      <Textarea
        placeholder="Feature name"
        value={featureName}
        onChange={(e) => setFeatureName(e.target.value)}
      />
      <Button
        variant="primary"
        onClick={() => {
          setOpen(false);
        }}
      >
        Actually, I want to stay
      </Button>
      <p
        className="text-red-500 cursor-pointer"
        onClick={handleCancelSubscription}
      >
        No thanks, I just want to cancel
      </p>
    </div>
  );

  const renderSwitchProduct = () => (
    <div className="space-y-2">
      <p>
        We're bummed you're moving on, but would appreciate it if you could tell
        us who you're switching to
      </p>
      <Textarea
        placeholder="Feature name"
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
      />
      <Button
        variant="primary"
        onClick={() => {
          setOpen(false);
        }}
      >
        Actually, I want to stay
      </Button>
      <p
        className="text-red-500 cursor-pointer"
        onClick={handleCancelSubscription}
      >
        No thanks, I just want to cancel
      </p>
    </div>
  );

  const renderAccountCancellation = () => (
    <div className="space-y-2">
      <p>
        We're sorry to hear you want to cancel completely. Are you sure you want
        to proceed?
      </p>
      <Button
        variant="destructiveBlur"
        onClick={handleCancelSubscription}
        isLoading={cancelSubscriptionMutation.isLoading}
      >
        I understand, cancel my account
      </Button>
      <Button variant="outlineBlur" onClick={() => setOpen(false)}>
        I've changed my mind, keep my account active
      </Button>
    </div>
  );

  const renderSelectedReason = () => {
    switch (selectedReason) {
      case "Not sure how to use it":
        return (
          <div className="space-y-2">
            <p>
              Over 300,000 content creators maximize their potential with Frase.
              Let's unlock its full value for you - book a free session with our
              product expert to discover how Frase can transform your content
              workflow.
            </p>
            <Button
              variant="outlineBlur"
              onClick={() =>
                window.open(
                  "https://meetings.hubspot.com/malachi-walton",
                  "_blank"
                )
              }
            >
              Reserve some time with a product expert
            </Button>
            <p
              className="text-red-500 cursor-pointer"
              onClick={handleCancelSubscription}
            >
              No thanks, I just want to cancel
            </p>
          </div>
        );
      case "Need to pause billing":
        return renderPauseOption();
      case "Too expensive":
        return renderDiscountOption();
      case "Missing features":
        return renderFeatureRequest();
      case "Found a different product I like better":
        return renderSwitchProduct();
      case "I want to cancel my entire account":
        return renderAccountCancellation();
      default:
        return renderCancellationReasons();
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(newOpen) => {
        if (newOpen) {
          setSelectedReason(null);
        }
        setOpen(newOpen);
        setDiscountApplied(false);
      }}
    >
      <DialogTrigger asChild>
        <Button
          variant="outlineBlur"
          className="w-fit"
          textClassName="text-red-500"
          size="xs"
        >
          Cancel subscription
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between">
            {!discountApplied ? (
              <DialogTitle>Confirm subscription cancellation</DialogTitle>
            ) : (
              <DialogTitle>Discount applied</DialogTitle>
            )}
            <DialogCloseButton
              close={() => {
                setOpen(false);
                setSelectedReason(null);
              }}
            />
          </DialogHeader>
          <DialogDescription className="px-4">
            {renderSelectedReason()}
          </DialogDescription>
          <DialogFooter className="px-4 pb-4">
            {selectedReason && !discountApplied && (
              <Button
                variant="outlineBlur"
                onClick={() => setSelectedReason(null)}
              >
                Back
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
