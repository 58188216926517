import { Button } from "@/components/Elements";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Elements/Select";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import {
  ArrowDown,
  ArrowUp,
  BarChart3,
  FileText,
  Search,
  TrendingUp,
  X,
  Zap,
} from "lucide-react";
import { useState } from "react";
import { useGetPageDashboard } from "../api";
import { useContentOpportunitiesStore } from "../store";
import { ContentOpportunityPage, ContentOpportunityPageQuery } from "../types";
import MetricCard from "./MetricCard";
import { OpportunityScoreWithTooltip } from "./OpportunityScoreWithTooltip";
import StatusBadge from "./StatusBadge";

interface PageSidebarContentProps {
  pageOpportunity: ContentOpportunityPage;
  onClose?: () => void;
}

// Component to display a single query item
const QueryItem = ({ query }: { query: ContentOpportunityPageQuery }) => {
  return (
    <div className="p-2 border border-zinc-200 dark:border-zinc-800 rounded-md mb-1.5 hover:bg-zinc-50 dark:hover:bg-zinc-800/50 transition-colors">
      <div className="flex items-center justify-between mb-0.5">
        <span className="font-medium text-xs truncate max-w-[75%]">
          {query.query}
        </span>
        <div className="mt-0.5 flex justify-end">
          <OpportunityScoreWithTooltip score={query.opportunity_score} />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-1 text-2xs text-muted-foreground">
        <div>Pos: {query.position.toFixed(1)}</div>
        <div>Clk: {query.clicks}</div>
        <div>CTR: {(query.ctr * 100).toFixed(1)}%</div>
        <div>Imp: {query.impressions}</div>
      </div>
    </div>
  );
};

// Component to display queries in a dropdown interface
const QueryTabs = ({
  decayingQueries = [],
  trendingUpQueries = [],
  topResultQueries = [],
  quickWinQueries = [],
}: {
  decayingQueries?: ContentOpportunityPageQuery[];
  trendingUpQueries?: ContentOpportunityPageQuery[];
  topResultQueries?: ContentOpportunityPageQuery[];
  quickWinQueries?: ContentOpportunityPageQuery[];
}) => {
  const defaultTab = "quick-win";
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  // Define query type options with their labels and counts
  const queryTypes = [
    {
      value: "quick-win",
      label: "Quick Win",
      count: quickWinQueries.length,
      icon: <Zap className="h-3.5 w-3.5 mr-1.5" />,
    },
    {
      value: "trending-up",
      label: "Trending Up",
      count: trendingUpQueries.length,
      icon: <TrendingUp className="h-3.5 w-3.5 mr-1.5" />,
    },
    {
      value: "top-result",
      label: "Top Result",
      count: topResultQueries.length,
      icon: <ArrowUp className="h-3.5 w-3.5 mr-1.5" />,
    },
    {
      value: "decaying",
      label: "Decaying",
      count: decayingQueries.length,
      icon: <ArrowDown className="h-3.5 w-3.5 mr-1.5" />,
    },
  ];

  // Get the current selected option for display
  const selectedOption =
    queryTypes.find((type) => type.value === selectedTab) || queryTypes[0];

  return (
    <div className="w-full space-y-3">
      <Select defaultValue={defaultTab} onValueChange={setSelectedTab}>
        <SelectTrigger className="w-full">
          <SelectValue>
            <div className="flex items-center">
              {selectedOption.icon}
              <span>{selectedOption.label}</span>
              {selectedOption.count > 0 && (
                <span className="ml-1.5 text-2xs bg-zinc-100 dark:bg-zinc-800 px-1.5 rounded-full">
                  {selectedOption.count}
                </span>
              )}
            </div>
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          {queryTypes.map((type) => (
            <SelectItem key={type.value} value={type.value}>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center">
                  {type.icon}
                  <span>{type.label}</span>
                </div>
                {type.count > 0 && (
                  <span className="ml-1.5 text-2xs bg-zinc-100 dark:bg-zinc-800 px-1.5 rounded-full">
                    {type.count}
                  </span>
                )}
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      {/* Content section - conditionally render based on selected tab */}
      <div className="space-y-1.5 overflow-y-auto pr-1">
        {selectedTab === "decaying" &&
          (decayingQueries.length > 0 ? (
            decayingQueries.map((query) => (
              <QueryItem key={query.query} query={query} />
            ))
          ) : (
            <p className="text-xs text-muted-foreground text-center py-2">
              No decaying queries found.
            </p>
          ))}

        {selectedTab === "trending-up" &&
          (trendingUpQueries.length > 0 ? (
            trendingUpQueries.map((query) => (
              <QueryItem key={query.query} query={query} />
            ))
          ) : (
            <p className="text-xs text-muted-foreground text-center py-2">
              No trending up queries found.
            </p>
          ))}

        {selectedTab === "top-result" &&
          (topResultQueries.length > 0 ? (
            topResultQueries.map((query) => (
              <QueryItem key={query.query} query={query} />
            ))
          ) : (
            <p className="text-xs text-muted-foreground text-center py-2">
              No top result queries found.
            </p>
          ))}

        {selectedTab === "quick-win" &&
          (quickWinQueries.length > 0 ? (
            quickWinQueries.map((query) => (
              <QueryItem key={query.query} query={query} />
            ))
          ) : (
            <p className="text-xs text-muted-foreground text-center py-2">
              No quick win queries found.
            </p>
          ))}
      </div>
    </div>
  );
};

export default function PageSidebarContent({
  pageOpportunity,
  onClose,
}: PageSidebarContentProps) {
  const { siteUrl } = useContentOpportunitiesStore();
  const { data, isLoading } = useGetPageDashboard({
    site_url: siteUrl || "",
    page_url: pageOpportunity.page_url,
    config: {
      enabled: !!siteUrl && !!pageOpportunity.page_url,
    },
  });
  return (
    <div className="space-y-3 p-2.5">
      <div className="flex justify-between items-start mb-2 sticky top-0 bg-white dark:bg-zinc-900 z-10 pb-1">
        <div className="space-y-0.5">
          <div className="flex items-center space-x-2">
            <StatusBadge status={pageOpportunity.status} />
          </div>
          <p className="text-xs text-muted-foreground truncate max-w-[230px]">
            <strong>{pageOpportunity.page_url}</strong>
          </p>
        </div>
        {onClose && (
          <Button
            variant="text"
            size="sm"
            onClick={onClose}
            className="flex-shrink-0 -mt-1"
            aria-label="Close sidebar"
          >
            <X className="h-4 w-4" />
          </Button>
        )}
      </div>

      {/* Compact metrics grid */}
      <div className="grid gap-2 grid-cols-2">
        <MetricCard
          title="Clicks"
          value={pageOpportunity.clicks}
          growth={pageOpportunity.clicks_growth}
          icon={<BarChart3 className="h-3.5 w-3.5" />}
          isPercentage={false}
          dateRange={pageOpportunity.date_range}
          comparisonDateRange={pageOpportunity.comparison_date_range}
          mini={true}
        />
        <MetricCard
          title="Impressions"
          value={pageOpportunity.impressions}
          growth={pageOpportunity.impressions_growth}
          icon={<FileText className="h-3.5 w-3.5" />}
          isPercentage={false}
          dateRange={pageOpportunity.date_range}
          comparisonDateRange={pageOpportunity.comparison_date_range}
          mini={true}
        />
        <MetricCard
          title="CTR"
          value={pageOpportunity.ctr}
          growth={pageOpportunity.ctr_growth}
          icon={<TrendingUp className="h-3.5 w-3.5" />}
          isPercentage={true}
          dateRange={pageOpportunity.date_range}
          comparisonDateRange={pageOpportunity.comparison_date_range}
          mini={true}
        />
        <MetricCard
          title="Position"
          value={pageOpportunity.position}
          growth={pageOpportunity.position_diff}
          icon={<Search className="h-3.5 w-3.5" />}
          isPosition={true}
          dateRange={pageOpportunity.date_range}
          comparisonDateRange={pageOpportunity.comparison_date_range}
          mini={true}
        />
      </div>

      {/* Query Tabs Section */}
      {!isLoading && data && (
        <div className="mt-2">
          <h3 className="text-xs font-medium mb-1.5">Query Opportunities</h3>
          <QueryTabs
            decayingQueries={data.decayingQueryOpportunities}
            trendingUpQueries={data.trendingUpQueryOpportunities}
            topResultQueries={data.topResultQueryOpportunities}
            quickWinQueries={data.quickWinQueryOpportunities}
          />
        </div>
      )}

      {isLoading && (
        <div className="flex justify-center items-center h-full py-8">
          <LogoSpinner variant="sm" loadingText="Loading..." />
        </div>
      )}
    </div>
  );
}
