import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter
} from "@/components/Elements";
import { useNavigate } from "react-router-dom";

interface UpgradeToContinueDialogProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export const UpgradeToContinueDialog = ({
  open,
  onClose,
  onCancel,
}: UpgradeToContinueDialogProps) => {
  const navigate = useNavigate();
  
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <h2 className="text-lg font-medium text-zinc-900 dark:text-white">
            Upgrade your plan
          </h2>
        </DialogHeader>
        <div className="flex flex-col space-y-4 p-4">
          <p className="text-sm text-zinc-500 dark:text-zinc-400">
            You've exceeded the number of domains allowed for your current plan.
            Upgrade to connect more domains.
          </p>
          <DialogFooter>
            <Button variant="outline" onClick={onCancel} size="sm">
              Cancel
            </Button>
            <Button
              onClick={() => {
                navigate("/app/settings/plans");
                onClose();
              }}
              size="sm"
            >
              Upgrade
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradeToContinueDialog;