import { Button, DialogModal, Textarea } from "@/components/Elements";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { TbInfoCircle } from "react-icons/tb";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";
import { useInviteUsersToOrg } from "../../teams/api/inviteUser";

interface EnterpriseInfo {
  pendingInvites?: number | any[];
  usedSeats?: number;
  totalSeats?: number;
  remainingSeats?: number;
  limitReached?: boolean;
  isEnterprise?: boolean;
  [key: string]: any;
}

export const InviteMembersButton = ({
  disabled = false,
  maxTeamSize = Infinity,
  currentTeamSize = 0,
  isEnterprise = false,
  enterpriseInfo = null as EnterpriseInfo | null,
}) => {
  const [open, setOpen] = useState(false);
  const [emails, setEmails] = useState("");
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotificationStore();
  const { mutate: inviteUsers, isLoading: inviteUsersQuery } =
    useInviteUsersToOrg();

  useEffect(() => {
    if (open) {
      setEmails("");
      setLoading(false);
    }
  }, [open]);

  const checkUserExists = async (email) => {
    try {
      const response = await axios.post("/doesUserExistSystem", { email });
      return response; // Assuming the API returns { exists: boolean }
    } catch (error) {
      // Handle error (e.g., show notification)
      return false;
    }
  };

  // Function to check if this is an enterprise plan
  const checkIfEnterprisePlan = async () => {
    try {
      // Import the function for checking enterprise plans
      const { isEnterprisePlan } = await import(
        "../../subscription/api/getAiArticleLimit"
      );

      // Use the useSubscription hook outside of this function scope to get the subscription data
      // Here we just return the check based on the isEnterprise prop or use the plan name
      if (isEnterprise) {
        return true;
      }

      // If we have subscription data from the parent component, use that
      const { data: subscriptionData } = useSubscription({});
      if (subscriptionData) {
        return isEnterprisePlan(subscriptionData);
      }

      return false;
    } catch (error) {
      console.error("Error checking if enterprise plan:", error);
      return false;
    }
  };

  // Function to show the enterprise seat limit dialog
  const showEnterpriseSeatLimitDialog = async () => {
    try {
      // Import the enterprise limit dialog dynamically
      const { EnterpriseWizardLimitDialog } = await import(
        "../../upgrade/components/EnterpriseWizardLimitDialog"
      );

      // Show the limit dialog
      const showDialog = document.createElement("div");
      document.body.appendChild(showDialog);

      const onClose = () => {
        document.body.removeChild(showDialog);
      };

      // For enterprise plans with seat info, use actual enterprise seat data
      const totalSeats =
        isEnterprise && enterpriseInfo?.totalSeats
          ? enterpriseInfo.totalSeats
          : maxTeamSize;

      const usedSeats =
        isEnterprise && enterpriseInfo?.usedSeats
          ? enterpriseInfo.usedSeats
          : currentTeamSize;

      // Render the dialog with current usage statistics
      ReactDOM.render(
        <EnterpriseWizardLimitDialog
          limitType="SEAT"
          usage={usedSeats}
          limit={totalSeats}
          onClose={onClose}
          description={`Adding more members would exceed your plan's seat limit of ${totalSeats} members. Please contact sales to increase your seat limit.`}
        />,
        showDialog
      );
    } catch (error) {
      console.error("Error showing enterprise seat limit dialog:", error);
      // Fallback to notification
      addNotification({
        title: "Seat Limit Reached",
        message: `You've reached your Enterprise plan's seat limit of ${maxTeamSize} seats. Please contact sales to increase your limit.`,
        type: "error",
      });
    }
  };

  const handleSubmit = async () => {
    const processedEmails = emails.split(",").map((email) => email.trim());

    // Check if adding these users would exceed the team size limit
    if (currentTeamSize + processedEmails.length > maxTeamSize) {
      // Check if this is an enterprise plan based on props or fallback to API check
      const planIsEnterprise = isEnterprise || (await checkIfEnterprisePlan());

      if (planIsEnterprise) {
        // Show enterprise-specific limit dialog for enterprise users
        await showEnterpriseSeatLimitDialog();
      } else {
        // Show standard notification for non-enterprise users
        addNotification({
          title: "Team Size Limit",
          message: `Adding ${
            processedEmails.length
          } members would exceed your plan's team size limit of ${maxTeamSize} members. You can add up to ${
            maxTeamSize - currentTeamSize
          } more members.`,
          type: "error",
        });
      }
      setLoading(false);
      return;
    }

    let invalidEmails = [];
    let nonExistentEmails = [];
    let existingEmails = [];
    setLoading(true);

    for (const email of processedEmails) {
      // Simple email validation check
      if (!/^\S+@\S+\.\S+$/.test(email)) {
        invalidEmails.push(email);
      } else {
        // Check if the user exists
        const exists = await checkUserExists(email);
        if (!exists) {
          nonExistentEmails.push(email);
        } else {
          existingEmails.push(email);
        }
      }
    }

    if (invalidEmails.length > 0) {
      addNotification({
        title: "Invalid Email",
        message: `The following emails are invalid: ${invalidEmails.join(
          ", "
        )}.`,
        type: "error",
      });
      setLoading(false);
      return;
    }

    if (nonExistentEmails.length > 0) {
      const payload = {
        userEmails: nonExistentEmails.map((email) => ({ email, role: 2 })),
      };

      // Use the `inviteUsers` mutation to send invites
      inviteUsers(payload, {
        onSuccess: () => {
          addNotification({
            title: `${nonExistentEmails.length} invites sent`,
            message: `Invites have been sent to the following emails: ${nonExistentEmails.join(
              ", "
            )}.`,
            type: "success",
          });
          setOpen(false);
        },
        onError: (error) => {
          // Handle the error case
          addNotification({
            title: "Error",
            message: "There was an error sending the invites.",
            type: "error",
          });
        },
      });
    } else {
      addNotification({
        title: "Users already exist",
        message: `The users with the following emails already exist: ${processedEmails.join(
          ", "
        )}.`,
        type: "info",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        type="button"
        variant="outlineBlur"
        size="xs"
        className="flex-shrink-0 h-8"
        onClick={() => {
          if (disabled) {
            // When clicked while disabled, show an explanatory notification
            const pendingCount =
              enterpriseInfo && "pendingInvites" in enterpriseInfo
                ? typeof enterpriseInfo.pendingInvites === "number"
                  ? enterpriseInfo.pendingInvites
                  : Array.isArray(enterpriseInfo.pendingInvites)
                  ? enterpriseInfo.pendingInvites.length
                  : 0
                : 0;

            const message =
              pendingCount > 0
                ? `You have reached your seat limit of ${maxTeamSize}. Note that your ${pendingCount} pending invite${
                    pendingCount !== 1 ? "s" : ""
                  } count${
                    pendingCount === 1 ? "s" : ""
                  } toward your total seat allocation.`
                : `You have reached your seat limit of ${maxTeamSize}.`;

            addNotification({
              title: "Cannot invite more members",
              message,
              type: "info",
            });
          } else {
            setOpen(true);
          }
        }}
        title={
          disabled ? `Team size limit of ${maxTeamSize} members reached` : ""
        }
      >
        Invite members
      </Button>
      <DialogModal
        open={open}
        setOpen={setOpen}
        className="gap-0"
        variant="fitContent"
        title="Invite members by email"
        footer={
          <div className="mt-4 text-center flex-col">
            <Button
              type="submit"
              variant="primaryBlur"
              disabled={emails.length === 0}
              isLoading={inviteUsersQuery || loading}
              onClick={handleSubmit}
            >
              Send invites
            </Button>
          </div>
        }
      >
        <div className="mt-2">
          <Textarea
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            placeholder="Enter email addresses separated by commas"
            rows={3}
          />
          {maxTeamSize !== Infinity && (
            <div className="flex items-center space-x-2">
              <TbInfoCircle className="text-zinc-500" />
              <p className="text-xs text-zinc-500">
                You can add up to {maxTeamSize - currentTeamSize} more team
                members
              </p>
            </div>
          )}
        </div>
      </DialogModal>
    </>
  );
};
