import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { cn } from "@/utils/style";
import { useState } from "react";
import {
  TbBulb,
  TbChartBar,
  TbChevronDown,
  TbChevronRight,
  TbSparkles,
  TbTarget,
} from "react-icons/tb";
import { useGetSiteDashboardAI } from "../api/getSiteDashboardAI";
import { SiteDashboardData } from "../types";

const RecommendedFocusAreaAccordion = ({
  areas,
}: {
  areas: { title: string; description: string }[];
}) => {
  const [openIndex, setOpenIndex] = useState(areas.length > 0 ? 0 : -1);

  return (
    <div className="flex flex-col gap-2 mt-2">
      {areas.map((area, index) => (
        <div
          key={index}
          className="border border-zinc-200 dark:border-zinc-700 rounded-md overflow-hidden"
        >
          <button
            onClick={() => setOpenIndex(index === openIndex ? -1 : index)}
            className={cn(
              "w-full px-3 py-2 flex items-center justify-between",
              "text-left font-medium text-zinc-900 dark:text-white text-sm",
              "bg-zinc-50 dark:bg-zinc-800/50 hover:bg-zinc-100 dark:hover:bg-zinc-800",
              "transition-colors duration-200 ease-in-out"
            )}
          >
            <span>{area.title}</span>
            {openIndex === index ? (
              <TbChevronDown className="w-4 h-4 text-emerald-600 transition-transform duration-200 ease-in-out" />
            ) : (
              <TbChevronRight className="w-4 h-4 text-emerald-600 transition-transform duration-200 ease-in-out" />
            )}
          </button>

          <div
            className={cn(
              "overflow-hidden",
              openIndex === index
                ? "transition-all duration-300 ease-in-out max-h-96"
                : "transition-all duration-150 ease-in-out max-h-0"
            )}
          >
            <div className="px-3 py-2 text-xs text-zinc-600 dark:text-zinc-400 leading-relaxed bg-white dark:bg-zinc-900">
              <FormatDescriptionText text={area.description} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
// Helper component to format description text and detect numbered list items
const FormatDescriptionText = ({ text }: { text: string }) => {
  // Process bolded text enclosed in double asterisks
  const processTextWithBold = (content: string) => {
    const parts = content.split(/(\*\*.*?\*\*)/g);

    if (parts.length === 1) {
      return content;
    }

    return (
      <>
        {parts.map((part, i) => {
          if (part.startsWith("**") && part.endsWith("**")) {
            const boldText = part.slice(2, -2);
            return (
              <strong
                key={i}
                className="font-medium text-zinc-800 dark:text-zinc-200"
              >
                {boldText}
              </strong>
            );
          }
          return part;
        })}
      </>
    );
  };

  // Split text into lines
  const lines = text.split("\n");

  // Check for lists with numbers or hyphens/dashes
  const hasNumberedItems = lines.some((line) => /^\d+\./.test(line.trim()));
  const hasDashItems = lines.some((line) => /^[-–—]/.test(line.trim()));

  // If no lists are detected, return the text with bold processing
  if (!hasNumberedItems && !hasDashItems) {
    return <>{processTextWithBold(text)}</>;
  }

  return (
    <div className="space-y-2">
      {lines.map((line, index) => {
        const trimmedLine = line.trim();

        // Check for numbered list items (1. Item)
        const numberedMatch = trimmedLine.match(/^(\d+)\.(.*)/);
        if (numberedMatch) {
          const [_, number, content] = numberedMatch;
          return (
            <div key={index} className="flex items-start gap-1.5">
              <span className="flex-shrink-0 text-emerald-600 dark:text-emerald-500 font-medium text-xs min-w-5">
                {number}.
              </span>
              <span className="text-xs text-zinc-600 dark:text-zinc-400">
                {processTextWithBold(content.trim())}
              </span>
            </div>
          );
        }

        // Check for dash list items (- Item, – Item, or — Item)
        const dashMatch = trimmedLine.match(/^([-–—])(.*)/);
        if (dashMatch) {
          const [_, dash, content] = dashMatch;
          return (
            <div key={index} className="flex items-start gap-1.5">
              <span className="flex-shrink-0 text-emerald-600 dark:text-emerald-500 font-medium text-xs min-w-5">
                •
              </span>
              <span className="text-xs text-zinc-600 dark:text-zinc-400">
                {processTextWithBold(content.trim())}
              </span>
            </div>
          );
        }

        // Regular paragraph text
        return (
          <p key={index} className="text-xs text-zinc-600 dark:text-zinc-400">
            {processTextWithBold(line)}
          </p>
        );
      })}
    </div>
  );
};

const SiteAIRecommendationsDialog = ({
  siteDashboard,
  show,
  onHide,
}: {
  siteDashboard: SiteDashboardData | undefined;
  show: boolean;
  onHide: () => void;
}) => {
  const { data, isLoading } = useGetSiteDashboardAI({
    site_dashboard: siteDashboard as SiteDashboardData,
    config: {
      enabled: !!siteDashboard && show,
      staleTime: 24 * 60 * 60 * 1000,
      cacheTime: 24 * 60 * 60 * 1000,
    },
  });

  return (
    <Dialog open={show} onOpenChange={onHide}>
      {/** @ts-ignore */}
      <DialogContent className="max-w-[1250px] mx-12" variant="templates">
        <DialogHeader className="py-3 flex items-center justify-between">
          <DialogTitle className="flex items-center gap-2">
            <TbSparkles
              className="text-emerald-600 w-4 h-4"
              style={{ fill: "#059669" }}
            />
            AI Overview
          </DialogTitle>
          <DialogCloseButton close={onHide} />
        </DialogHeader>
        <DialogDescription className="px-4 py-2 min-h-[500px] max-h-[75vh]">
          {isLoading && (
            <div className="flex items-center justify-center h-[calc(100%-1rem)] min-h-[450px]">
              <LogoSpinner variant="md" loadingText="Loading AI Overview..." />
            </div>
          )}

          {!isLoading && data && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div className="flex flex-col gap-4">
                {/* Summary Section */}
                <div className="bg-white dark:bg-zinc-900 p-4 rounded-md border border-zinc-200 dark:border-zinc-700 shadow-sm">
                  <div className="flex items-center gap-2 mb-2">
                    <TbChartBar className="text-emerald-600 w-4 h-4" />
                    <h3 className="text-base font-semibold text-zinc-900 dark:text-white">
                      Summary
                    </h3>
                  </div>
                  <p className="text-zinc-700 dark:text-zinc-300 text-xs leading-relaxed">
                    {data.summary}
                  </p>
                </div>

                {/* Insights Section */}
                <div className="bg-white dark:bg-zinc-900 p-4 rounded-md border border-zinc-200 dark:border-zinc-700 shadow-sm">
                  <div className="flex items-center gap-2 mb-2">
                    <TbBulb className="text-emerald-600 w-4 h-4" />
                    <h3 className="text-base font-semibold text-zinc-900 dark:text-white">
                      Key Insights
                    </h3>
                  </div>
                  <ul className="space-y-2 max-h-[calc(70vh-8rem)] overflow-y-auto pr-1">
                    {data.insights.map((insight, index) => (
                      <li
                        key={index}
                        className={cn(
                          "flex items-start gap-2 p-2 rounded-md",
                          "bg-zinc-50 dark:bg-zinc-800/50 hover:bg-zinc-100 dark:hover:bg-zinc-800",
                          "transition-colors duration-100 ease-in-out"
                        )}
                      >
                        <span className="flex-shrink-0 text-emerald-500 bg-emerald-100 dark:bg-emerald-900/30 w-5 h-5 rounded-full flex items-center justify-center mt-0.5">
                          •
                        </span>
                        <span className="text-zinc-700 dark:text-zinc-300 text-xs">
                          {insight}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="bg-white dark:bg-zinc-900 p-4 rounded-md border border-zinc-200 dark:border-zinc-700 shadow-sm">
                <div className="flex items-center gap-2 mb-2">
                  <TbTarget className="text-emerald-600 w-4 h-4" />
                  <h3 className="text-base font-semibold text-zinc-900 dark:text-white">
                    Recommended Focus Areas
                  </h3>
                </div>
                <RecommendedFocusAreaAccordion
                  areas={data.recommendedFocusAreas}
                />
              </div>
            </div>
          )}
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};

export default SiteAIRecommendationsDialog;
