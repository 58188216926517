import { OpportunityScoreWithTooltip } from "./OpportunityScoreWithTooltip";

/**
 * Props for the ScoreDisplay component
 */
interface ScoreDisplayProps {
  /** The score value to display */
  score: number;
  /** The maximum possible score (defaults to 100) */
  maxScore?: number;
  /** Whether this is an opportunity score that should use color coding */
  isOpportunityScore?: boolean;
}

/**
 * A component that displays a score out of a maximum value
 * Used to show metrics like content quality scores or rankings
 */
export default function ScoreDisplay({
  score,
  maxScore = 100,
  isOpportunityScore = false,
}: ScoreDisplayProps) {
  // If this is an opportunity score, use the OpportunityScoreWithTooltip component
  if (isOpportunityScore) {
    return <OpportunityScoreWithTooltip score={score} size="sm" />;
  }

  // Default display for non-opportunity scores
  return (
    <div className="flex items-center">
      <span className="font-medium text-zinc-900 dark:text-white">{score}</span>
      <span className="text-xs text-muted-foreground dark:text-zinc-400">
        /{maxScore}
      </span>
    </div>
  );
}
