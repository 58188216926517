import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useDocumentStore } from "@/stores/document";
import { DialogPortal } from "@radix-ui/react-dialog";
import { useState } from "react";
import { TbSparkles, TbTrash } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useUpdateDocument } from "../api/updateDocument";

export const ContinueWizardGenerationDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { document: fraseDocument } = useDocumentStore();
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
    isResolvingConflict: true,
  });
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription({});
  const isAddOnActive = subscriptionData?.add_on;
  const navigate = useNavigate();

  const [isCancellingAiArticle, setIsCancellingAiArticle] = useState(false);

  const handleCancelAiArticle = async () => {
    setIsCancellingAiArticle(true);

    const updatedFraseDocument = {
      ...fraseDocument,
      metadata: {
        ...fraseDocument.metadata,
        ai_article_started: undefined,
      },
    };

    // check if the document has been edited by another user
    await updateDocumentMutation.mutateAsync(updatedFraseDocument);
    setIsCancellingAiArticle(false);
  };

  const handleOpenAiWizard = () => {
    navigate("/app/wizard", {
      state: { shouldPrepopulate: true },
    });
  };

  const body = (
    <div className="flex text-zinc-600 dark:text-zinc-400 items-center">
      <p className="text-sm">
        You're almost done! You started generating an article. Do you want to
        continue generating the article or cancel it?
      </p>
    </div>
  );

  const handleOpenChange = (open) => {
    setOpen(open);
    if (open) {
      // Reset the state when the dialog is opened
    }
  };

  const confirmButton = (
    <div className="flex items-center space-x-2">
      <Button
        variant="outlineBlur"
        onClick={() => {
          handleCancelAiArticle();
          setOpen(false);
        }}
        isLoading={isCancellingAiArticle}
        startIcon={<TbTrash />}
      >
        Cancel article
      </Button>
      <Button
        variant="aiBlur"
        onClick={() => {
          handleOpenAiWizard();
          setOpen(false);
        }}
        startIcon={<TbSparkles />}
        disabled={isLoadingSubscription}
        isLoading={isLoadingSubscription}
      >
        Continue generating
      </Button>
    </div>
  );

  return (
    <Dialog onOpenChange={handleOpenChange} open={open} modal>
      <DialogPortal>
        <DialogContent
          variant="fitContent"
          className="w-fit"
          preventCloseOnEscape={true}
          onPointerDownOutside={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>Continue generating article with AI?</DialogTitle>
          </DialogHeader>
          <DialogDescription className="px-4 pb-4">{body}</DialogDescription>
          <DialogFooter className="px-4 pb-4">{confirmButton}</DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
