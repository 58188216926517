import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";
import { ExtractFnReturnType, QueryConfig } from "../../../lib/react-query";

export type PaymentMethodResponse = {
  payment_method: {
    id: string;
    card: {
      brand: string;
      last4: string;
      exp_month: number;
      exp_year: number;
    };
  } | null;
};

export const getUserPaymentMethod = (): Promise<PaymentMethodResponse> => {
  return axios.get("/stripe/getUserPaymentMethod");
};

type QueryFnType = () => Promise<PaymentMethodResponse>;

type UseGetUserPaymentMethodOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useGetUserPaymentMethod = ({
  config,
}: UseGetUserPaymentMethodOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["userPaymentMethod"],
    queryFn: () => getUserPaymentMethod(),
  });
};
