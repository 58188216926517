export function addCommasToNumber(number: number): string {
  // Convert to string and handle negative numbers
  const str = number.toString();
  const isNegative = str[0] === "-";
  const absStr = isNegative ? str.slice(1) : str;

  // Split into integer and decimal parts
  const [integerPart, decimalPart] = absStr.split(".");

  // Add commas to integer part
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Combine parts
  let result = formattedInteger;
  if (decimalPart !== undefined) {
    result += `.${decimalPart}`;
  }

  // Add negative sign back if needed
  return isNegative ? `-${result}` : result;
}

export function getGSCPlanNickname(
  name: string
): "Solo" | "Team" | "Basic" | "Growth" | "Free" | "Enterprise" {
  if (name.includes("lifetime")) {
    // We give them a team plan domain limit as they are grandfathered in
    return "Team";
  } else if (name.includes("solo")) {
    return "Solo";
  } else if (name.includes("team")) {
    return "Team";
  } else if (name.includes("enterprise")) {
    return "Enterprise";
  } else if (name.includes("basic")) {
    return "Basic";
  } else if (name.includes("answer")) {
    return "Growth";
  } else {
    return "Free";
  }
}
