import { axios } from "@/lib/axios";
import { useNotificationStore } from "@/stores/notifications";
import { useMutation } from "react-query";

interface Plan {
  plan: string;
  quantity?: number;
}

interface UpdateUserSubscriptionParams {
  plans: Plan[];
}

interface UpdateUserSubscriptionResponse {
  success: boolean;
}

const updateUserSubscription = async (
  params: UpdateUserSubscriptionParams
): Promise<UpdateUserSubscriptionResponse> => {
  try {
    const response = await axios.post<UpdateUserSubscriptionResponse>(
      "/stripe/updateUserSubscription",
      params
    );

    return response;
  } catch (error) {
    console.error("Error in updateUserSubscription:", error);
    throw error;
  }
};

export const useUpdateUserSubscription = () => {
  const { addNotification } = useNotificationStore();

  return useMutation(updateUserSubscription, {
    onError: (error: any) => {
      console.error("Subscription update error:", error);
      addNotification({
        type: "error",
        title: "Error",
        message:
          error.response?.data?.message || "Failed to update subscription",
      });
    },
  });
};
