import { useMutation, useQuery } from "react-query";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

interface CreateIntentRequest {
  plans: Array<{ plan: string; quantity?: number }>;
  coupon?: string;
}

interface PaymentIntentResponse {
  client_secret: string;
  payment_intent_id: string;
  amount: number;
  requires_payment: boolean;
}

interface PaymentStatusResponse {
  status:
    | "succeeded"
    | "processing"
    | "requires_payment_method"
    | "requires_confirmation"
    | "canceled";
  payment_intent_id?: string;
  error?: string;
}

const createPaymentIntent = async (
  requestData: CreateIntentRequest
): Promise<PaymentIntentResponse> => {
  const data = await axios.post("/stripe/createIntent", requestData);
  return data;
};

const checkPaymentStatus = async (): Promise<PaymentStatusResponse> => {
  const data = await axios.post("/stripe/checkPaymentStatus");
  return data;
};

export const useCreatePaymentIntent = () => {
  const { addNotification } = useNotificationStore();

  return useMutation(createPaymentIntent, {
    onError: (error) => {
      addNotification({
        type: "error",
        title: "Payment setup failed",
        message: `An error occurred: ${error.message}. Please try again or contact support.`,
      });
    },
  });
};

export const useCheckPaymentStatus = (enabled: boolean = false) => {
  const { addNotification } = useNotificationStore();

  return useQuery("checkPaymentStatus", checkPaymentStatus, {
    enabled,
    refetchInterval: 2000, // Poll every 2 seconds
    refetchIntervalInBackground: false,
    onError: (error) => {
      addNotification({
        type: "error",
        title: "Payment status check failed",
        message: `An error occurred: ${error.message}. Please try again or contact support.`,
      });
    },
    onSuccess: (data) => {
      if (data.error) {
        addNotification({
          type: "error",
          title: "Payment failed",
          message: `An error occurred: ${data.error}. Please try again or contact support.`,
        });
      }
    },
  });
};
