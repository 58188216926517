import { useDocumentStore } from "@/stores/document";
import { useSerpStore } from "@/stores/serp";
import { cn } from "@/utils/style";
import { AnimatePresence, motion } from "framer-motion";
import { truncate } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { TbArrowRight } from "react-icons/tb";
import { Button, Progress } from "../../../components/Elements";
import { LogoSpinner } from "../../../components/Elements/Spinner/LogoSpinner";
import { FraseDocument } from "../../documents";

export const WizardGeneratingMessage = ({
  fraseDocument,
  title,
  onNavigateToDocument = () => {},
  renderNavigationButton = true,
  progress,
  articleInput,
}: {
  fraseDocument: FraseDocument;
  title?: string;
  onNavigateToDocument?: () => void;
  renderNavigationButton?: boolean;
  progress?: number;
  articleInput?: any;
}) => {
  // Get the active message based on progress and document data
  const [activeMessageIndex, setActiveMessageIndex] = useState(0);

  // Helper function to format text with emerald highlights
  const formatWithHighlight = (
    prefix: string,
    highlight: string,
    suffix: string
  ) => {
    return `${prefix}<span class="text-emerald-600 dark:text-emerald-400 font-medium"> ${highlight}</span>${suffix}`;
  };

  // Generate appropriate messages based on document data
  const generationMessages = useMemo(() => {
    // Extract key data from article input
    const query = articleInput?.query || title || "topic";
    const outlineHeadings =
      articleInput?.outline?.map((item: any) => item.heading) || [];
    const tone = articleInput?.tone || "";
    const topics = articleInput?.topics || "";
    const globalInstructions = articleInput?.global_instructions || "[]";

    // Parse global instructions if they exist and aren't empty
    let parsedInstructions = [];
    try {
      const instructionsValue =
        globalInstructions === "[]" ? [] : JSON.parse(globalInstructions);
      parsedInstructions = Array.isArray(instructionsValue)
        ? instructionsValue
        : [];
    } catch (e) {
      parsedInstructions = [];
    }

    // Helper function to truncate topics to a maximum combined length using Lodash
    const truncateTopics = (
      topicsList: string[],
      maxTotalLength: number = 40
    ) => {
      let totalLength = 0;
      return topicsList
        .map((topic, index) => {
          // Add length of topic plus comma and space for all but the last item
          const additionalLength =
            index < topicsList.length - 1 ? topic.length + 2 : topic.length;

          if (totalLength + additionalLength <= maxTotalLength) {
            totalLength += additionalLength;
            return topic;
          } else {
            // If this topic would exceed the max length, truncate it using Lodash
            const remainingSpace = Math.max(0, maxTotalLength - totalLength);
            if (remainingSpace <= 5) return null; // Skip if not enough space for meaningful truncation

            // Use Lodash truncate which respects word boundaries
            totalLength = maxTotalLength;
            return truncate(topic, {
              length: remainingSpace,
              omission: "...",
              separator: " ",
            });
          }
        })
        .filter(Boolean) as string[];
    };

    // Extract key topics (limit to 3 for display purposes)
    const keyTopics = truncateTopics(
      topics
        .split(",")
        .map((t: string) => t.trim())
        .filter((t: string) => t.length > 0 && t.length < 30) // Filter out empty or overly long topics
        .slice(0, 3)
    );

    // Base messages that will always be available
    const messages = [
      {
        text: formatWithHighlight(
          "Researching",
          query,
          " for quality insights..."
        ),
        type: "research",
        html: true,
      },
    ];

    // Add topic-specific research messages if available
    if (keyTopics.length > 0) {
      messages.push({
        text: `Gathering information on <span class="text-emerald-600 dark:text-emerald-400 font-medium">${keyTopics.join(
          ", "
        )}</span>...`,
        type: "research",
        html: true,
      });
    }

    // Add competitor analysis message
    messages.push({
      text: "Analyzing search competitors...",
      type: "research",
      html: false,
    });

    // Add content writing messages for each outline heading if available
    if (outlineHeadings.length > 0) {
      outlineHeadings.forEach((heading: string) => {
        messages.push({
          text: formatWithHighlight(
            "Writing section for the heading",
            heading,
            "..."
          ),
          type: "content",
          html: true,
        });
      });
    } else {
      // Generic content messages if no outline is available
      messages.push(
        {
          text: "Crafting article introduction...",
          type: "content",
          html: false,
        },
        {
          text: "Developing main content sections...",
          type: "content",
          html: false,
        },
        {
          text: "Adding supporting details and evidence...",
          type: "content",
          html: false,
        }
      );
    }

    // Add tone-specific message if tone is specified
    if (tone) {
      messages.push({
        text: formatWithHighlight("Applying", tone, " tone to content..."),
        type: "optimize",
        html: true,
      });
    }

    // Add global instructions message if available
    if (parsedInstructions.length > 0) {
      messages.push({
        text: "Applying custom writing instructions...",
        type: "optimize",
        html: false,
      });
    } else {
      // Standard optimization message
      messages.push({
        text: "Optimizing content for search engine visibility...",
        type: "optimize",
        html: false,
      });
    }

    // Add refinement messages
    messages.push(
      {
        text: "Refining language and readability...",
        type: "refine",
        html: false,
      },
      {
        text: "Applying final touches to content...",
        type: "refine",
        html: false,
      }
    );

    return messages;
  }, [fraseDocument, title]);

  // Cycle through messages in order
  useEffect(() => {
    // Start cycling through messages once we have them
    if (generationMessages.length > 0) {
      const interval = setInterval(() => {
        setActiveMessageIndex((prev) => {
          // When we reach the end, go back to the beginning
          if (prev >= generationMessages.length - 1) {
            return 0;
          }
          return prev + 1;
        });
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [generationMessages]);

  // Get current active message
  const activeMessage =
    generationMessages[activeMessageIndex] || generationMessages[0];

  // Reset animation when document changes
  useEffect(() => {
    // Reset animation logic if needed
  }, [fraseDocument.id]);

  // Get SERP data if available
  const { document: documentStore } = useDocumentStore();
  const docId = documentStore?.id;
  const { serp } = useSerpStore();
  const currentSerp = serp[docId] || { serpLoaded: false };
  const searchResults = currentSerp?.results || [];

  return (
    <div className="flex flex-col items-center space-y-4 w-full h-full">
      {currentSerp?.serpLoaded && searchResults.length > 0 ? (
        <div className="flex flex-col items-center space-y-2 mt-16">
          <LogoSpinner
            variant="sm"
            searchResults={searchResults}
            loadingText="Processing search results..."
          />
        </div>
      ) : (
        <div className="flex items-center space-x-2 mt-20">
          <p className="text-zinc-900 font-medium text-lg dark:text-white">
            Crafting your article with Rank-Ready AI
          </p>
        </div>
      )}
      <Progress
        progress={Math.round((progress || 0) * 10)}
        className={`w-[400px]`}
        stripColor="emerald"
        height={3}
      />
      <p className="text-zinc-500 text-xs">This may take a few minutes</p>
      <div
        className="bg-zinc-50 dark:bg-zinc-800 flex flex-col border-l border-r border-t w-1/2 overflow-hidden max-w-[700px] rounded-tl-lg rounded-tr-lg relative dark:border-zinc-700 py-8"
        style={{
          maskImage: "linear-gradient(to bottom, black 60%, transparent 90%)",
          WebkitMaskImage:
            "linear-gradient(to bottom, black 30%, transparent 90%)",
          maxHeight: "700px",
        }}
      >
        {title && (
          <p className="font-semibold text-zinc-900 text-lg dark:text-white pl-8">
            {title}
          </p>
        )}
        <div className="text-zinc-600 dark:text-zinc-300 text-sm h-6 flex items-center justify-center py-12">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeMessageIndex}
              className="flex items-center gap-2"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
              }}
            >
              <motion.div
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{
                  duration: 0.3,
                  delay: 0.2,
                  type: "spring",
                  stiffness: 300,
                }}
              ></motion.div>
              {activeMessage.html ? (
                <span
                  className="font-medium"
                  dangerouslySetInnerHTML={{ __html: activeMessage.text }}
                />
              ) : (
                <span className="font-medium">{activeMessage.text}</span>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
        {Array.from({ length: 6 }).map((_, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, scaleY: 0.5 }}
            animate={{ opacity: 1, scaleY: 1 }}
            exit={{ opacity: 0, scaleY: 0.5 }}
            transition={{
              delay: index * 0.3,
              duration: 0.5,
              repeat: Infinity,
              repeatType: "reverse",
              repeatDelay: 1.5,
            }}
            className={cn(
              "h-[9px] mb-3 mx-8 bg-emerald-600/30 rounded-md dark:bg-emerald-400/10",
              index === 0 && "ml-16",
              index === 5 && "mr-16"
            )}
          ></motion.div>
        ))}

        <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-white to-transparent dark:from-zinc-900 dark:to-transparent"></div>
      </div>
      {renderNavigationButton && (
        <Button
          variant="primaryBlur"
          onClick={() => {
            if (onNavigateToDocument) {
              onNavigateToDocument();
            }
          }}
          endIcon={<TbArrowRight />}
        >
          Go to document
        </Button>
      )}
      {/*<Button variant="outlineBlur" onClick={handleCancel} size="2xs">
        Cancel
        </Button>*/}
    </div>
  );
};
