import { DateRange, GSCContentStatus } from "./types";

// Helper function to format numbers
export const formatNumber = (num: number): string => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  }
  return num.toString();
};

// Helper function to format percentages
export const formatPercentage = (num: number): string => {
  return num.toFixed(2) + "%";
};

// Helper function to format growth as percentage
export const formatGrowth = (growth: number): string => {
  // Handle edge case where growth is exactly 1 (no change)
  if (growth === 1) return "0.0%";

  const percentage = ((growth - 1) * 100).toFixed(1);
  return growth > 1 ? `+${percentage}%` : `${percentage}%`;
};

// Helper function to get status badge color
export const getStatusColor = (status: GSCContentStatus): string => {
  switch (status) {
    case "decay":
      return "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300";
    case "trending up":
      return "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300";
    case "top result":
      return "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300";
    case "ranked":
      return "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300";
    case "unranked":
      return "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300";
    case "quick win":
      return "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300";
    case "missing":
      return "bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-300";
    default:
      return "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300";
  }
};

export function getMetricColor(metric: string): string {
  switch (metric) {
    case "ctr":
      return "#F87171";
    case "impressions":
      return "#059669";
    case "clicks":
      return "#FCD34D";
    case "position":
      return "#8b5cf6";
    default:
      return "#3b82f6";
  }
}

// Helper function to format date range
export const formatDateRange = (dateRange: DateRange): string => {
  const startDate = new Date(dateRange.start_date);
  const endDate = new Date(dateRange.end_date);

  return `${startDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  })} - ${endDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  })}`;
};

/**
 * Returns the appropriate color classes for an opportunity score based on its value
 * @param score The opportunity score (0-100)
 * @returns CSS class string for the score
 */
export const getOpportunityScoreColorClasses = (score: number): string => {
  if (score >= 71) {
    return "text-emerald-600 dark:text-emerald-400 bg-emerald-50 dark:bg-emerald-900/20";
  } else if (score >= 50) {
    return "text-amber-600 dark:text-amber-400 bg-amber-50 dark:bg-amber-900/20";
  } else {
    return "text-red-600 dark:text-red-400 bg-red-50 dark:bg-red-900/20";
  }
};
