"use client";

import * as React from "react";

import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/Elements/Resizable";
import { cn } from "@/utils/style";

/**
 * Props for the ResizableSidebar component
 */
interface ResizableSidebarProps {
  /** Main content to display */
  children: React.ReactNode;
  /** Content to display in the sidebar */
  sidebarContent: React.ReactNode;
  /** Default size of the sidebar as a percentage (1-100) */
  defaultSize?: number;
  /** Minimum size of the sidebar as a percentage */
  minSize?: number;
  /** Maximum size of the sidebar as a percentage */
  maxSize?: number;
  /** Whether the sidebar is open by default */
  defaultOpen?: boolean;
  /** Which side to place the sidebar on */
  side?: "left" | "right";
  /** Additional CSS classes to apply to the container */
  className?: string;
}

/**
 * A resizable sidebar component that adapts to mobile and desktop layouts
 * Provides a responsive experience with different behavior based on screen size
 */
export function ResizableSidebar({
  children,
  sidebarContent,
  defaultSize = 30,
  minSize = 15,
  maxSize = 40,
  defaultOpen = false,
  side = "right",
  className,
}: ResizableSidebarProps) {
  // Track if we're on a mobile device
  const [isMobile, setIsMobile] = React.useState(false);

  // Check screen size on mount and when window resizes
  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    checkMobile();

    // Add resize listener
    window.addEventListener("resize", checkMobile);

    // Clean up listener on unmount
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Render mobile layout
  if (isMobile) {
    return (
      <div className={cn("h-full w-full", className)}>
        <div className="flex h-full flex-col">
          <div
            className={cn(
              "fixed inset-y-0 z-40 flex w-3/4 max-w-xs flex-col bg-background transition-transform duration-300 ease-in-out",
              side === "left" ? "left-0" : "right-0",
              defaultOpen
                ? "translate-x-0"
                : side === "left"
                ? "-translate-x-full"
                : "translate-x-full"
            )}
          >
            {/* Sidebar content with proper dimensions */}
            <div className="flex-1 overflow-auto" style={{ width: "100%" }}>
              {sidebarContent}
            </div>
          </div>

          <div className="h-full w-full overflow-visible">{children}</div>
        </div>
      </div>
    );
  }

  // Render desktop layout - sidebar closed
  if (!defaultOpen) {
    return (
      <div className={cn("h-full w-full", className)}>
        <div className="h-full overflow-visible">{children}</div>
      </div>
    );
  }

  // Render desktop layout - sidebar open
  return (
    <div className={cn("h-full w-full", className)}>
      <ResizablePanelGroup direction="horizontal" className="h-full">
        {side === "left" ? (
          <>
            <ResizablePanel
              defaultSize={defaultSize}
              minSize={minSize}
              maxSize={maxSize}
            >
              <div className="h-full overflow-auto border-r">
                {sidebarContent}
              </div>
            </ResizablePanel>
            <ResizableHandle />
            <ResizablePanel defaultSize={100 - defaultSize}>
              <div className="h-full overflow-visible">{children}</div>
            </ResizablePanel>
          </>
        ) : (
          <>
            <ResizablePanel defaultSize={100 - defaultSize}>
              <div className="h-full overflow-y-auto">{children}</div>
            </ResizablePanel>
            <ResizableHandle />
            <ResizablePanel
              defaultSize={defaultSize}
              minSize={minSize}
              maxSize={maxSize}
            >
              <div className="h-full overflow-auto border-l">
                {sidebarContent}
              </div>
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>
    </div>
  );
}
