import { DateRange } from "../types";
import { getMetricColor } from "../utils";
import MetricCard from "./MetricCard";

/**
 * Metric item configuration
 */
interface MetricItem {
  /** Title of the metric */
  title: string;
  /** Current value of the metric */
  value: number;
  /** Growth rate compared to previous period */
  growth: number;
  /** Icon to display with the metric */
  icon: React.ReactNode;
  /** Whether the value should be formatted as a percentage */
  isPercentage?: boolean;
  /** Whether this metric represents a position (where lower is better) */
  isPosition?: boolean;
  /** Unique identifier for the metric (lowercase title by default) */
  id?: string;
}

/**
 * Props for the MetricCardsGrid component
 */
interface MetricCardsGridProps {
  /** Array of metrics to display */
  metrics: MetricItem[];
  /** Current date range for the metrics */
  dateRange: DateRange;
  /** Previous date range for comparison */
  comparisonDateRange: DateRange;
  /** Currently selected metric ID */
  selectedMetric?: string;
  /** Callback when a metric is selected */
  onSelectMetric?: (metricId: string) => void;
}

/**
 * A responsive grid layout for displaying multiple metric cards
 * Automatically adjusts columns based on screen size
 * Supports selecting a metric by clicking on its card
 */
export function MetricCardsGrid({
  metrics,
  dateRange,
  comparisonDateRange,
  selectedMetric,
  onSelectMetric,
}: MetricCardsGridProps) {
  // Process metrics to ensure each has an ID
  const metricsWithIds = metrics.map((metric) => ({
    ...metric,
    id: metric.id || metric.title.toLowerCase(),
  }));

  // Handle card click
  const handleCardClick = (metricId: string) => {
    if (onSelectMetric) {
      // If clicking the already selected metric, deselect it
      if (selectedMetric === metricId) {
        onSelectMetric("");
      } else {
        onSelectMetric(metricId);
      }
    }
  };

  return (
    <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
      {metricsWithIds.map((metric, index) => {
        const isSelected = selectedMetric === metric.id;
        // Get the selected metric's color for the ring
        const ringColor = isSelected ? getMetricColor(metric.id) : "";

        return (
          <div
            key={index}
            onClick={() => handleCardClick(metric.id)}
            className={`cursor-pointer transition-all duration-200 rounded-md overflow-hidden ${
              isSelected
                ? "ring-2 scale-[1.02] shadow-md"
                : "hover:scale-[1.01] hover:shadow-sm"
            }`}
            style={isSelected ? { boxShadow: `0 0 0 2px ${ringColor}` } : {}}
          >
            <MetricCard
              title={metric.title}
              value={metric.value}
              growth={metric.growth}
              icon={metric.icon}
              isPercentage={metric.isPercentage}
              isPosition={metric.isPosition}
              dateRange={dateRange}
              comparisonDateRange={comparisonDateRange}
            />
          </div>
        );
      })}
    </div>
  );
}
