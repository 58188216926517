import { ContentLayout } from "@/components/Layout";
import { useGetUsersForOrg } from "@/features/users/api/getUsersForOrg";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { InputLabel } from "../../../components/Elements";
import { Skeleton } from "../../../components/Elements/Skeleton";
import { useSubscription } from "../../auth/api/getSubscription";
import { useDocumentCount } from "../../documents/api/getDocumentCount";
import {
  useGetAiArticleUsage,
  useGetEnterpriseWizardInfo,
} from "../../subscription/api/getAiArticleLimit";
import { useGetAiWordsForOrg } from "../../subscription/api/getAiWordsForOrg";
import { useGetSearchQueriesForUser } from "../../subscription/api/getSearchQueriesForUser";
import { getPlanNickname } from "../../subscription/utils/getPlanNickname";
import { getPricingPeriod } from "../../subscription/utils/getPricingPeriod";
import { getSearchQueryCredits } from "../../subscription/utils/getSearchQueryCredits";
import { tiers } from "../../subscription/utils/tiers";
dayjs.extend(utc);

export const Usage = () => {
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription({});
  const { data: documentCountData, isLoading: isLoadingDocumentCount } =
    useDocumentCount({});
  const { data: usersForOrgData, isLoading: isLoadingUsersForOrg } =
    useGetUsersForOrg({});
  const { data: searchQueriesData, isLoading: isLoadingSearchQueries } =
    useGetSearchQueriesForUser();
  const { data: aiWordsData, isLoading: isLoadingAiWords } =
    useGetAiWordsForOrg();
  const { data: aiArticleUsageData, isLoading: isLoadingAiArticleUsage } =
    useGetAiArticleUsage();
  const { data: subscriptionInfoData, isLoading: isLoadingSubscriptionInfo } =
    useSubscription();
  // Enterprise wizard info
  // Check if current plan is enterprise
  const isEnterprise = subscriptionData?.plan?.includes("enterprise") || false;

  // Fetch enterprise wizard info only if on enterprise plan
  const { data: enterpriseWizardInfo, isLoading: isLoadingEnterpriseInfo } =
    useGetEnterpriseWizardInfo({
      enabled: isEnterprise,
    });

  // Calculate usage using the utility functions from getAiArticleLimit
  const addonUsage = aiArticleUsageData?.addon_usage || 0;
  const individualCreditsUsed =
    aiArticleUsageData?.individual_credits_used || 0;
  const totalArticles = aiArticleUsageData?.total_articles || 0;

  const isLoading =
    isLoadingSubscription ||
    isLoadingDocumentCount ||
    isLoadingUsersForOrg ||
    isLoadingSearchQueries ||
    isLoadingAiWords ||
    isLoadingAiArticleUsage ||
    isLoadingSubscriptionInfo;

  const currentMonthDisplay = dayjs().utc().format("MMMM");
  const currentMonthNumber = dayjs().utc().format("YYYY-M");

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const planNickname = getPlanNickname(subscriptionData?.plan || "Free");
  const pricingPeriod =
    subscriptionData?.billing_cycle === "year" ? "yearly" : "monthly";
  const isAddOnActive = subscriptionData?.add_on;

  const subscriptionDetails = {
    plan: planNickname,
    pricingPeriod: getPricingPeriod(subscriptionData?.plan || "Free"),
    planName: subscriptionData?.plan || "Free Plan",
    proAddon: subscriptionData?.add_on ? "Active" : "Inactive",
    users: usersForOrgData?.length || 0,
    isAppsumoActive: subscriptionData?.appsumo || false,
    price:
      tiers.find((t) => t.name === planNickname)?.[pricingPeriod]?.price || 0,
    hasAddOn: !!subscriptionData?.add_on,
    isEnterprise: isEnterprise,
    documents: {
      currentMonth: documentCountData?.cur_month_doc_count || 0,
      limit: "Unlimited",
    },
    searchQueries: {
      currentMonth: searchQueriesData?.current_month?.length || 0,
      limit: getSearchQueryCredits(subscriptionData?.plan || "Free"),
    },
    aiContent: {
      currentMonth: formatNumberWithCommas(
        aiWordsData?.["12_months"][currentMonthNumber] || 0
      ),
      limit: planNickname === "Solo" ? "20,000" : "Unlimited",
    },
    aiArticles: {
      currentMonth: addonUsage || 0,
      limit: 30,
      individualCreditsUsed: individualCreditsUsed || 0,
      totalArticles: totalArticles || 0,
    },
    rankReady: {
      purchased: aiArticleUsageData?.monthly_purchases || 0,
      remaining: Math.max(
        0,
        (aiArticleUsageData?.monthly_purchases || 0) -
          (aiArticleUsageData?.individual_credits_used || 0)
      ),
    },
    enterprise: {
      seats: enterpriseWizardInfo?.total_seats || 5,
      documents: {
        limit: enterpriseWizardInfo?.monthly_document_limit || 100,
        used: enterpriseWizardInfo?.monthly_document_usage || 0,
      },
      billingCycle:
        subscriptionData?.billing_cycle === "year" ? "yearly" : "monthly",
    },
  };

  return (
    <ContentLayout>
      <div className="flex flex-col items-start h-screen pt-5 px-8 w-full space-y-4 overflow-y-scroll">
        <div>
          <h2 className="text-xl dark:text-white font-medium">Usage</h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400 mt-2 min-w-[550px] mb-4">
            View your usage for the current month.
          </p>

          {(subscriptionData?.status === "active" ||
            subscriptionData?.status === "active_canceled") && (
            <section className="space-y-4">
              <div className="flex flex-col items-center space-y-2">
                <h3 className="text-md dark:text-white font-medium pt-4 mt-4 border-t dark:border-t-zinc-800 w-full">
                  Monthly Usage for{" "}
                  {isLoading ? (
                    <Skeleton className="w-24" />
                  ) : (
                    currentMonthDisplay
                  )}
                </h3>
                <p className="text-sm text-zinc-500 dark:text-zinc-400">
                  View your usage for the current month. Usage resets on the
                  first day of the next month.
                </p>
              </div>
              <div>
                <InputLabel label="Documents" />
                {isLoading ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  <p>
                    <span className="font-medium text-sm dark:text-white">
                      {subscriptionDetails.documents.currentMonth}
                    </span>{" "}
                    <span className="text-sm text-zinc-500">
                      / {subscriptionDetails.documents.limit}
                    </span>
                  </p>
                )}
              </div>

              <div>
                <InputLabel label="SEO Documents" />
                {isLoading ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  <p>
                    <span className="font-medium text-sm dark:text-white">
                      {subscriptionDetails.searchQueries.currentMonth}
                    </span>{" "}
                    <span className="text-sm text-zinc-500">
                      / {subscriptionDetails.searchQueries.limit}
                    </span>
                  </p>
                )}
              </div>

              <div>
                <InputLabel label="AI-generated Words" />
                {isLoading ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  <p>
                    <span className="font-medium text-sm dark:text-white">
                      {subscriptionDetails.aiContent.currentMonth}
                    </span>{" "}
                    <span className="text-sm text-zinc-500">
                      / {subscriptionDetails.aiContent.limit}
                    </span>
                  </p>
                )}
              </div>

              {isAddOnActive && (
                <div>
                  <InputLabel label="Rank-Ready AI Add-on Documents" />
                  {isLoading ? (
                    <Skeleton className="h-6 w-full" />
                  ) : (
                    <p>
                      <span className="font-medium text-sm dark:text-white">
                        {subscriptionDetails.aiArticles.currentMonth}
                      </span>{" "}
                      <span className="text-sm text-zinc-500">
                        / {subscriptionDetails.aiArticles.limit}
                      </span>
                    </p>
                  )}
                </div>
              )}
            </section>
          )}
          {/* Enterprise Section */}
          {subscriptionDetails.isEnterprise && (
            <div>
              <h3 className="text-md dark:text-white font-medium pt-4 mt-4 border-t dark:border-t-zinc-800 w-full">
                Enterprise Plan Details
              </h3>
              {isLoading || isLoadingEnterpriseInfo ? (
                <Skeleton className="h-6 w-full" />
              ) : (
                <div className="space-y-4 mt-4">
                  <div>
                    <InputLabel label="Included Seats" />
                    <p>
                      <span className="font-medium text-sm dark:text-white">
                        {subscriptionDetails.enterprise.seats}
                      </span>
                    </p>
                  </div>
                  <div>
                    <InputLabel label="Monthly Rank-Ready AI Document Limit" />
                    <p>
                      <span className="font-medium text-sm dark:text-white">
                        {enterpriseWizardInfo?.monthly_document_usage || 0}
                      </span>{" "}
                      <span className="text-sm text-zinc-500">
                        / {enterpriseWizardInfo?.monthly_document_limit || 100}
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Rank-Ready Section - Show only if not enterprise or if has add-on */}
          {(!subscriptionDetails.isEnterprise ||
            subscriptionDetails.hasAddOn) && (
            <div>
              <h3 className="text-md dark:text-white font-medium pt-4 mt-4 border-t dark:border-t-zinc-800 w-full">
                Rank-Ready AI Document Credits
              </h3>
              {isLoading ? (
                <Skeleton className="h-6 w-full" />
              ) : (
                <div className="space-y-4 mt-4">
                  <div>
                    <InputLabel label="Total Documents Purchased" />
                    <p>
                      <span className="font-medium text-sm dark:text-white">
                        {subscriptionDetails.rankReady.purchased}
                      </span>
                    </p>
                  </div>
                  <div>
                    <InputLabel label="Remaining Documents" />
                    <p>
                      <span className="font-medium text-sm dark:text-white">
                        {subscriptionDetails.rankReady.remaining}
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </ContentLayout>
  );
};
