import { ContentLayout } from "@/components/Layout";
import { useActiveTab } from "@/components/AdvancedEditor/hooks/useActiveTab";
import { DocumentsList } from "../components/DocumentsList";
import { useEffect } from "react";

export const Documents = () => {
  const { setActiveTab } = useActiveTab();

  useEffect(() => {
    setActiveTab('serp');
  }, [setActiveTab]);

  return (
    <ContentLayout>
      <DocumentsList />
    </ContentLayout>
  );
};
