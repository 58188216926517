import { axios } from "@/lib/axios";
import { useMutation } from "react-query";

export type OneTimePurchaseDTO = {
  product_name: string;
  article_id?: string;
};

export type OneTimePurchaseResponse = {
  payment_id: string;
  client_secret: string;
  requires_payment: boolean;
};

export const createOneTimePurchase = (
  data: OneTimePurchaseDTO
): Promise<OneTimePurchaseResponse> => {
  return axios.post("/stripe/createOneTimePurchase", data);
};

export const useCreateOneTimePurchase = () => {
  return useMutation({
    mutationFn: createOneTimePurchase,
  });
};
