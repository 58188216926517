/**
 * Feature Flags System
 *
 * This module provides a typesafe feature flag system that reads configuration from environment variables.
 *
 * Usage:
 * 1. Define new feature flags in the FeatureFlag enum
 * 2. Set environment variables with the format: VITE_FEATURE_FLAG_[FLAG_NAME]=enabled|disabled
 * 3. Import and use in your code:
 *    ```
 *    import { isFeatureEnabled, FeatureFlag } from 'src/utils/featureFlags';
 *
 *    if (isFeatureEnabled(FeatureFlag.OPPORTUNITY_DASHBOARD)) {
 *      // Feature-specific code here
 *    }
 *    ```
 *
 * Environment variables:
 * - Must be prefixed with "VITE_FEATURE_FLAG_"
 * - Must have values of either "enabled" or "disabled" (case insensitive)
 * - Will default to disabled (false) if not set
 * - Will throw an error if set to any other value
 */

// Define the feature flag keys
export enum FeatureFlag {
  /**
   * Feature flag for the Content Opportunity Dashboard feature
   * Environment variable: VITE_FEATURE_FLAG_OPPORTUNITY_DASHBOARD
   */
  OPPORTUNITY_DASHBOARD = "OPPORTUNITY_DASHBOARD",

  // Add new feature flags here following the same pattern
  // Ensure the enum key matches the environment variable suffix
}

// Type for the feature flags configuration object
type FeatureFlagsConfig = {
  [key in FeatureFlag]: boolean;
};

/**
 * Singleton class that manages feature flags
 * Reads configuration from environment variables and provides methods to check flag status
 */
class FeatureFlags {
  private static instance: FeatureFlags;
  private flags: FeatureFlagsConfig;

  // Prefix for all feature flag environment variables
  private readonly ENV_PREFIX = "VITE_FEATURE_FLAG_";

  private constructor() {
    this.flags = this.initializeFlags();
  }

  /**
   * Get the singleton instance of the FeatureFlags class
   * Creates a new instance if one doesn't exist
   *
   * @returns The singleton FeatureFlags instance
   */
  public static getInstance(): FeatureFlags {
    if (!FeatureFlags.instance) {
      FeatureFlags.instance = new FeatureFlags();
    }

    return FeatureFlags.instance;
  }

  /**
   * Initialize feature flags from environment variables
   * Reads all flags defined in the FeatureFlag enum
   *
   * @returns A configuration object with all feature flags and their values
   * @throws Error if an environment variable has an invalid value
   */
  private initializeFlags(): FeatureFlagsConfig {
    const config = {} as FeatureFlagsConfig;

    // Initialize each feature flag
    for (const flag of Object.values(FeatureFlag)) {
      const envVarName = `${this.ENV_PREFIX}${flag}`;
      const rawEnvValue = import.meta.env[envVarName];

      // If not set, default to disabled
      if (rawEnvValue === undefined) {
        config[flag] = false;
        continue;
      }

      // Normalize value to lowercase for case-insensitive check
      const envValue = rawEnvValue.toLowerCase();

      // Validate that the value is either 'enabled' or 'disabled'
      if (envValue !== "enabled" && envValue !== "disabled") {
        throw new Error(
          `Invalid value for feature flag ${envVarName}: ${rawEnvValue}. ` +
            `Allowed values are 'enabled' or 'disabled' (case insensitive).`
        );
      }

      // Set to true if explicitly enabled
      config[flag] = envValue === "enabled";
    }

    return config;
  }

  /**
   * Check if a specific feature flag is enabled
   *
   * @param flag The feature flag to check
   * @returns true if the feature is enabled, false otherwise
   */
  public isEnabled(flag: FeatureFlag): boolean {
    return this.flags[flag];
  }

  /**
   * Get all feature flags with their current values
   * Useful for debugging or displaying feature status
   *
   * @returns A copy of the current feature flags configuration
   */
  public getAllFlags(): FeatureFlagsConfig {
    return { ...this.flags };
  }
}

// Export the singleton instance
export const featureFlags = FeatureFlags.getInstance();

/**
 * Convenience function for checking if a feature is enabled
 * This is the recommended way to check feature flags in the application
 *
 * @param flag The feature flag to check
 * @returns true if the feature is enabled, false otherwise
 *
 * @example
 * ```
 * import { isFeatureEnabled, FeatureFlag } from 'src/utils/featureFlags';
 *
 * if (isFeatureEnabled(FeatureFlag.OPPORTUNITY_DASHBOARD)) {
 *   // Feature-specific code here
 * }
 * ```
 */
export function isFeatureEnabled(flag: FeatureFlag): boolean {
  return featureFlags.isEnabled(flag);
}
