import {
  Button,
  Spinner,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { useSubscription } from "@/features/auth/api/getSubscription";
import ProductTour from "@/features/onboarding/components/ProductTour";
import { InfoGainTabs } from "@/features/serp/components/InfoGainTabs";
import { getPlanNickname } from "@/features/subscription/utils/getPlanNickname";
import { useDocumentStore } from "@/stores/document";
import { useEditorStore } from "@/stores/editor";
import { cn } from "@/utils/style";
import { motion } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import { TbArrowLeft, TbSearch, TbSparkles, TbTarget } from "react-icons/tb";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import {
  useIsAiArticle,
  useIsAiArticleAccepted,
} from "../../features/ai/components/AiArticleLoader";
import { useDomainAuthority } from "../../features/documents/api/getDomainAuthority";
import { useGoogleSearchResults } from "../../features/documents/api/getGoogleSearchResults";
import { useUrlBatch } from "../../features/documents/api/getUrlBatch";
import { useUpdateDocument } from "../../features/documents/api/updateDocument";
import { useSerpStore } from "../../stores/serp";
import { ShortcutTag } from "../Elements/Tag/ShortcutTag";
import { useSidebarNavigationStore } from "../Layout/SidebarToggle";
import { CommentStore } from "./commenting";
import { DocumentSidebarPreview } from "./DocumentSidebarPreview";
import { useActiveTab } from "./hooks/useActiveTab";
import { useHeaderNavigation } from "./hooks/useHeaderNavigation";
import { CommentsPanel } from "./plugins/CommentPlugin/CommentsPanel";
import { TopicScores } from "./plugins/OptimizePlugin/TopicScores";
import { Outline } from "./plugins/OutlinePlugin/Outline";
import { Overview } from "./plugins/ResearchPlugin/Overview";
import SearchQuery from "./plugins/ResearchPlugin/SearchQuery";
import { SerpPlaceholder } from "./plugins/ResearchPlugin/SerpPlaceholder";

const MAX_SIDEBAR_WIDTH = 580;
const DEFAULT_SIDEBAR_WIDTH = 460;
const CLOSE_ACTION_WIDTH = 200;

const validateFirstLoad = (document) => {
  if (
    document.metadata?.hasOwnProperty("ai_article") &&
    document.metadata?.ai_article
  ) {
    return false;
  }

  return document.metadata?.hasOwnProperty("serp_loaded") &&
    document.metadata?.serp_loaded === true
    ? false
    : true;
};

const HeaderNavigation = ({ onBack, title, titleIcon }) => {
  return (
    <div className="flex items-center justify-between w-full px-4 pb-2 py-[2px]">
      <div className="flex items-center">
        <Button
          className="mr-2"
          variant="buttonIcon"
          buttonIcon={<TbArrowLeft />}
          onClick={onBack}
          aria-label="Back"
        />
        {titleIcon && titleIcon}
        <p
          className={cn(
            "font-medium text-xs dark:text-white",
            titleIcon && "ml-2"
          )}
        >
          {title}
        </p>
      </div>
    </div>
  );
};

const SidebarTabs = ({
  documentHash,
  setEditor,
  setIsLoadingSerp,
  width,
  isHeaderNavVisible,
  handleStartProcessingSerp,
}: {
  documentHash: string;
  width: any;
  setIsLoadingSerp: (value: boolean) => void;
  setEditor: any;
  isHeaderNavVisible: boolean;
  handleStartProcessingSerp: () => void;
}) => {
  const { activeTab, setActiveTab } = useActiveTab();
  const { document: fraseDocument } = useDocumentStore();
  const { id: docId } = fraseDocument;

  const { editor: editorStore } = useEditorStore();
  const { serp } = useSerpStore();
  const { activeTabIndex } = editorStore;
  const trackEvent = useTrackEvent();

  const documentLanguage = fraseDocument?.metadata?.lang_code || "en";

  const documentTitle = useMemo(
    () =>
      fraseDocument.text && fraseDocument.text[activeTabIndex]
        ? fraseDocument.text[activeTabIndex].title
        : "",
    [fraseDocument.text, activeTabIndex]
  );

  const outlineHeadings = useMemo(
    () =>
      Object.values(
        JSON.parse(fraseDocument.metadata?.outline || "{}") || "{}"
      ) || [],
    [fraseDocument.metadata]
  );

  const currentSerp = useMemo(
    () => serp[docId] || { topics: [] },
    [serp, docId]
  );

  useEffect(() => {
    trackEvent.mutate({
      event: "serp_panel_view",
      properties: JSON.stringify({
        view: activeTab,
      }),
    });
  }, [activeTab]);

  return (
    <div className="flex w-full items-center pt-1">
      <Tabs
        defaultValue="serp"
        className="w-full"
        value={activeTab}
        onValueChange={(value) => {
          setActiveTab(value);
          if (value === "optimize") {
            setEditor((editorState) => {
              editorState.optimizeViewVisible = true;
              editorState.highlightTopics = true;
            });
          } else {
            setEditor((editorState) => {
              editorState.optimizeViewVisible = false;
              editorState.highlightTopics = false;
            });
          }
        }}
      >
        {!isHeaderNavVisible && (
          <div className="px-4">
            <TabsList className="w-full mb-[8px]">
              <TabsTrigger value="serp">SERP</TabsTrigger>
              <TabsTrigger value="outline">Outline</TabsTrigger>
              <TabsTrigger
                value="optimize"
                id="optimize-tab"
                data-testid="optimize-tab"
              >
                Optimize
              </TabsTrigger>
              <TabsTrigger value="infoGain">Info Gain</TabsTrigger>
            </TabsList>
          </div>
        )}
        <TabsContent value="serp" activeTab={activeTab}>
          <Overview
            setIsLoadingSerp={setIsLoadingSerp}
            handleStartProcessingSerp={handleStartProcessingSerp}
          />
        </TabsContent>
        <TabsContent value="outline" activeTab={activeTab}>
          <Outline
            panelWidth={width}
            headings={outlineHeadings}
            onSelectedAssetsChange={() => {}}
            title={documentTitle}
            topics={currentSerp.topics}
            urls={currentSerp.urls}
            selectedLanguage={documentLanguage}
          />
        </TabsContent>
        <TabsContent
          value="optimize"
          isHeaderVisible={isHeaderNavVisible}
          activeTab={activeTab}
        >
          <TopicScores panelWidth={width} />
        </TabsContent>
        <TabsContent
          value="infoGain"
          activeTab={activeTab}
          className="overflow-hidden"
        >
          <InfoGainTabs isHeaderNavVisible={isHeaderNavVisible} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

// Helper function to check if a document appears to be from AI article workflow
const isFromAIArticleWorkflow = (document) => {
  return (
    document.text &&
    document.text.length > 1 &&
    document.text[document.text.length - 1].name === "AI Document"
  );
};

// Helper function to check if we're on the AI Document tab specifically
const isActiveTabAIDocument = (document, activeTabIndex) => {
  return (
    document.text &&
    document.text.length > activeTabIndex &&
    document.text[activeTabIndex].name === "AI Document"
  );
};

export const SidebarPlugins = () => {
  const { document: fraseDocument } = useDocumentStore();
  const { data: subscriptionData } = useSubscription({});
  const isSubscriptionCancelled = subscriptionData?.status === "canceled";

  if (isSubscriptionCancelled) {
    return null;
  }

  const [width, setWidth] = useState(DEFAULT_SIDEBAR_WIDTH);
  const [isResizing, setIsResizing] = useState(false);
  const [hasMoved, setHasMoved] = useState(false);
  const { isSidebarOpen, closeSidebar, openSidebar } =
    useSidebarNavigationStore();
  const { setDocument } = useDocumentStore();
  const { activeTab, setActiveTab } = useActiveTab();

  const location = useLocation();
  const [isOnboardingDocument, setIsOnboardingDocument] = useState(
    location.state?.onboardingDocument && !location.state?.onboardingAiTool
  );
  const isOnboardingAiTool = location.state?.onboardingAiTool;
  const documentHash = location.pathname.split("/")[3];
  const { setEditor } = useEditorStore();
  const { isVisible, title, onBack, titleIcon } = useHeaderNavigation();
  const [isLoadingSerp, setIsLoadingSerp] = useState(false);
  const [isLoadingSerpError, setIsLoadingSerpError] = useState(false);
  // Refs to prevent duplicate SERP processing and track completion
  const serpProcessingTriggeredRef = useRef(false);
  const serpProcessingCompleteRef = useRef(false);
  const isFirstSerpLoad = validateFirstLoad(fraseDocument);
  const queryClient = useQueryClient();
  const isAiArticle = useIsAiArticle();
  const isAiArticleAccepted = useIsAiArticleAccepted();
  const [searchQuery, setSearchQuery] = useState(fraseDocument.query || "");
  const [isSerpProcessed, setIsSerpProcessed] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);
  const [runProductTour, setRunProductTour] = useState(false);
  const trackEvent = useTrackEvent();
  const { editor, toggleSplitView } = useEditorStore();
  const { isSplitViewVisible, isCommentsPanelVisible } = editor;

  // Create refs here at the component level
  const hasInitialTabBeenSet = useRef(false);
  const hasSetOptimizeTabForAiArticle = useRef(false);
  const hasSetTabAfterSerpProcessing = useRef(false);

  // Use memo to create stable reference for document props
  // This helps prevent unnecessary re-renders
  const documentInfo = useMemo(
    () => ({
      id: fraseDocument.id,
      query: fraseDocument.query,
      hash: fraseDocument.hash,
    }),
    [fraseDocument.id, fraseDocument.query, fraseDocument.hash]
  );

  const updateDocumentMutation = useUpdateDocument({ notifyOnSuccess: false });
  const { serp, setSerp } = useSerpStore();
  const { urls, results, articles } = serp[fraseDocument.id] || {
    urls: [],
    results: [],
    articles: [],
  };
  const blacklist = fraseDocument.metadata?.blacklist || {};
  const planNickname = getPlanNickname(subscriptionData?.plan || "Free");

  const validArticles = results && results.length > 0 ? results : [];

  const handleResize = (e) => {
    const windowWidth = window.innerWidth;
    const newWidth = windowWidth - e.clientX;
    setIsResizing(true);
    setHasMoved(true);

    if (newWidth >= DEFAULT_SIDEBAR_WIDTH && newWidth <= MAX_SIDEBAR_WIDTH) {
      setWidth(newWidth);
    }

    if (newWidth > MAX_SIDEBAR_WIDTH) {
      setWidth(MAX_SIDEBAR_WIDTH);
    }

    if (newWidth < DEFAULT_SIDEBAR_WIDTH && newWidth > CLOSE_ACTION_WIDTH) {
      setWidth(DEFAULT_SIDEBAR_WIDTH);
    }

    if (newWidth < DEFAULT_SIDEBAR_WIDTH && newWidth <= CLOSE_ACTION_WIDTH) {
      setWidth(DEFAULT_SIDEBAR_WIDTH);
      handleResizeEnd();
      closeSidebar();
    }
  };

  const handleResizeEnd = () => {
    document.removeEventListener("mousemove", handleResize);
    document.removeEventListener("mouseup", handleResizeEnd);
    setTimeout(() => {
      setIsResizing(false);
      setHasMoved(false);
    }, 100);
  };

  const handleResizeStart = () => {
    document.addEventListener("mousemove", handleResize);
    document.addEventListener("mouseup", handleResizeEnd);
  };

  const googleSearchResultsQuery = useGoogleSearchResults({
    searchQuery,
    document: fraseDocument,
    config: {
      enabled: false, // Disable auto-fetching
    },
  });
  const blacklistUrls = fraseDocument.metadata?.blacklist || {};

  const urlBatchQuery = useUrlBatch({
    urls: urls?.filter((url) => !Object.keys(blacklistUrls).includes(url)),
    document: fraseDocument,
    config: {
      enabled: false, // Disable auto-fetching
    },
  });

  // Move to next step when SERP is processed
  useEffect(() => {
    if (
      !isLoadingSerpError &&
      !isLoadingSerp &&
      results &&
      results.length > 0 &&
      articles &&
      articles.length > 0
    ) {
      setIsSerpProcessed(true);
    } else {
      setIsSerpProcessed(false);
    }
  }, [isLoadingSerpError, isLoadingSerp, results, articles]);

  const writeWithAiRef = useRef(null);

  useEffect(() => {
    writeWithAiRef.current = document.getElementById("write-with-ai");

    const observer = new MutationObserver((mutations) => {
      const writeWithAi = document.getElementById("write-with-ai");
      if (stepIndex === 3 && !writeWithAi) {
        setRunProductTour(false);
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
    });

    return () => observer.disconnect();
  }, [stepIndex]);

  useEffect(() => {
    const aiCommandLoadingText = document.getElementById(
      "ai-command-input-loading-text"
    );
    const isLoading =
      aiCommandLoadingText?.innerHTML === "Thinking..." ||
      "Writing using web results..." ||
      "Writing using editor context...";
    if (stepIndex === 4 && isLoading) {
      setRunProductTour(false);
    }
  }, [document.getElementById("ai-command-input-loading-text")]);

  useEffect(() => {
    if (isLoadingSerp && !isLoadingSerpError && !isSerpProcessed) {
      setStepIndex(1);
    } else {
      setStepIndex(0);
    }
  }, [isLoadingSerp, isLoadingSerpError, isSerpProcessed]);

  useEffect(() => {
    if (isSerpProcessed) {
      setStepIndex(2);
    }
  }, [isSerpProcessed]);

  // Add a dedicated effect to ensure correct tab is selected when SERP processing completes
  useEffect(() => {
    // Check if user is coming from WizardSuccessMessage
    const isFromWizardSuccess = location.state?.fromWizardSuccess === true;

    // Only run when SERP processing has completed and the loading state changes
    // AND only if we haven't already set the tab for this processing cycle
    if (
      serpProcessingCompleteRef.current &&
      !isLoadingSerp &&
      !hasSetTabAfterSerpProcessing.current
    ) {
      // Mark that we've handled this SERP processing cycle
      hasSetTabAfterSerpProcessing.current = true;

      // Check if we're currently on an AI Document tab
      const { activeTabIndex } = editor;
      const isAIDocumentTab = isActiveTabAIDocument(
        fraseDocument,
        activeTabIndex
      );

      // ONLY set Optimize tab when BOTH:
      // 1. We're on an AI Document tab, AND
      // 2. We're coming from WizardSuccessMessage (clicked "Review & Optimize")
      if (isAIDocumentTab && isFromWizardSuccess) {
        // For AI Document tabs coming from Wizard Success, select the Optimize tab
        setActiveTab("optimize");

        // Clear location state
        history.replaceState(null, "", location.pathname);

        // Also ensure the UI state reflects this change
        setEditor((editorState) => {
          editorState.optimizeViewVisible = true;
          editorState.highlightTopics = true;
        });

        // Use a DOM click as a fallback to ensure tab selection
        setTimeout(() => {
          const optimizeTab = document.getElementById("optimize-tab");
          if (optimizeTab) {
            optimizeTab.click();
          }
        }, 50);
      }
      // Reset flag to prevent repeated actions
      serpProcessingCompleteRef.current = false;
    } else if (serpProcessingCompleteRef.current && !isLoadingSerp) {
      // If conditions not met, just reset the flag without tab switching
      serpProcessingCompleteRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // Only depend on loading state and fromWizardSuccess
    isLoadingSerp,
    location.state?.fromWizardSuccess,
    // We still need to know if this is an AI workflow document
    // But explicitly exclude the full document to prevent refreshes on outline changes
    documentInfo.id,
    // Include editor.activeTabIndex to ensure we check the right tab
    editor.activeTabIndex,
  ]);

  const domainAuthorityQuery = useDomainAuthority({
    urls: urls?.filter((url) => !Object.keys(blacklistUrls).includes(url)),
    articles,
    document: fraseDocument,
    config: {
      enabled: false, // Disable auto-fetching
    },
  });

  const handleUpdateDocumentFirstSerpLoad = async () => {
    const updatedDocument = {
      ...fraseDocument,
      query: searchQuery,
      metadata: { ...fraseDocument.metadata, serp_loaded: true },
    };
    await updateDocumentMutation.mutate(updatedDocument);
  };

  const handleStartProcessingSerp = async () => {
    // Check if we're already loading - prevent duplicate processing
    if (isLoadingSerp) {
      return;
    }

    setIsLoadingSerp(true);
    setIsSerpProcessed(false);

    // Reset tab selection flag each time we start processing
    hasSetTabAfterSerpProcessing.current = false;

    if (!searchQuery) {
      setIsLoadingSerp(false);
      serpProcessingTriggeredRef.current = false;
      return;
    }

    if (isFirstSerpLoad === true) {
      handleUpdateDocumentFirstSerpLoad().catch(() => {
        setIsLoadingSerp(false);
        setIsLoadingSerpError(true);
      });
    }

    googleSearchResultsQuery
      .refetch()
      .then((resp) => {
        if (resp.status === "error") {
          setIsLoadingSerp(false);
          setIsLoadingSerpError(true);
          setIsSerpProcessed(false);
          serpProcessingTriggeredRef.current = false;
          return;
        }

        urlBatchQuery
          .refetch()
          .then(() => {
            domainAuthorityQuery
              .refetch()
              .then(() => {
                // Mark SERP as processed and complete
                setIsSerpProcessed(true);
                serpProcessingTriggeredRef.current = false;

                // Finally clear loading states
                setIsLoadingSerp(false);
                setIsLoadingSerpError(false);

                // For any SERP processing completion, always show SERP tab UNLESS:
                // 1. We're on an AI Document tab, AND
                // 2. We have fromWizardSuccess flag set
                const isFromWizardSuccess =
                  location.state?.fromWizardSuccess === true;
                const { activeTabIndex } = editor;
                const isAIDocumentTab = isActiveTabAIDocument(
                  fraseDocument,
                  activeTabIndex
                );

                // If not an AI Document tab OR not coming from WizardSuccess, show SERP tab
                if (!isAIDocumentTab || !isFromWizardSuccess) {
                  setActiveTab("serp");
                  setEditor((editorState) => {
                    editorState.optimizeViewVisible = false;
                    editorState.highlightTopics = false;
                  });

                  // Use a DOM click as a fallback to ensure tab selection
                  setTimeout(() => {
                    const serpTab = document.querySelector('[value="serp"]');
                    if (serpTab) {
                      serpTab.click();
                    }
                  }, 50);
                }

                // Set the completion flag - this will trigger our dedicated effect AFTER we've set the tab
                serpProcessingCompleteRef.current = true;
              })
              .catch(() => {
                setIsLoadingSerp(false);
                setIsLoadingSerpError(true);
                setIsSerpProcessed(false);
                serpProcessingTriggeredRef.current = false;
              });
          })
          .catch(() => {
            setIsLoadingSerp(false);
            setIsLoadingSerpError(true);
            setIsSerpProcessed(false);
          });
      })
      .catch(() => {
        setIsLoadingSerp(false);
        setIsLoadingSerpError(true);
        setIsSerpProcessed(false);
        serpProcessingTriggeredRef.current = false;
      });
  };

  // Reset SERP data when document ID, query, or hash changes
  // This ensures we don't reset SERP data when only the outline or other metadata changes
  useEffect(() => {
    // Reset all processing and tab selection flags when document changes
    // This is critical for allowing a new processing cycle and proper tab selection
    serpProcessingTriggeredRef.current = false;
    hasSetTabAfterSerpProcessing.current = false;
    hasSetOptimizeTabForAiArticle.current = false;
    hasInitialTabBeenSet.current = false;

    // Check if we have a valid query
    const hasValidQuery = fraseDocument.query && fraseDocument.query.length > 0;
    // Check if document has already loaded SERP
    const hasSerpLoaded = fraseDocument.metadata?.serp_loaded === true;
    // Check if coming from AI article workflow
    const isFromAI = isFromAIArticleWorkflow(fraseDocument);

    // We should preserve queries in these cases:
    // 1. Document has a valid query AND is from AI workflow, OR
    // 2. Document has a valid query AND has SERP already loaded
    const shouldPreserveQueries = hasValidQuery && (isFromAI || hasSerpLoaded);

    if (shouldPreserveQueries) {
      // Preserve queries for AI workflow or documents with SERP loaded
    } else {
      // Only cancel queries and reset SERP if we don't have a valid query
      // or if it's a new document without SERP loaded
      // Cancel queries for documents without valid query or SERP loaded

      queryClient.cancelQueries(["googleSearchResults"], { exact: false });
      queryClient.cancelQueries(["processUrlBatch"], { exact: false });
      queryClient.invalidateQueries(["googleSearchResults"], { exact: false });
      queryClient.invalidateQueries(["processUrlBatch"], { exact: false });

      if (!isAiArticle) {
        setSerp(fraseDocument.id, {
          ...serp[fraseDocument.id],
          urls: [],
          results: [],
          articles: [],
        });
      }
    }

    // Always update the search query based on document query
    if (documentInfo.query) {
      setSearchQuery(documentInfo.query);
    } else {
      setSearchQuery("");
    }

    // Also set default tab to SERP for new documents unless they're AI articles with fromWizardSuccess
    const isFromWizardSuccess = location.state?.fromWizardSuccess === true;
    if (!(isFromAI && isFromWizardSuccess)) {
      setActiveTab("serp");
      setEditor((editorState) => {
        editorState.optimizeViewVisible = false;
        editorState.highlightTopics = false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // Only depend on document identity and SERP-related properties
    documentInfo.id,
    documentInfo.hash,
    documentInfo.query,
    location.state?.fromWizardSuccess,
  ]);

  // Start processing SERP if not first time loading SERP or if coming from AI article
  useEffect(() => {
    // Only run this once per search query + document combination
    const processingKey = `${documentInfo.id}:${searchQuery}`;

    // Check if user is coming from WizardSuccessMessage
    const isFromWizardSuccess = location.state?.fromWizardSuccess === true;

    // Check if current tab is AI Document tab
    const { activeTabIndex } = editor;
    const isAIDocumentTab = isActiveTabAIDocument(
      fraseDocument,
      activeTabIndex
    );

    // If coming from WizardSuccessMessage and we're on an AI Document tab, set active tab to optimize
    if (
      isAIDocumentTab &&
      isFromWizardSuccess &&
      !hasSetOptimizeTabForAiArticle.current
    ) {
      hasSetOptimizeTabForAiArticle.current = true;

      setActiveTab("optimize");

      // Clear location state
      history.replaceState(null, "", location.pathname);

      setEditor((editorState) => {
        editorState.optimizeViewVisible = true;
        editorState.highlightTopics = true;
      });

      // Also try to click the tab programmatically
      setTimeout(() => {
        const optimizeTab = document.getElementById("optimize-tab");
        if (optimizeTab) {
          optimizeTab.click();
        }
      }, 100);
    } else if (!isAIDocumentTab && !hasSetOptimizeTabForAiArticle.current) {
      // For any non-AI-Document tab, ensure we're in SERP tab
      hasSetOptimizeTabForAiArticle.current = true;

      setActiveTab("serp");
      setEditor((editorState) => {
        editorState.optimizeViewVisible = false;
        editorState.highlightTopics = false;
      });
    }

    // Check if document has SERP loaded
    const hasSerpLoaded = fraseDocument.metadata?.serp_loaded === true;

    // Only process if we haven't already triggered processing for this document hash
    // This prevents multiple processing triggers during the same session
    // We should process SERP if:
    // 1. Not already processing AND
    // 2. (Not first SERP load OR from AI workflow OR document has SERP loaded) AND
    // 3. Search query matches document query and isn't empty
    if (
      !serpProcessingTriggeredRef.current &&
      (isFirstSerpLoad === false ||
        isFromAIArticleWorkflow(fraseDocument) ||
        hasSerpLoaded) &&
      searchQuery === documentInfo.query &&
      searchQuery !== ""
    ) {
      // Start SERP processing

      // Set the ref to prevent duplicate processing
      serpProcessingTriggeredRef.current = true;
      handleStartProcessingSerp();
    }

    // Clean up function
    return () => {
      // Nothing to clean up
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // Only depend on properties related to SERP processing
    searchQuery,
    documentInfo.id,
    documentInfo.query,
    isFirstSerpLoad,
    // Don't include the entire fraseDocument, which would cause refreshes on outline updates
    location.state?.fromWizardSuccess,
  ]);

  // Set initial tab based on document type
  useEffect(() => {
    // Only set the initial tab when the document first loads
    if (documentHash && !hasInitialTabBeenSet.current) {
      hasInitialTabBeenSet.current = true;

      // Check if user is coming from WizardSuccessMessage
      const isFromWizardSuccess = location.state?.fromWizardSuccess === true;

      // Reset tab selection flags when document changes
      hasSetTabAfterSerpProcessing.current = false;

      // Check if we're currently on an AI Document tab
      const { activeTabIndex } = editor;
      const isAIDocumentTab = isActiveTabAIDocument(
        fraseDocument,
        activeTabIndex
      );

      // Special case: Only set optimize tab if both conditions are true:
      // 1. We're on an AI Document tab
      // 2. User is coming directly from WizardSuccessMessage (clicked "Review & Optimize")
      if (isAIDocumentTab && isFromWizardSuccess) {
        setActiveTab("optimize");

        // Clear location state
        history.replaceState(null, "", location.pathname);

        // Also update editor state
        setEditor((editorState) => {
          editorState.optimizeViewVisible = true;
          editorState.highlightTopics = true;
        });
      } else {
        // For all other cases, ensure we're showing SERP tab
        setActiveTab("serp");

        // Update editor state accordingly
        setEditor((editorState) => {
          editorState.optimizeViewVisible = false;
          editorState.highlightTopics = false;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // Only depend on the document hash (identifier) and the location state
    // This ensures the effect only runs when the document changes or on initial load
    documentHash,
    location.state?.fromWizardSuccess,
    // Include editor.activeTabIndex to ensure we check the right tab
    editor.activeTabIndex,
  ]);

  // Close sidebar if AI article is loading
  useEffect(() => {
    if (isAiArticle && !isAiArticleAccepted) {
      closeSidebar();
    } else {
      openSidebar();
      //setActiveTab("optimize");
    }
  }, [isAiArticleAccepted, isAiArticle]);

  useEffect(() => {
    if (stepIndex === 2 && activeTab === "optimize") {
      setStepIndex(3);
    }
  }, [activeTab]);

  // Cancel queries when sidebar is closed
  useEffect(() => {
    if (!isSidebarOpen) {
      queryClient.cancelQueries(["googleSearchResults"], { exact: false });
      queryClient.cancelQueries(["processUrlBatch"], { exact: false });
      queryClient.invalidateQueries(["googleSearchResults"], { exact: false });
      queryClient.invalidateQueries(["processUrlBatch"], { exact: false });
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    if (isLoadingSerp && isOnboardingAiTool) {
      setIsOnboardingDocument(true);
      setStepIndex(1);
    }
  }, [isLoadingSerp, isOnboardingAiTool]);

  useEffect(() => {
    if (isOnboardingDocument) {
      setRunProductTour(true);
    }
  }, [isOnboardingDocument]);

  const commentStore = useMemo(() => {
    if (!editor.instance) return null;
    return new CommentStore(editor.instance, documentHash);
  }, [editor.instance, documentHash]);

  const sidebarVariants = {
    open: {
      x: 0,
      zIndex: 0,
      width: width - 1,
      opacity: 1,
      transition: {
        type: "easeOut",
        duration: 0.2,
        delay: 0,
      },
    },
    closed: {
      x: "%100",
      width: 0,
      opacity: 1,
      transition: {
        type: "easeOut",
        duration: 0.2,
        delay: 0,
      },
      transitionEnd: {
        display: "none",
      },
    },
    isResizing: {
      x: 0,
      width: width - 1,
      opacity: 1,
      zIndex: 50,
      transition: {
        type: "easeOut",
        duration: 0,
        delay: 0,
      },
    },
  };

  return (
    <motion.div
      className="flex h-screen print:hidden"
      initial="open"
      animate={() => {
        if (isResizing) {
          return "isResizing";
        }

        if (isSidebarOpen) {
          return "open";
        }

        return "closed";
      }}
      variants={sidebarVariants}
      style={{ width: width - 1 }}
    >
      {isOnboardingDocument && (
        <ProductTour
          run={runProductTour}
          setRun={setRunProductTour}
          onClose={() => {
            setRunProductTour(false);
            setIsOnboardingDocument(false);
          }}
          steps={[
            {
              segmentAction: "click_start_serp",
              target: "#serp-panel",
              titleIcon: (
                <TbSearch
                  className="text-white w-4 h-4 shrink-0 mr-2"
                  style={{
                    fill: "#059669",
                  }}
                />
              ),
              title: "Let's process your search results!",
              content:
                "Frase will analyze the top Google search result pages (SERP) for your query to assist with research and content creation. <br /><br /> Please enter a search query and click <strong>Start</strong> to begin.",
              placement: "left",
              disableBeacon: true,
              disableOverlayClose: true,
              disableCloseOnEsc: true,
              spotlightClicks: true,
              shouldShowNextButton: false,
              shouldShowCloseButton: false,
              nextButtonDisabled: !isSerpProcessed,
              spotlightPadding: 0,
              styles: {
                options: {
                  zIndex: 10,
                },
              },
            },
            {
              segmentAction: "wait_serp_processing",
              target: "#serp-panel",
              titleIcon: (
                <Spinner className="text-white w-4 h-4 shrink-0 mr-2" />
              ),
              title: "Processing...",
              content:
                "Frase is processing your search results, this process may take 20 seconds. After this analysis, Frase will provide you with an overview of key headings, topics, and more insights to help you research and optimize your content.",
              placement: "left-start",
              disableBeacon: true,
              disableOverlayClose: true,
              disableCloseOnEsc: true,
              spotlightClicks: true,
              shouldShowNextButton: false,
              shouldShowCloseButton: false,
              nextButtonDisabled: !isSerpProcessed,
              spotlightPadding: 0,
              offset: -4,
              customStyles: {
                marginTop: "30%",
              },
            },
            {
              segmentAction: "click_optimize_tab",
              target: "#optimize-tab",
              titleIcon: (
                <TbTarget className="text-white w-4 h-4 shrink-0 mr-2" />
              ),
              title: "Optimize content with Frase's Topic Score",
              content:
                "Frase provides a list of key topics mentioned by your competitors. Including these topics in your article helps you create authoritative content Google will love.",
              placement: "bottom-end",
              disableBeacon: false,
              disableOverlayClose: true,
              disableCloseOnEsc: true,
              spotlightClicks: true,
              shouldShowNextButton: true,
              disableOverlay: false,
              nextButtonText: "Next",
              shouldShowCloseButton: true,
              nextButtonDisabled: !isSerpProcessed,
              spotlightPadding: 0,
              styles: {
                overlay: {
                  zIndex: -10,
                },
              },
              floaterProps: {
                style: {
                  zIndex: 50,
                },
              },
            },
            {
              segmentAction: "click_write_with_ai",
              target: "#write-with-ai",
              titleIcon: (
                <TbSparkles className="text-white fill-white w-4 h-4 shrink-0 mr-2" />
              ),
              title: "Write with AI assistance",
              content:
                "Generate content ideas and write articles with AI assistance. Click <strong>Start writing with AI...</strong> to input your first prompt.",
              placement: "right",
              disableBeacon: true,
              disableOverlayClose: true,
              disableCloseOnEsc: true,
              spotlightClicks: true,
              disableOverlay: false,
              nextButtonText: "Got it!",
              shouldShowNextButton: true,
              shouldShowCloseButton: true,
              spotlightPadding: 0,
              styles: {
                overlay: {
                  zIndex: -10,
                },
              },
            },
          ]}
          handleTourEnd={() => {
            setRunProductTour(false);
            setIsOnboardingDocument(false);
          }}
          stepIndex={stepIndex}
          onNext={() => {
            if (stepIndex === 2) {
              // click on the optimize tab
              setActiveTab("optimize");
              setEditor((editorState) => {
                editorState.optimizeViewVisible = true;
                editorState.highlightTopics = true;
              });
              setStepIndex(3);
            } else if (stepIndex === 3) {
              // click on the AI writer button
              const aiWriterButton = document.getElementById("write-with-ai");
              aiWriterButton?.click();
              setRunProductTour(false);
            } else {
              setStepIndex(stepIndex + 1);
            }
          }}
        />
      )}
      <div id="panels" className="flex flex-col h-screen print:hidden">
        <div
          style={{
            width: width - 1,
          }}
          className="h-[calc(100vh)] relative"
          id="serp-panel"
        >
          {isSplitViewVisible ? (
            <DocumentSidebarPreview document={fraseDocument} />
          ) : isCommentsPanelVisible ? (
            commentStore && (
              <CommentsPanel
                documentHash={documentHash}
                onClose={() => {
                  setEditor((state) => {
                    state.isCommentsPanelVisible = false;
                  });
                }}
                commentStore={commentStore}
              />
            )
          ) : (
            <>
              {isLoadingSerpError === false &&
              isLoadingSerp === false &&
              results &&
              results.length > 0 ? (
                <div className="w-full flex-col items-center justify-between">
                  <div className="pt-1.5">
                    <SearchQuery
                      containerClassName="mx-4"
                      document={fraseDocument}
                      setDocument={setDocument}
                      setProcessResults={handleStartProcessingSerp}
                      setSearchQuery={setSearchQuery}
                      searchQuery={searchQuery}
                      isReadOnly={true}
                    />
                  </div>
                  {isVisible && (
                    <HeaderNavigation
                      onBack={onBack}
                      title={title}
                      titleIcon={titleIcon}
                    />
                  )}
                  <SidebarTabs
                    documentHash={documentHash}
                    setEditor={setEditor}
                    setProcessResults={handleStartProcessingSerp}
                    setIsLoadingSerp={setIsLoadingSerp}
                    handleStartProcessingSerp={handleStartProcessingSerp}
                    width={width}
                    isHeaderNavVisible={isVisible}
                  />
                </div>
              ) : (
                <SerpPlaceholder
                  isReadOnly={false}
                  settingsVisible={!isOnboardingDocument}
                  isLoadingSerp={isLoadingSerp}
                  handleStartProcessingSerp={handleStartProcessingSerp}
                  fraseDocument={fraseDocument}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  results={validArticles}
                />
              )}
            </>
          )}
        </div>
      </div>

      {isSidebarOpen && (
        <TooltipProvider delayDuration={500} skipDelayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div
                className={cn(
                  "print:hidden absolute h-screen top-0 bottom-0 after:absolute after:h-screen after:top-0 after:bottom-0",
                  "w-[8px] after:w-[1px] hover:after:w-[2px] active:after:w-[2px]",
                  "after:mr-[4px] hover:after:mr-[3px] active:after:mr-[3px]",
                  "select-none cursor-col-resize after:cursor-col-resize after:select-none",
                  "after:bg-zinc-400 dark:after:bg-zinc-600 hover:after:bg-zinc-400 dark:hover:after:bg-zinc-500 active:after:bg-zinc-400 dark:active:after:bg-zinc-500",
                  "after:opacity-30 hover:after:opacity-100 active:after:opaicty-100",
                  "hover:after:transition hover:after:duration-200 hover:after:delay-400 hover:after:ease-in active:after:transition active:after:duration-200 active:after:delay-400 active:after:ease-in"
                )}
                style={{
                  right: width - 8,
                  zIndex: 50,
                }}
                onMouseDown={handleResizeStart}
                onMouseUp={handleResizeEnd}
                onClick={() => {
                  if (!isResizing && !hasMoved) {
                    closeSidebar();
                  }
                }}
              />
            </TooltipTrigger>
            <TooltipContent side={"left"}>
              <div className="flex flex-col p-1">
                <span>
                  <strong>Drag</strong> to resize
                </span>
                <span>
                  <strong>Click</strong> to collapse
                  <ShortcutTag className="ml-1">]</ShortcutTag>
                </span>
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </motion.div>
  );
};
