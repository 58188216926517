import { Button } from "@/components/Elements";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { BarChart3, FileText, Search, TrendingUp, X } from "lucide-react";
import { useGetQueryDashboard } from "../api";
import { useContentOpportunitiesStore } from "../store";
import { ContentOpportunityPageQuery, ContentOpportunityQuery } from "../types";
import MetricCard from "./MetricCard";
import { OpportunityScoreWithTooltip } from "./OpportunityScoreWithTooltip";
import StatusBadge from "./StatusBadge";

interface QuerySidebarContentProps {
  queryOpportunity: ContentOpportunityQuery;
  onClose?: () => void;
}

// Component to display a single page item
const PageItem = ({ page }: { page: ContentOpportunityPageQuery }) => {
  return (
    <div className="p-2 border border-zinc-200 dark:border-zinc-800 rounded-md mb-1.5 hover:bg-zinc-50 dark:hover:bg-zinc-800/50 transition-colors">
      <div className="flex items-center justify-between mb-0.5">
        <span className="font-medium text-xs truncate max-w-[75%]">
          {page.page_url}
        </span>
        <div className="mt-0.5 flex justify-end">
          <OpportunityScoreWithTooltip score={page.opportunity_score} />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-1 text-2xs text-muted-foreground">
        <div>Pos: {page.position.toFixed(1)}</div>
        <div>Clk: {page.clicks}</div>
        <div>CTR: {(page.ctr * 100).toFixed(1)}%</div>
        <div>Imp: {page.impressions}</div>
      </div>
    </div>
  );
};

export default function QuerySidebarContent({
  queryOpportunity,
  onClose,
}: QuerySidebarContentProps) {
  const { siteUrl } = useContentOpportunitiesStore();
  const { data, isLoading } = useGetQueryDashboard({
    site_url: siteUrl || "",
    query: queryOpportunity.query,
    config: {
      enabled: !!siteUrl && !!queryOpportunity.query,
    },
  });

  return (
    <div className="space-y-3 p-2.5">
      <div className="flex justify-between items-start mb-2 sticky top-0 bg-white dark:bg-zinc-900 z-10 pb-1">
        <div className="space-y-0.5">
          <div className="flex items-center space-x-2">
            <StatusBadge status={queryOpportunity.status} />
          </div>
          <p className="text-xs text-muted-foreground truncate max-w-[230px]">
            <strong>"{queryOpportunity.query}"</strong>
          </p>
        </div>
        {onClose && (
          <Button
            variant="text"
            size="sm"
            onClick={onClose}
            className="flex-shrink-0 -mt-1"
            aria-label="Close sidebar"
          >
            <X className="h-4 w-4" />
          </Button>
        )}
      </div>

      {/* Compact metrics grid */}
      <div className="grid gap-2 grid-cols-2">
        <MetricCard
          title="Clicks"
          value={queryOpportunity.clicks}
          growth={queryOpportunity.clicks_growth}
          icon={<BarChart3 className="h-3.5 w-3.5" />}
          isPercentage={false}
          dateRange={queryOpportunity.date_range}
          comparisonDateRange={queryOpportunity.comparison_date_range}
          mini={true}
        />
        <MetricCard
          title="Impressions"
          value={queryOpportunity.impressions}
          growth={queryOpportunity.impressions_growth}
          icon={<FileText className="h-3.5 w-3.5" />}
          isPercentage={false}
          dateRange={queryOpportunity.date_range}
          comparisonDateRange={queryOpportunity.comparison_date_range}
          mini={true}
        />
        <MetricCard
          title="CTR"
          value={queryOpportunity.ctr}
          growth={queryOpportunity.ctr_growth}
          icon={<TrendingUp className="h-3.5 w-3.5" />}
          isPercentage={true}
          dateRange={queryOpportunity.date_range}
          comparisonDateRange={queryOpportunity.comparison_date_range}
          mini={true}
        />
        <MetricCard
          title="Position"
          value={queryOpportunity.position}
          growth={queryOpportunity.position_diff}
          icon={<Search className="h-3.5 w-3.5" />}
          isPosition={true}
          dateRange={queryOpportunity.date_range}
          comparisonDateRange={queryOpportunity.comparison_date_range}
          mini={true}
        />
      </div>

      {/* Page Opportunities Section */}
      {!isLoading && data && (
        <div className="mt-2">
          <h3 className="text-xs font-medium mb-1.5">Ranked Pages</h3>
          <div className="space-y-1.5 overflow-y-auto pr-1">
            {data.pageOpportunities.length > 0 ? (
              data.pageOpportunities.map((page) => (
                <PageItem key={page.page_url} page={page} />
              ))
            ) : (
              <p className="text-xs text-muted-foreground text-center py-2">
                No page opportunities found.
              </p>
            )}
          </div>
          {data.potentialCannibalization && (
            <div className="mt-2 p-2 bg-amber-50 dark:bg-amber-950/30 border border-amber-200 dark:border-amber-800 rounded-md">
              <p className="text-xs text-amber-800 dark:text-amber-300">
                <strong>Potential Cannibalization:</strong> Multiple pages are
                ranking for this query.
              </p>
            </div>
          )}
        </div>
      )}

      {isLoading && (
        <div className="flex justify-center items-center h-full py-8">
          <LogoSpinner variant="sm" loadingText="Loading..." />
        </div>
      )}
    </div>
  );
}
