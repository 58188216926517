import { CustomizeResultsButton } from "@/components/AdvancedEditor/plugins/ResearchPlugin/Overview";
import { useUpdateDocument } from "@/features/documents/api/updateDocument";
import { useNotificationStore } from "@/stores/notifications";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { TbCheck, TbSearch } from "react-icons/tb";
import { Button, Input, Label } from "../../../components/Elements";
import { cn } from "../../../utils/style";
import { CountrySelect } from "./CountrySelect";
import { LanguageSelect } from "./LanguageSelect";
import { SearchResultCard } from "./SearchResultCard";

const ResultCards = ({
  articles,
  validArticles,
  handleUpdateBlacklist,
  handleSaveBlacklist,
  blacklist,
  setIsCustomizing,
  isCustomizing,
}) => {
  const selectedCount = useMemo(
    () =>
      validArticles.length -
      Object.keys(blacklist).filter((url) =>
        validArticles.some((article) => article.url === url)
      ).length,
    [validArticles, blacklist]
  );

  const articleCards = useMemo(() => {
    return (isCustomizing ? articles : validArticles)
      .map((article, index) => {
        const rank = index + 1;
        const links = article.links?.length ?? 0;

        return (
          <SearchResultCard
            isCustomizing={isCustomizing}
            handleUpdateBlacklist={handleUpdateBlacklist}
            isCustomImport={article.isCustomImport}
            isResultSelected={!blacklist[article.url]}
            key={article.url} // Use article.url instead of index for key if possible
            title={article.title}
            description={article.description}
            assets={article.assets}
            url={article.url}
            rank={rank}
            wordCount={article.word_count}
            links={links}
            domainAuthority={article.domainAuthority}
            isProcessed={!article.error_msg || article.error_msg.length === 0}
          />
        );
      })
      .filter(Boolean);
  }, [
    isCustomizing,
    articles,
    validArticles,
    blacklist,
    handleUpdateBlacklist,
  ]);

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between">
        <p className="text-sm font-normal text-zinc-600 dark:text-zinc-400">
          {isCustomizing
            ? `${selectedCount} of ${articles.length} search results selected`
            : `We processed ${validArticles.length} search results for your search query.`}
        </p>
        {isCustomizing ? (
          <Button
            variant="primaryBlur"
            size="xs"
            className="h-7"
            onClick={() => {
              handleSaveBlacklist();
              setIsCustomizing(false);
            }}
          >
            Save
          </Button>
        ) : (
          <CustomizeResultsButton
            setCustomizeResultsVisible={setIsCustomizing}
          />
        )}
      </div>
      {articleCards}
    </div>
  );
};

export const SearchQueryStep = ({
  articles,
  results,
  query,
  setQuery,
  setProcessQuery,
  isLoadingSerp,
  selectedLanguage,
  setSelectedLanguage,
  selectedCountry,
  setSelectedCountry,
  globalInstructions,
  setGlobalInstructions,
  fraseDocument,
  fraseDocumentBlacklist,
  setProcessedArticles,
}) => {
  const [isCustomizing, setIsCustomizing] = useState(false);

  const [blacklist, setBlacklist] = useState(fraseDocumentBlacklist || {});
  const sortedArticles = useMemo(() => {
    if (!articles) return [];

    return articles
      .filter((article) => article.isValid)
      .sort((a, b) => {
        if (a.isCustomImport && !b.isCustomImport) return -1;
        if (!a.isCustomImport && b.isCustomImport) return 1;
        return a.index - b.index;
      });
  }, [articles]);

  const validArticles = useMemo(() => {
    return sortedArticles
      .filter((article) => article.isValid && !blacklist[article.url])
      .map((article, index, array) => {
        const rank =
          array
            .slice(0, index)
            .filter((prevArticle) => !prevArticle.isCustomImport).length + 1;

        const matchedResult = results.find(
          (result) => result.url === article.url
        );
        const title = article.title || matchedResult?.title || "Untitled";

        return {
          ...article,
          rank: article.isCustomImport ? null : rank,
          title,
        };
      });
  }, [sortedArticles, blacklist, results]);

  const { addNotification } = useNotificationStore();
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
  });

  useEffect(() => {
    setProcessedArticles(validArticles);
  }, [validArticles]);

  const handleUpdateLocalBlacklist = (url, isSelected) => {
    const newBlacklist = { ...blacklist };
    if (!isSelected) {
      delete newBlacklist[url];
    } else {
      newBlacklist[url] = true;
    }
    setBlacklist(newBlacklist);
  };

  const handleUpdateDocumentBlacklist = () => {
    const updatedValidArticles = (articles || [])
      .filter((article) => article.isValid && !blacklist[article.url])
      .sort((a, b) => a.index - b.index);

    if (updatedValidArticles.length < 5) {
      addNotification({
        title: "Customize search results",
        message: "Please select at least 5 results.",
        type: "error",
      });
      return;
    }

    let newDocument = {
      ...fraseDocument,
      metadata: {
        ...fraseDocument.metadata,
        blacklist: blacklist,
      },
    };

    updateDocumentMutation.mutate(newDocument);
  };

  return (
    <div className="flex-col items-center justify-between p-4 w-[600px] max-w-[700px] self-center mx-auto">
      <h2 className="text-xl font-semibold text-zinc-800 dark:text-zinc-100">
        Search Query
      </h2>
      <p className="text-sm mt-4 font-normal text-zinc-600 dark:text-zinc-300">
        Analyze the top Google search results for your target search query.
        Frase will use information from these results to improve the quality of
        your AI-generated article.
      </p>
      <div className="flex flex-col items-start space-y-2 w-full">
        <Label className="font-medium text-zinc-500 text-xs mt-4">
          Search Query
        </Label>
        <Input
          type="text"
          autoFocus
          placeholder={`Enter a search query target (long-tail keywords work best)`}
          className={cn(
            articles?.length > 0 &&
              "ring-emerald-600 disabled:opacity-100 dark:ring-emerald-400/60"
          )}
          value={query}
          disabled={isLoadingSerp || validArticles.length > 0}
          onChange={(value) => setQuery(value)}
          onBlur={(event: ChangeEvent<HTMLInputElement>) =>
            setQuery(event.target.value)
          }
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setProcessQuery();
            }
          }}
          secondIcon={
            validArticles.length > 0 && (
              <TbCheck
                className="flex-shrink-0"
                style={{
                  strokeWidth: 3,
                }}
              />
            )
          }
          secondButtonClassName={cn(
            articles?.length > 0 &&
              "visible text-emerald-900 dark:text-emerald-400"
          )}
        />
      </div>

      {validArticles.length === 0 && (
        <div className="flex items-center justify-between space-x-2 mt-4">
          <div className="flex flex-col items-start space-y-2 w-full">
            <Label className="font-medium text-zinc-500 text-xs">
              Language
            </Label>
            <LanguageSelect
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
              triggerClassName="mt-2"
              className=""
            />
          </div>
          <div className="flex flex-col items-start space-y-2 w-full">
            <Label className="font-medium text-zinc-500 text-xs">Country</Label>
            <CountrySelect
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              triggerClassName="mt-2"
              className=""
            />
          </div>
        </div>
      )}
      {sortedArticles.length > 0 ? (
        <ResultCards
          blacklist={blacklist}
          validArticles={validArticles}
          articles={sortedArticles}
          handleUpdateBlacklist={handleUpdateLocalBlacklist}
          handleSaveBlacklist={handleUpdateDocumentBlacklist}
          setIsCustomizing={setIsCustomizing}
          isCustomizing={isCustomizing}
        />
      ) : (
        <>
          <Button
            variant="aiBlur"
            className="mt-4"
            startIcon={<TbSearch />}
            disabled={query.length === 0 || isLoadingSerp}
            onClick={(event) => {
              event.preventDefault();
              setProcessQuery();
            }}
            isLoading={isLoadingSerp}
          >
            Process query
          </Button>
        </>
      )}
    </div>
  );
};
