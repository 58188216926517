import { axios } from "@/lib/axios";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation } from "react-query";
import { useNotificationStore } from "../../../stores/notifications";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY as string);

interface UpdatePaymentMethodRequest {
  success_url?: string;
  cancel_url?: string;
}

const updateUserPaymentMethod = async (
  requestData: UpdatePaymentMethodRequest
) => {
  const data = await axios.post("/stripe/updateUserPaymentMethod", requestData);
  return data;
};

export const useUpdatePaymentMethod = () => {
  const { addNotification } = useNotificationStore();

  const mutation = useMutation(updateUserPaymentMethod, {
    onSuccess: async (data) => {
      const stripe = await stripePromise;

      if (data.session_id) {
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.session_id,
        });

        if (error) {
          addNotification({
            type: "error",
            title: "Payment Method Update Failed",
            message: error.message || "Unable to redirect to Stripe Checkout",
          });
        }
      }
    },
    onError: (error: any) => {
      addNotification({
        type: "error",
        title: "Payment Method Update Failed",
        message: error.response?.data?.error || "An unexpected error occurred",
      });
    },
  });

  return {
    updatePaymentMethod: mutation.mutate,
    isLoading: mutation.isLoading,
    error: mutation.error,
  };
};
