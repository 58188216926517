"use client";

import {
  BarChart3,
  Bot,
  CalendarRange,
  FileText,
  Search,
  TrendingDown,
  TrendingUp,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Elements";
import { FraseResearchBot } from "@/components/Elements/Logo/FraseResearchBot";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { axios } from "@/lib/axios";
import { useNotificationStore } from "@/stores/notifications";
import OauthPopup from "react-oauth-popup";
import { useGetSiteDashboard } from "../api/getSiteDashboard";
import HeaderRow from "../components/HeaderRow";
import HistoricalMetricsChart from "../components/HistoricalMetricsChart";
import { MetricCardsGrid } from "../components/MetricCardsGrid";
import PageOpportunitiesTable from "../components/PageOpportunitiesTable";
import PageSidebarContent from "../components/PageSidebarContent";
import QueryOpportunitiesTable from "../components/QueryOpportunitiesTable";
import QuerySidebarContent from "../components/QuerySidebarContent";
import { ResizableSidebar } from "../components/ResizeableSidebar";
import SiteAIRecommendationsDialog from "../components/SiteAIRecommendationsDialog";
import TabsContainer from "../components/TabContainer";
import { useContentOpportunitiesStore } from "../store";
import { ContentOpportunityPage, ContentOpportunityQuery } from "../types";

export default function SiteDashboard() {
  const navigate = useNavigate();
  const {
    siteUrl,
    isInit,
    isGSCEnabled,
    domainLimit,
    weeks,
    setWeeks,
    integrateGSC,
  } = useContentOpportunitiesStore();
  const [authUrl, setAuthUrl] = useState<string | null>(null);
  const { addNotification } = useNotificationStore();

  // State for sidebar
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarType, setSidebarType] = useState<"page" | "query">("page");
  const [selectedPage, setSelectedPage] =
    useState<ContentOpportunityPage | null>(null);
  const [selectedQuery, setSelectedQuery] =
    useState<ContentOpportunityQuery | null>(null);
  const [showAIOverview, setShowAIOverview] = useState(false);

  // State for selected metric
  const [selectedMetric, setSelectedMetric] = useState<string>("clicks");

  // State for selected tab
  const [selectedTab, setSelectedTab] = useState<string>("attention");

  // Time period options
  const timeOptions = [
    { label: "4 weeks", value: "4" },
    { label: "8 weeks", value: "8" },
    { label: "12 weeks (Quarter)", value: "12" },
    { label: "26 weeks (Half Year)", value: "26" },
    { label: "52 weeks (Year)", value: "52" },
  ];

  // Effect to close sidebar when siteUrl changes
  useEffect(() => {
    // Close the sidebar when siteUrl changes
    if (sidebarOpen) {
      setSidebarOpen(false);
      setSelectedPage(null);
      setSelectedQuery(null);
    }
  }, [siteUrl]);

  // Fetch authorization URL for GSC integration
  const fetchAuthUrl = async () => {
    try {
      const currentUrl = new URL(window.location.href);
      const baseUrl = `${currentUrl.protocol}//${currentUrl.host}`;
      const redirectUri = `${baseUrl}/ignore-auth`;
      const response: { auth_url: string } = await axios.post(
        "/searchConsole/getAuthUrl",
        { redirect_uri: redirectUri }
      );
      const authUrl = new URL(response.auth_url);
      const state = { type: "gsc" };
      authUrl.searchParams.set(
        "state",
        encodeURIComponent(JSON.stringify(state))
      );
      setAuthUrl(authUrl.toString());
    } catch (error) {
      addNotification({
        type: "error",
        title: "Error Fetching Auth URL",
        message: "Could not retrieve the authentication URL.",
      });
    }
  };

  // Fetch auth URL if domains are allowed and URL isn't already set
  useEffect(() => {
    if (domainLimit > 0 && !authUrl) {
      fetchAuthUrl();
    }
  }, [isInit, isGSCEnabled, domainLimit, authUrl]);

  const renderGSCIntegrationButton = () => {
    if (!isGSCEnabled && domainLimit > 0 && authUrl) {
      return (
        <OauthPopup
          url={authUrl}
          onCode={(code) => {
            integrateGSC(code, navigate, addNotification);
          }}
          title={"Authorize Google Search Console"}
          width={600}
          height={600}
          onClose={() => {}}
        >
          <Button className="whitespace-nowrap">
            Integrate Search Console
          </Button>
        </OauthPopup>
      );
    }
    return null;
  };

  // Only execute the query if siteUrl is defined
  const {
    data: dashboardData,
    isLoading,
    error,
  } = useGetSiteDashboard({
    site_url: siteUrl || "",
    weeks,
    config: {
      enabled: !!siteUrl, // Disable the query when siteUrl is undefined
    },
  });

  // Check for React Query error
  if (error) {
    return (
      <ContentLayout title="Content Opportunities">
        <HeaderRow />
        <div className="flex flex-col items-center justify-center w-full py-20">
          <div className="bg-white dark:bg-zinc-800 rounded-md p-10 shadow-glow max-w-lg border space-y-4 dark:border-zinc-700">
            <div className="space-y-4">
              <h1 className="text-lg font-medium dark:text-white">
                Error Loading Content Opportunities
              </h1>
              <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                {(error as Error).message ||
                  "An error occurred while loading content opportunities data."}
              </p>
              <Button onClick={() => window.location.reload()}>
                Refresh Page
              </Button>
            </div>
          </div>
        </div>
      </ContentLayout>
    );
  }

  // Fallback to sample data if API data is not available
  const data = dashboardData || null;

  // Loading state
  if (!isInit || (!!siteUrl && isLoading)) {
    return (
      <ContentLayout title="Content Opportunities">
        <HeaderRow />
        <div className="relative flex items-center w-full py-20 justify-center">
          <LogoSpinner
            variant="md"
            loadingText="Loading Content Opportunities..."
          />
        </div>
      </ContentLayout>
    );
  }

  // If GSC isn't enabled or domain limit is 0, show plan pricing with upgrade UI
  if (!isGSCEnabled || !data || domainLimit === 0) {
    return (
      <ContentLayout title="Content Opportunities">
        <HeaderRow />
        <div className="flex flex-col items-center justify-center w-full py-20">
          <div className="bg-white dark:bg-zinc-800 rounded-md p-10 shadow-glow max-w-lg border space-y-4 dark:border-zinc-700">
            <FraseResearchBot />
            <div className="space-y-4">
              <h1 className="text-lg font-medium dark:text-white">
                Content Opportunities
              </h1>
              <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                Monitor, analyze, and optimize your content based on real-time
                data from Google Search Console.
              </p>
              <div>
                <Table className="w-full mb-4">
                  <TableHeader className="bg-zinc-50 dark:bg-zinc-800">
                    <TableRow>
                      <TableHead className="w-1/2 py-1 px-4 text-left text-xs font-semibold text-zinc-900 dark:text-white">
                        Plan
                      </TableHead>
                      <TableHead className="w-1/2 py-1 px-4 text-right text-xs font-semibold text-zinc-900 dark:text-white">
                        Domains
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                        Free / Solo
                      </TableCell>
                      <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                        0
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                        Basic
                      </TableCell>
                      <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                        1
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                        Team
                      </TableCell>
                      <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                        Unlimited
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {domainLimit === 0 ? (
                  <Button
                    variant="primaryBlur"
                    size="sm"
                    className="bg-emerald-100"
                    onClick={() => {
                      navigate("/app/settings/plans");
                    }}
                  >
                    Upgrade your plan to continue
                  </Button>
                ) : (
                  renderGSCIntegrationButton()
                )}
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
    );
  }

  // Render sidebar content based on type and loading/error states
  const renderSidebarContent = () => {
    if (sidebarType === "page") {
      if (!selectedPage) {
        return <div className="p-4">Select a page to view details</div>;
      }

      return (
        <PageSidebarContent
          pageOpportunity={selectedPage}
          onClose={() => setSidebarOpen(false)}
        />
      );
    }

    if (sidebarType === "query") {
      if (!selectedQuery) {
        return <div className="p-4">Select a query to view details</div>;
      }

      return (
        <QuerySidebarContent
          queryOpportunity={selectedQuery}
          onClose={() => setSidebarOpen(false)}
        />
      );
    }

    return null;
  };

  // Main content when GSC is integrated
  return (
    <ContentLayout title="Content Opportunities">
      <ResizableSidebar
        defaultOpen={sidebarOpen}
        sidebarContent={renderSidebarContent()}
        defaultSize={30}
        minSize={30}
        maxSize={50}
        side="right"
        className="h-full"
      >
        <HeaderRow />
        <div className="container mx-auto py-6 px-4 sm:px-6 lg:px-8 flex flex-col gap-6 h-[calc(100vh-4rem)] overflow-y-auto overflow-x-hidden">
          <div className="flex justify-end items-center gap-3">
            <Select value={weeks} onValueChange={setWeeks}>
              <SelectTrigger className="w-[180px]">
                <div className="flex items-center gap-2">
                  <CalendarRange className="h-4 w-4 text-zinc-500" />
                  <SelectValue placeholder="Select time period" />
                </div>
              </SelectTrigger>
              <SelectContent>
                {timeOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button
              startIcon={<Bot />}
              variant="aiBlur"
              size="sm"
              className="w-fit"
              onClick={() => setShowAIOverview(true)}
            >
              Summary
            </Button>
          </div>
          <MetricCardsGrid
            metrics={[
              {
                title: "Clicks",
                value: data.periodCompare.clicks,
                growth: data.periodCompare.clicks_growth,
                icon: <BarChart3 className="h-4 w-4" />,
                isPercentage: false,
                id: "clicks",
              },
              {
                title: "Impressions",
                value: data.periodCompare.impressions,
                growth: data.periodCompare.impressions_growth,
                icon: <FileText className="h-4 w-4" />,
                id: "impressions",
              },
              {
                title: "CTR",
                value: data.periodCompare.ctr,
                growth: data.periodCompare.ctr_growth,
                icon: <TrendingUp className="h-4 w-4" />,
                isPercentage: true,
                id: "ctr",
              },
              {
                title: "Position",
                value: data.periodCompare.position,
                growth: data.periodCompare.position_diff,
                icon: <Search className="h-4 w-4" />,
                isPosition: true,
                id: "position",
              },
            ]}
            dateRange={data.periodCompare.date_range}
            comparisonDateRange={data.periodCompare.comparison_date_range}
            selectedMetric={selectedMetric}
            onSelectMetric={setSelectedMetric}
          />

          {/* Historical Metrics Chart */}
          {data.historicalMetrics && data.historicalMetrics.length > 0 && (
            <HistoricalMetricsChart
              key="historical-metrics-chart" // Add a stable key to prevent re-rendering
              metrics={data.historicalMetrics}
              selectedMetric={selectedMetric}
            />
          )}

          <TabsContainer
            defaultValue="attention"
            value={selectedTab}
            onValueChange={setSelectedTab}
            compact={false}
            tabs={[
              {
                value: "attention",
                label: "Need Attention",
                icon: <TrendingDown className="h-4 w-4" />,
                count: data.pagesNeedingAttention.length,
                badgeColor: "red",
                content: (
                  <PageOpportunitiesTable
                    opportunities={data.pagesNeedingAttention}
                    onClick={(pageOpportunity) => {
                      setSidebarType("page");
                      setSelectedPage(pageOpportunity);
                      setSidebarOpen(true);
                    }}
                  />
                ),
              },
              {
                value: "opportunities",
                label: "Optimize Content",
                icon: <TrendingUp className="h-4 w-4" />,
                count: data.optimizeContentOpportunities.length,
                badgeColor: "amber",
                content: (
                  <PageOpportunitiesTable
                    opportunities={data.optimizeContentOpportunities}
                    onClick={(pageOpportunity) => {
                      setSidebarType("page");
                      setSelectedPage(pageOpportunity);
                      setSidebarOpen(true);
                    }}
                  />
                ),
              },
              {
                value: "new-content",
                label: "Create Content",
                icon: <FileText className="h-4 w-4" />,
                count: data.newContentOpportunities.length,
                badgeColor: "green",
                content: (
                  <QueryOpportunitiesTable
                    opportunities={data.newContentOpportunities}
                    onClick={(queryOpportunity) => {
                      setSidebarType("query");
                      setSelectedQuery(queryOpportunity);
                      setSidebarOpen(true);
                    }}
                  />
                ),
              },
            ]}
          />
        </div>
      </ResizableSidebar>
      <SiteAIRecommendationsDialog
        siteDashboard={dashboardData}
        show={showAIOverview}
        onHide={() => setShowAIOverview(false)}
      />
    </ContentLayout>
  );
}
