import { useQueryClient } from "react-query";
import { useNotificationStore } from "../../../stores/notifications";
import { useCheckPaymentStatus, useCreatePaymentIntent } from "./paymentIntent";

interface BeginSubscriptionRequest {
  plans: Array<{ plan: string; quantity?: number }>;
}

export const useBeginSubscription = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();
  const createPaymentIntent = useCreatePaymentIntent();
  const checkPaymentStatus = useCheckPaymentStatus();

  const handlePayment = async (requestData: BeginSubscriptionRequest) => {
    // 1. Create payment intent
    const data = await createPaymentIntent.mutateAsync(requestData);

    const { client_secret, payment_intent_id } = data;
    // 2. Attach payment method using Stripe.js (this should be done in the component)
    return { client_secret, payment_intent_id };
  };

  return {
    createPaymentIntent: handlePayment,
    checkPaymentStatus,
    isLoading: createPaymentIntent.isLoading,
    error: createPaymentIntent.error,
  };
};
