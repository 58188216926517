import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/Elements";
import { Navigation } from "@/components/Layout/MobileNavigation";
import { axios } from "@/lib/axios";
import { useNotificationStore } from "@/stores/notifications";
import { useEffect, useState } from "react";
import { TbChevronDown } from "react-icons/tb";
import OauthPopup from "react-oauth-popup";
import { useNavigate } from "react-router-dom";
import { useContentOpportunitiesStore } from "../store";
import SelectSiteDialog from "./SelectSiteDialog";
import UpgradeToContinueDialog from "./UpgradeToContinueDialog";

interface HeaderRowProps {
  /**
   * When true, the site selection dropdown will be disabled
   * Used for pages where site selection shouldn't be allowed
   */
  disableSiteSelection?: boolean;
}

function HeaderRow({ disableSiteSelection = false }: HeaderRowProps) {
  const [isChangingSite, setIsChangingSite] = useState(false);
  const [authUrl, setAuthUrl] = useState<string | null>(null);
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  const {
    siteUrl,
    setSiteUrl,
    authorizedSites,
    gscSites,
    disableSearchConsole,
    isLoadingDisableSearchConsole,
    domainLimit,
    isInit,
    isGSCEnabled,
    integrateGSC,
    init,
    showUpgradeToContinueDialog,
    showSelectSiteDialog,
    setShowSelectSiteDialog,
    setShowUpgradeToContinueDialog,
  } = useContentOpportunitiesStore();

  // Initialize GSC when component mounts
  useEffect(() => {
    init();
  }, [init]);

  // Fetch authorization URL for GSC integration
  const fetchAuthUrl = async () => {
    try {
      const currentUrl = new URL(window.location.href);
      const baseUrl = `${currentUrl.protocol}//${currentUrl.host}`;
      const redirectUri = `${baseUrl}/ignore-auth`;
      const response: { auth_url: string } = await axios.post(
        "/searchConsole/getAuthUrl",
        { redirect_uri: redirectUri }
      );
      const authUrl = new URL(response.auth_url);
      const state = { type: "gsc" };
      authUrl.searchParams.set(
        "state",
        encodeURIComponent(JSON.stringify(state))
      );
      setAuthUrl(authUrl.toString());
    } catch (error) {
      addNotification({
        type: "error",
        title: "Error Fetching Auth URL",
        message: "Could not retrieve the authentication URL.",
      });
    }
  };

  // Fetch auth URL if domains are allowed and URL isn't already set
  useEffect(() => {
    if (domainLimit > 0 && !authUrl) {
      fetchAuthUrl();
    }
  }, [isInit, isGSCEnabled, domainLimit, authUrl]);

  const handleSiteChange = async (site: string) => {
    setIsChangingSite(true);
    setSiteUrl(site);
    setIsChangingSite(false);
  };

  const isSingleSite = authorizedSites.length === 1;
  const hasSingleGscSite = gscSites.length === 1;

  // Determine if we should show the dropdown arrow
  const showDropdownIcon = () => {
    if (disableSiteSelection) return false;
    if (domainLimit === 1) return false;
    if (domainLimit === Infinity) return gscSites.length > 1;
    return !isSingleSite;
  };

  // Determine if the dropdown content should be displayed
  const showDropdownContent = () => {
    if (disableSiteSelection) return false;
    if (domainLimit === 1) return false;
    if (domainLimit === Infinity) return gscSites.length > 1;
    return !isSingleSite;
  };

  // Render GSC integration UI in header only when needed
  const renderGSCIntegrationButton = () => {
    if (!isGSCEnabled && domainLimit > 0 && authUrl) {
      return (
        <OauthPopup
          url={authUrl}
          onCode={(code) => {
            integrateGSC(code, navigate, addNotification);
          }}
          title={"Authorize Google Search Console"}
          width={600}
          height={600}
          onClose={() => {}}
        >
          <Button size="2xs" className="whitespace-nowrap">
            Integrate Search Console
          </Button>
        </OauthPopup>
      );
    }
    return null;
  };

  // Handle the dropdown click when domain limit is 1
  const handleDropdownClick = () => {
    if (domainLimit === 1) {
      setShowUpgradeToContinueDialog(true);
    }
  };

  return (
    <div className="flex flex-col h-fit">
      <div className="flex flex-col sm:flex-row justify-between items-center px-6 h-14 w-full border-b dark:border-zinc-800">
        <div className="flex items-center mb-2 sm:mb-0">
          <Navigation />
          <span className="text-sm font-medium dark:text-white items-center whitespace-nowrap">
            Content Opportunities
          </span>
        </div>
        <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
          {isGSCEnabled ? (
            <>
              <DropdownMenu>
                <DropdownMenuTrigger
                  asChild
                  disabled={isChangingSite || disableSiteSelection}
                >
                  {/** @ts-ignore */}
                  <Button
                    variant="outline"
                    size="2xs"
                    className="inline-flex items-center justify-between w-fit whitespace-nowrap"
                    endIcon={showDropdownIcon() ? <TbChevronDown /> : undefined}
                    label={"Domain"}
                    isLoading={isChangingSite}
                    disabled={isChangingSite || disableSiteSelection}
                    title={
                      disableSiteSelection
                        ? "Site selection disabled on this page"
                        : domainLimit === 1
                        ? "Upgrade to add more domains"
                        : undefined
                    }
                    onClick={
                      domainLimit === 1 ? handleDropdownClick : undefined
                    }
                  >
                    {siteUrl || "Select a site"}
                  </Button>
                </DropdownMenuTrigger>
                {showDropdownContent() && (
                  <DropdownMenuContent className="w-full sm:w-[200px] max-h-[200px] overflow-y-auto">
                    {gscSites.map((site) => (
                      <DropdownMenuItem
                        key={site}
                        onSelect={() => handleSiteChange(site)}
                      >
                        {site}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                )}
              </DropdownMenu>

              <Button
                variant="text"
                onClick={disableSearchConsole}
                size="2xs"
                className="whitespace-nowrap"
                isLoading={isLoadingDisableSearchConsole}
              >
                Log out of Search Console
              </Button>
            </>
          ) : (
            renderGSCIntegrationButton()
          )}
        </div>
      </div>

      {/* GSC integration dialogs */}
      <SelectSiteDialog
        open={showSelectSiteDialog}
        onClose={() => setShowSelectSiteDialog(false)}
        onCancel={disableSearchConsole}
      />
      <UpgradeToContinueDialog
        open={showUpgradeToContinueDialog}
        onClose={() => setShowUpgradeToContinueDialog(false)}
        onCancel={disableSearchConsole}
      />
    </div>
  );
}

export default HeaderRow;
