import { axios } from "@/lib/axios";
import { useNotificationStore } from "@/stores/notifications";
import { useMutation, useQueryClient } from "react-query";

interface Plan {
  plan: string;
  quantity?: number;
}

interface CreateUserSubscriptionParams {
  plans: Plan[];
  coupon?: string;
}

interface CreateUserSubscriptionResponse {
  type: "setup" | "payment";
  clientSecret: string;
}

const createUserSubscription = async (
  params: CreateUserSubscriptionParams
): Promise<CreateUserSubscriptionResponse> => {
  const data = await axios.post<CreateUserSubscriptionResponse>(
    "/stripe/createUserSubscription",
    params
  );
  return data;
};

export const useCreateUserSubscription = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation(createUserSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries("subscription");
    },
    onError: (error: Error) => {
      addNotification({
        type: "error",
        title: "Error creating subscription",
        message: error.message,
      });
    },
  });
};
