interface PositionChangeProps {
  positionDiff: number;
}

export default function PositionChange({ positionDiff }: PositionChangeProps) {
  // For position metrics, negative diff means improvement (moving up in rankings)
  const isImproved = positionDiff < 0;
  const absoluteChange = Math.abs(positionDiff).toFixed(1);

  return (
    <span
      className={
        isImproved
          ? "text-green-600 dark:text-green-400"
          : "text-red-600 dark:text-red-400"
      }
    >
      {isImproved ? "-" : "+"}
      {absoluteChange}
    </span>
  );
}
