import { useQuery, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";

// This represents the actual data format returned by the API
export interface AIArticleUsageData {
  full_access_articles: number;
  addon_usage: number;
  monthly_purchases: number;
  purchase_limit: number;
  individual_credits_used: number;
  total_articles: number;
  purchase_limit_reached: boolean;
  // Enterprise-specific fields
  is_enterprise?: boolean;
  enterprise_monthly_limit?: number;
  enterprise_monthly_usage?: number;
  documents_remaining?: number;
}

export const getAiArticleUsage = async () => {
  const response = await axios.post("/getAIArticleUsage");
  return response;
};

export const useGetAiArticleUsage = (options = {}) => {
  return useQuery("aiArticleUsage", getAiArticleUsage, {
    ...options,
  });
};

// Helper function to check if an article credit was already consumed
export const wasArticleCreditConsumed = (
  articleId: number | undefined,
  usageResponse: AIArticleUsageData | undefined
): boolean => {
  if (!articleId || !usageResponse ) return false;

  // The original implementation assumed response had articles, but current format doesn't
  // If your implementation needs to check for consumed articles, you would need to add that logic here
  return false;
};

// Helper function to check if subscription plan has the addon feature
export const hasAddonFeature = (subscriptionData: any): boolean => {
  if (!subscriptionData) return false;

  // Check if subscription has the add_on feature
  return subscriptionData.add_on === true;
};

// Get the addon usage directly
export const calculateAddonUsage = (
  usageResponse: AIArticleUsageData | undefined
): number => {
  if (!usageResponse) return 0;
  return usageResponse.addon_usage || 0;
};

// Get individual credits used directly
export const calculateIndividualCreditsUsed = (
  usageResponse: AIArticleUsageData | undefined
): number => {
  if (!usageResponse) return 0;
  return usageResponse.individual_credits_used || 0;
};

// Get total articles directly
export const calculateTotalArticles = (
  usageResponse: AIArticleUsageData | undefined
): number => {
  if (!usageResponse) return 0;
  return usageResponse.total_articles || 0;
};

// Calculate remaining addon credits (assuming 30 total credits per month for addon)
export const calculateRemainingAddonCredits = (
  usageResponse: AIArticleUsageData | undefined
): number => {
  if (!usageResponse) return 0;

  const addonUsage = calculateAddonUsage(usageResponse);
  const ADDON_MONTHLY_LIMIT = 30; // Monthly limit for addon credits

  return Math.max(0, ADDON_MONTHLY_LIMIT - addonUsage);
};

// Function to invalidate aiArticleUsage cache
export const useInvalidateAiArticleUsage = () => {
  const queryClient = useQueryClient();

  return () => {
    return queryClient.invalidateQueries("aiArticleUsage");
  };
};

// Helper function to check if user is on an enterprise plan
export const isEnterprisePlan = (
  usageResponse: AIArticleUsageData | any | undefined
): boolean => {
  // Handle the case when the input might not be the expected AIArticleUsageResponse
  if (!usageResponse || !usageResponse.data) return false;

  // Check directly for is_enterprise flag in data
  if (typeof usageResponse.data.is_enterprise === 'boolean') {
    return usageResponse.data.is_enterprise;
  }

  // If it's subscription data, check if the plan name contains "enterprise"
  if (typeof usageResponse.data.plan === "string") {
    return usageResponse.data.plan.toLowerCase().includes("enterprise");
  }

  // Any other case, return false
  return false;
};

// Helper function to get enterprise monthly document limit from EnterpriseWizardInfo
export const getEnterpriseDocumentLimit = (
  enterpriseWizardInfo?: EnterpriseWizardInfo | undefined
): number => {
  // If no wizard info or not enterprise, return 0
  if (!enterpriseWizardInfo || !enterpriseWizardInfo.data || !enterpriseWizardInfo.data.has_enterprise) return 0;

  return enterpriseWizardInfo.data.monthly_document_limit || 0;
};

// Helper function to get enterprise monthly document usage from EnterpriseWizardInfo
export const getEnterpriseDocumentUsage = (
  enterpriseWizardInfo?: EnterpriseWizardInfo | undefined
): number => {
  // If no wizard info or not enterprise, return 0
  if (!enterpriseWizardInfo || !enterpriseWizardInfo.data || !enterpriseWizardInfo.data.has_enterprise) return 0;

  return enterpriseWizardInfo.data.monthly_document_usage || 0;
};

// Helper function to check if enterprise document limit is reached
export const isEnterpriseDocumentLimitReached = (
  enterpriseWizardInfo?: EnterpriseWizardInfo | undefined
): boolean => {
  // If no data provided or not an enterprise plan, return false
  if (!enterpriseWizardInfo || !enterpriseWizardInfo.data || !enterpriseWizardInfo.data.has_enterprise)
    return false;

  // Check if usage has reached the limit
  return (
    enterpriseWizardInfo.data.monthly_document_usage >=
    enterpriseWizardInfo.data.monthly_document_limit
  );
};

// Enterprise Wizard Info data interface
export interface EnterpriseWizardInfoData {
  has_enterprise: boolean;
  documents_remaining: number;
  monthly_document_limit: number;
  used_seats: number;
  monthly_document_usage: number;
  total_seats: number;
  available_seats: number;
}

// Enterprise Wizard Info response interface
export interface EnterpriseWizardInfo {
  data: EnterpriseWizardInfoData;
  status: number;
  statusText: string;
  headers: any;
  config: any;
}

// Helper function to get enterprise wizard info
export const getEnterpriseWizardInfo =
  async (): Promise<EnterpriseWizardInfo> => {
    try {
      const response = await axios.post("/getWizardEnterpriseInfo");
      return response;
    } catch (error) {
      console.error("Error fetching enterprise wizard info:", error);
      return {
        data: {
          has_enterprise: false,
          documents_remaining: 0,
          monthly_document_limit: 0,
          used_seats: 0,
          monthly_document_usage: 0,
          total_seats: 0,
          available_seats: 0,
        },
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
      };
    }
  };

// Hook to get enterprise wizard info - using React Query for caching
export const useGetEnterpriseWizardInfo = (options = {}) => {
  return useQuery<EnterpriseWizardInfo>(
    "enterpriseWizardInfo",
    getEnterpriseWizardInfo,
    {
      ...options, // Allow overriding defaults
    }
  );
};
