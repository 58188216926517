import * as React from "react";

import { Button } from "@/components/Elements/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Elements/Dialog";

export type ConfirmationDialogProps = {
  triggerButton: React.ReactElement;
  confirmButton: React.ReactElement;
  title: string;
  body?: string;
  cancelButtonText?: string;
  icon?: "danger" | "info";
  isDone?: boolean;
  open: () => void;
  isOpen: boolean;
  close: () => void;
  isLoading?: boolean;
};

export const ConfirmationDialog = ({
  triggerButton,
  confirmButton,
  title,
  body = "",
  cancelButtonText = "Cancel",
  icon = "danger",
  isDone = false,
  open,
  isOpen,
  close,
  isLoading,
}: ConfirmationDialogProps) => {
  const cancelButtonRef = React.useRef(null);

  React.useEffect(() => {
    if (isDone) {
      close();
    }
  }, [isDone, close]);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          close();
        }
      }}
    >
      <DialogTrigger>{triggerButton}</DialogTrigger>
      <DialogContent>
        <DialogHeader className="pb-0 flex items-center justify-between">
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription className="px-4">{body}</DialogDescription>
        <DialogFooter className="px-4 pb-4">
          <Button
            variant="outlineBlur"
            onClick={close}
            ref={cancelButtonRef}
            disabled={isLoading}
          >
            {cancelButtonText}
          </Button>
          {confirmButton}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
