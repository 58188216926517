import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import {
  SiteDashboardAIRecommendations,
  SiteDashboardAIRecommendationsResponse,
  SiteDashboardData,
} from "../types";

export const getSiteDashboardAI = async ({
  site_dashboard,
}: {
  site_dashboard: SiteDashboardData;
}): Promise<SiteDashboardAIRecommendations> => {
  const response: SiteDashboardAIRecommendationsResponse = await axios.post(
    "/content-recommendations/site-dashboard/ai-recommendations",
    site_dashboard
  );

  // Check if the response indicates an error
  if (response.status === "error") {
    throw new Error(
      response.message || "An error occurred fetching site dashboard"
    );
  }

  return response.data as SiteDashboardAIRecommendations;
};

type QueryFnType = typeof getSiteDashboardAI;

type UseGetSiteDashboardOptions = {
  site_dashboard: SiteDashboardData;
  config?: QueryConfig<QueryFnType>;
};

export const useGetSiteDashboardAI = ({
  site_dashboard,
  config,
}: UseGetSiteDashboardOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "contentOpportunities",
      "siteDashboardAI",
      site_dashboard.site_url,
      site_dashboard.periodCompare.date_range.start_date,
    ],
    queryFn: () => getSiteDashboardAI({ site_dashboard }),
  });
};
