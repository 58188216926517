import { Skeleton } from "@/components/Elements/Skeleton";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useUser } from "@/features/auth/api/getUser";
import {
  useGetAiArticleUsage,
  useGetEnterpriseWizardInfo,
} from "@/features/subscription/api/getAiArticleLimit";
import { getPlanNickname } from "@/features/subscription/utils/getPlanNickname";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { useEffect } from "react";
import { TbArrowLeft, TbInfoCircle } from "react-icons/tb";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Progress,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/Elements";
import { WizardSteps } from "./WizardSteps";

// Component that only renders the Purchase credits button when org_role is 1
const UserRoleConditionalButton = ({
  onClick,
  className = "",
}: {
  onClick: () => void;
  className?: string;
}) => {
  const { data: userData } = useUser();
  const userOrgRole = userData?.org_role;

  // Only render the button if org_role is 1
  if (userOrgRole === 1) {
    return (
      <div className={className}>
        <Button variant="aiBlur" size="sm" onClick={onClick} className="w-full">
          Purchase credits
        </Button>
      </div>
    );
  }

  // Return null if org_role is not 1
  return null;
};

const CreditsBadge = ({
  usage = 0,
  limit = 0,
  isLoading,
  isProAddon,
  documentsRemaining,
  documentsPurchased,
  individualCreditsUsed = 0,
  isEnterprise = false,
  enterpriseMonthlyLimit,
  enterpriseMonthlyUsage = 0,
  purchaseLimitReached = false,
}: {
  usage: number;
  limit: number;
  isLoading: boolean;
  isProAddon?: boolean;
  documentsRemaining?: number;
  documentsPurchased?: number;
  individualCreditsUsed?: number;
  isEnterprise?: boolean;
  enterpriseMonthlyLimit?: number;
  enterpriseMonthlyUsage?: number;
  purchaseLimitReached?: boolean;
}) => {
  const navigate = useNavigate();

  // Enterprise plan handling
  if (isEnterprise) {
    // Only calculate these values if not loading to prevent flash of red UI
    const documentsRemaining = isLoading
      ? null
      : Number(enterpriseMonthlyLimit - enterpriseMonthlyUsage);
    const hasReachedLimit = isLoading
      ? false
      : documentsRemaining !== null && documentsRemaining <= 0;

    return (
      <div
        className={`flex flex-col justify-center border dark:border-zinc-500/40 rounded-md p-3 mb-4 mx-3 shadow-sm dark:shadow-lg ${
          hasReachedLimit
            ? "border-red-400/50 bg-red-50 dark:bg-red-900/10"
            : "border-zinc-400/30 bg-zinc-50 dark:bg-zinc-800"
        }`}
      >
        <div className="space-y-2">
          <div className="flex items-center flex-wrap">
            <span
              className={`text-xs font-medium mr-1 ${
                hasReachedLimit
                  ? "text-red-600 dark:text-red-400"
                  : "text-zinc-600 dark:text-white"
              }`}
            >
              Enterprise AI Document Limit
            </span>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className="inline-flex">
                    <TbInfoCircle
                      className={`h-3.5 w-3.5 ${
                        hasReachedLimit ? "text-red-500" : "text-zinc-500"
                      }`}
                    />
                  </span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent className="z-[10000]">
                    {isLoading
                      ? "Loading document usage information..."
                      : hasReachedLimit
                      ? `You have reached your monthly document limit. Please contact sales to increase your limit.`
                      : `${
                          documentsRemaining ?? 0
                        } documents remaining this month from your Enterprise plan limit of ${Number(
                          enterpriseMonthlyLimit
                        )}`}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </TooltipProvider>
          </div>
          {isLoading ? (
            <Skeleton className="h-4 w-full" />
          ) : (
            <>
              <Progress
                progress={
                  !hasReachedLimit && enterpriseMonthlyLimit > 0
                    ? (enterpriseMonthlyUsage / enterpriseMonthlyLimit) * 10
                    : 0 // Only show empty progress when limit is reached
                }
                className={`my-2 ${
                  hasReachedLimit ? "bg-red-200 dark:bg-red-800/20" : ""
                }`}
              />
              <div
                className={`text-xs ${
                  hasReachedLimit
                    ? "text-red-600 dark:text-red-400"
                    : "text-zinc-500 dark:text-white"
                }`}
              >
                <span className="font-medium">
                  {isLoading
                    ? "--"
                    : hasReachedLimit
                    ? "0"
                    : documentsRemaining}{" "}
                  / {Number(enterpriseMonthlyLimit)}
                </span>
                <span className="ml-1"> documents remaining this month</span>
              </div>

              {hasReachedLimit && (
                <div className="mt-2">
                  <Button
                    variant="outlineBlur"
                    size="sm"
                    onClick={() =>
                      window.open(
                        "mailto:sales@frase.io?subject=Enterprise%20Document%20Limit%20Increase",
                        "_blank"
                      )
                    }
                    className="w-full text-xs py-1"
                  >
                    Contact Sales to Increase Limit
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  // Standard non-enterprise handling
  const proAddonRemaining = isProAddon ? Math.max(0, limit - usage) : 0;
  const totalRemaining = proAddonRemaining + (documentsRemaining || 0);
  const totalLimit = (isProAddon ? limit : 0) + (documentsPurchased || 0);

  const handlePurchaseClick = () => {
    navigate("/app/settings/document-bundles");
  };

  return (
    <div className="flex flex-col justify-center border border-zinc-400/30 dark:border-zinc-500/40 rounded-md p-3 mb-4 mx-3 bg-zinc-50 dark:bg-zinc-800 shadow-sm dark:shadow-lg">
      <div className="space-y-2">
        <div className="flex items-center flex-wrap">
          <span className="text-xs font-medium text-zinc-600 dark:text-white mr-1">
            Rank-Ready AI Document Credits
          </span>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="inline-flex">
                  <TbInfoCircle className="text-zinc-500 h-3.5 w-3.5" />
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent className="z-[10000]">
                  {isProAddon && documentsRemaining !== null
                    ? `Combined total from Rank-Ready AI Documents Add-on (${proAddonRemaining} remaining) and Rank-Ready credits (${documentsRemaining} remaining, ${
                        individualCreditsUsed || 0
                      } used)`
                    : isProAddon
                    ? `${proAddonRemaining} articles remaining this month from Rank-Ready AI Documents Add-on`
                    : documentsRemaining !== null
                    ? `${documentsRemaining} Rank-Ready articles remaining, ${
                        individualCreditsUsed || 0
                      } individual credits used`
                    : "No Rank-Ready credits available"}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          </TooltipProvider>
        </div>
        {isLoading ? (
          <Skeleton className="h-4 w-full" />
        ) : (
          <>
            <Progress
              progress={
                totalRemaining > 0 && totalLimit > 0
                  ? ((totalLimit - totalRemaining) / totalLimit) * 10
                  : 0
              }
              className="my-2"
            />
            <div className="text-xs text-zinc-500 dark:text-white">
              {totalLimit === 0 &&
              (documentsRemaining === null || documentsRemaining === 0) ? (
                <span>No article credits available</span>
              ) : (
                <>
                  <span className="font-medium">
                    {totalRemaining} / {totalLimit}
                  </span>
                  <span className="ml-1">articles available</span>
                </>
              )}
            </div>
            {(totalRemaining === 0 ||
              totalLimit === 0 ||
              documentsRemaining === null) &&
              !purchaseLimitReached && (
                <UserRoleConditionalButton
                  onClick={handlePurchaseClick}
                  className="mt-3"
                />
              )}
          </>
        )}
      </div>
    </div>
  );
};

export const AiWizardNavigation = ({
  wizardSteps,
  currentStep,
  setCurrentStep,
  isLoadingSerp,
  stepValidations,
  furthestStepReached,
  setIsEnterpriseLimitReached,
}: {
  wizardSteps: any;
  currentStep: number;
  setCurrentStep: any;
  isLoadingSerp: boolean;
  stepValidations: any;
  furthestStepReached: number;
  setIsEnterpriseLimitReached?: (isReached: boolean) => void;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: aiArticleUsageData, isLoading: isLoadingAiArticleUsage } =
    useGetAiArticleUsage({});
  const { data: subscriptionData } = useSubscription();
  const { data: enterpriseWizardInfo, isLoading: isLoadingEnterpriseInfo } =
    useGetEnterpriseWizardInfo({});

  // Check if user has reached monthly purchase limit
  const monthlyPurchases = aiArticleUsageData?.monthly_purchases ?? 0;
  const purchaseLimit = aiArticleUsageData?.purchase_limit ?? 40;
  const purchaseLimitReached =
    aiArticleUsageData?.purchase_limit_reached ??
    aiArticleUsageData?.purchase_limit_reached ??
    monthlyPurchases >= purchaseLimit;

  // Extract enterprise status and limits - prefer enterpriseWizardInfo over aiArticleUsageData
  const isEnterpriseUser =
    getPlanNickname(subscriptionData?.plan) === "Enterprise";

  // Check if enterprise document limit is reached
  const isEnterpriseLimitReached =
    isEnterpriseUser &&
    enterpriseWizardInfo?.has_enterprise &&
    enterpriseWizardInfo?.monthly_document_usage >=
      enterpriseWizardInfo?.monthly_document_limit;

  const addonUsage =
    !isLoadingAiArticleUsage && aiArticleUsageData
      ? aiArticleUsageData?.addon_usage || 0
      : 0;

  const individualCreditsUsed =
    !isLoadingAiArticleUsage && aiArticleUsageData
      ? aiArticleUsageData?.individual_credits_used || 0
      : 0;

  const totalArticles =
    !isLoadingAiArticleUsage && aiArticleUsageData
      ? aiArticleUsageData?.total_articles || 0
      : 0;

  const aiArticlesUsage = {
    currentMonth: addonUsage,
    limit: 30,
    individualCreditsUsed: individualCreditsUsed,
    totalArticles: totalArticles,
  };

  // Force loading of aiArticleUsage data when component mounts if needed
  useEffect(() => {
    // This ensures we refetch aiArticleUsage when this component mounts if needed
    if (isLoadingAiArticleUsage === false && !aiArticleUsageData) {
      // If we have no data but we're not loading, force a refetch
      queryClient.refetchQueries("aiArticleUsage");
    }
  }, []);

  // Notify parent component when enterprise limit is reached
  useEffect(() => {
    if (
      setIsEnterpriseLimitReached &&
      isEnterpriseUser &&
      enterpriseWizardInfo
    ) {
      const documentsRemaining = Number(
        enterpriseWizardInfo.monthly_document_limit -
          enterpriseWizardInfo.monthly_document_usage
      );
      setIsEnterpriseLimitReached(documentsRemaining <= 0);
    }
  }, [isEnterpriseUser, enterpriseWizardInfo, setIsEnterpriseLimitReached]);

  return (
    <div className="flex flex-col h-screen bg-zinc-50 dark:bg-zinc-800 w-[280px] border-r dark:border-r-zinc-700 flex-shrink-0">
      <div className="mt-4 flex flex-col h-full justify-between">
        <div>
          <div className="flex px-2">
            <Button
              variant="text"
              onClick={() => {
                navigate("/app");
              }}
              buttonIcon={<TbArrowLeft />}
              textClassName="ml-2 text-start flex w-full text-zinc-900 dark:text-white"
              className="mx-2 w-full hover:bg-zinc-900/5 dark:hover:bg-white/5"
            >
              Rank-Ready AI Document
            </Button>
          </div>
          <div className="pt-6">
            <WizardSteps
              wizardSteps={wizardSteps}
              currentStep={currentStep}
              isLoadingSerp={isLoadingSerp}
              setCurrentStep={setCurrentStep}
              stepValidations={stepValidations}
              furthestStepReached={furthestStepReached}
            />
          </div>
        </div>
        <CreditsBadge
          usage={aiArticlesUsage.currentMonth}
          limit={aiArticlesUsage.limit}
          isLoading={isLoadingAiArticleUsage || isLoadingEnterpriseInfo}
          isProAddon={!!subscriptionData?.add_on}
          documentsRemaining={Math.max(
            0,
            (aiArticleUsageData?.monthly_purchases || 0) -
              (aiArticleUsageData?.individual_credits_used || 0)
          )}
          documentsPurchased={aiArticleUsageData?.monthly_purchases || 0}
          individualCreditsUsed={individualCreditsUsed}
          isEnterprise={isEnterpriseUser}
          enterpriseMonthlyLimit={
            enterpriseWizardInfo?.monthly_document_limit || 0
          }
          enterpriseMonthlyUsage={
            enterpriseWizardInfo?.monthly_document_usage || 0
          }
          purchaseLimitReached={purchaseLimitReached}
        />
      </div>
    </div>
  );
};
