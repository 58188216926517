import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/Elements/Chart/Chart";
import { useIsDarkMode } from "@/utils/isDarkMode";
import dayjs from "dayjs";
import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { GSCBaseMetrics } from "../types";
import { getMetricColor } from "../utils";

interface HistoricalMetricsChartProps {
  metrics: GSCBaseMetrics[];
  selectedMetric: string;
}

function formatMetricValue(value: number, metric: string): string {
  if (metric === "ctr") {
    return `${value.toFixed(2)}%`;
  } else if (metric === "position") {
    return value.toFixed(2);
  } else if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1).replace(/\.0$/, "")}M`;
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed(1).replace(/\.0$/, "")}K`;
  }
  return value.toString();
}

function formatDateRange(startDate: string, endDate: string): string {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  // If in same month, show as "Jan 1-15, 2023"
  if (start.month() === end.month() && start.year() === end.year()) {
    return `${start.format("MMM D")}-${end.format("D, YYYY")}`;
  }

  // If in same year, show as "Jan 1 - Feb 15, 2023"
  if (start.year() === end.year()) {
    return `${start.format("MMM D")} - ${end.format("MMM D, YYYY")}`;
  }

  // Otherwise show full dates "Jan 1, 2022 - Feb 15, 2023"
  return `${start.format("MMM D, YYYY")} - ${end.format("MMM D, YYYY")}`;
}

// Format date range for x-axis labels (shorter version)
function formatShortDateRange(startDate: string, endDate: string): string {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  // If in same month, show as "Jan 1-15"
  if (start.month() === end.month() && start.year() === end.year()) {
    return `${start.format("MMM D")}-${end.format("D")}`;
  }

  // If in same year, show as "Jan-Feb"
  if (start.year() === end.year()) {
    return `${start.format("MMM")}-${end.format("MMM")}`;
  }

  // Different years, show as "Jan'22-Feb'23"
  return `${start.format("MMM'YY")}-${end.format("MMM'YY")}`;
}

// Define the component as a regular function component
function HistoricalMetricsChart({
  metrics,
  selectedMetric,
}: HistoricalMetricsChartProps) {
  const isDarkMode = useIsDarkMode();

  // Sort metrics by date (oldest to newest)
  const sortedMetrics = [...metrics].sort(
    (a, b) =>
      dayjs(a.date_range.start_date).valueOf() -
      dayjs(b.date_range.start_date).valueOf()
  );

  // Format data for the chart
  const chartData = sortedMetrics.map((metric) => ({
    startDate: dayjs(metric.date_range.start_date).format("YYYY-MM-DD"),
    endDate: dayjs(metric.date_range.end_date).format("YYYY-MM-DD"),
    dateRange: formatDateRange(
      metric.date_range.start_date,
      metric.date_range.end_date
    ),
    shortDateRange: formatShortDateRange(
      metric.date_range.start_date,
      metric.date_range.end_date
    ),
    [selectedMetric]: metric[selectedMetric as keyof GSCBaseMetrics] as number,
    tooltipValue: formatMetricValue(
      metric[selectedMetric as keyof GSCBaseMetrics] as number,
      selectedMetric
    ),
    tooltipLabel: selectedMetric,
  }));

  const chartConfig = {
    [selectedMetric]: {
      label: selectedMetric.charAt(0).toUpperCase() + selectedMetric.slice(1),
      color: getMetricColor(selectedMetric),
    },
  } satisfies ChartConfig;

  if (!chartData.length) return null;

  return (
    <div className="w-full mt-2 mb-2 bg-white dark:bg-zinc-800 pt-4 px-4 rounded-lg border border-zinc-200 dark:border-zinc-700 shadow-sm">
      <h3 className="text-sm font-medium mb-2 text-zinc-700 dark:text-zinc-300">
        {selectedMetric.charAt(0).toUpperCase() + selectedMetric.slice(1)}{" "}
      </h3>
      <ResponsiveContainer height={200} width="100%">
        <ChartContainer config={chartConfig}>
          <AreaChart
            data={chartData}
            className="text-zinc-800 dark:text-zinc-200"
            margin={{ top: 10, right: 10, left: 10, bottom: 20 }}
          >
            <defs>
              <linearGradient
                id={`gradient-${selectedMetric}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="5%"
                  stopColor={getMetricColor(selectedMetric)}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={getMetricColor(selectedMetric)}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis
              dataKey="shortDateRange"
              tickLine={false}
              axisLine={false}
              tickMargin={10}
              minTickGap={20}
              angle={-15}
              height={60}
              tick={{ fontSize: 11 }}
            />
            <YAxis
              tick={{ fill: isDarkMode ? "#d4d4d8" : "#3f3f46" }}
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickCount={5}
              tickFormatter={(value) => {
                return formatMetricValue(value, selectedMetric);
              }}
            />
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  color={getMetricColor(selectedMetric)}
                  indicator="dot"
                  labelFormatter={(_, payload) => {
                    if (payload && payload.length > 0) {
                      return payload[0].payload.dateRange;
                    }
                    return "";
                  }}
                  className="bg-white dark:bg-zinc-900 dark:text-white dark:border-zinc-800"
                />
              }
            />
            <Area
              type="monotone"
              dataKey={selectedMetric}
              stroke={getMetricColor(selectedMetric)}
              fillOpacity={1}
              fill={`url(#gradient-${selectedMetric})`}
            />
          </AreaChart>
        </ChartContainer>
      </ResponsiveContainer>
    </div>
  );
}

// Export the memoized version of the component
export default React.memo(HistoricalMetricsChart);
