import { Avatar, Input, InputLabel } from "@/components/Elements";
import { ContentLayout } from "@/components/Layout";
import { isLegacyPlan } from "@/features/subscription/utils/tiers";
import { useGetUsersForOrg } from "@/features/users/api/getUsersForOrg";
import { useEffect, useMemo, useState } from "react";
import { TbSearch } from "react-icons/tb";
import {
  AvatarFallback,
  AvatarImage,
  Button,
} from "../../../components/Elements";
import { cn, usernameToColor } from "../../../utils/style";
import { useSubscriptionUpdate } from "../../auth/api/getSubscription";
import { useGetEnterpriseSeatInfo } from "../../teams/api/getEnterpriseSeatInfo";
import { useGetPendingUsers } from "../../teams/api/getPendingUsers";
import { useUpdateTeam } from "../../teams/api/updateTeam";
import ConfirmRemoveUserDialog from "../components/ConfirmRemoveUserDialog";
import { InviteMembersButton } from "../components/InviteDialog";

export const Team = () => {
  const userOrgQuery = useGetUsersForOrg();
  const subscriptionUpdateQuery = useSubscriptionUpdate();
  const { data: pendingUsers, isLoading: isLoadingPendingUsers } =
    useGetPendingUsers();
  const updateTeamMutation = useUpdateTeam();
  // Fetch enterprise seat information
  const { data: enterpriseSeatInfo, isLoading: isLoadingEnterpriseSeatInfo } =
    useGetEnterpriseSeatInfo();

  const [organizationName, setOrganizationName] = useState(
    subscriptionUpdateQuery.data?.name || ""
  );
  const [searchTerm, setSearchTerm] = useState("");

  const isLegacyTeamPlan = useMemo(() => {
    return isLegacyPlan(subscriptionUpdateQuery.data?.plan || "");
  }, [subscriptionUpdateQuery.data?.plan]);

  const isEnterprisePlan = useMemo(() => {
    // Check if the plan name contains enterprise (case insensitive)
    const planName = subscriptionUpdateQuery.data?.plan || "";
    return (
      planName.toLowerCase().includes("enterprise") ||
      // Also check the enterprise flag from the seat info
      enterpriseSeatInfo?.isEnterprise === true
    );
  }, [subscriptionUpdateQuery.data?.plan, enterpriseSeatInfo]);

  // Get max team size based on plan type
  const maxTeamSize = useMemo(() => {
    if (isLegacyTeamPlan) {
      return Infinity;
    } else if (isEnterprisePlan && enterpriseSeatInfo) {
      // For enterprise plans, use the total seats from enterprise subscription
      return enterpriseSeatInfo.totalSeats || 5;
    } else {
      // Default team plan limit
      return 9;
    }
  }, [isLegacyTeamPlan, isEnterprisePlan, enterpriseSeatInfo]);

  const allMembers = useMemo(() => {
    const existingUsers = userOrgQuery.data || [];
    const pendingUsersWithStatus =
      pendingUsers?.map((user) => ({
        id: user.id,
        fullName: user.username,
        username: user.username,
        role: user.role,
        status: "Invited",
        orgId: user.teamId,
      })) || [];
    return [...existingUsers, ...pendingUsersWithStatus];
  }, [userOrgQuery.data, pendingUsers]);

  const isLoading =
    userOrgQuery.isLoading ||
    subscriptionUpdateQuery.isLoading ||
    isLoadingPendingUsers ||
    isLoadingEnterpriseSeatInfo;

  useEffect(() => {
    if (subscriptionUpdateQuery.data) {
      setOrganizationName(subscriptionUpdateQuery.data.name);
    }
  }, [subscriptionUpdateQuery.data]);

  const filteredMembers = allMembers.filter(
    (user) =>
      user.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.username?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (userOrgQuery.isError || subscriptionUpdateQuery.isError)
    return <div>Error loading team members</div>;

  return (
    <ContentLayout>
      <div className="flex flex-col items-start h-screen pt-5 px-8 w-full space-y-4 overflow-y-scroll">
        <div>
          <h2 className="text-xl dark:text-white font-medium">Team</h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400 mt-2 min-w-[550px]">
            Manage your team members and organization information.
          </p>
          <h3 className="text-md dark:text-white font-medium pt-4 mt-4 border-t dark:border-t-zinc-800 w-full">
            General
          </h3>
          <div className="space-y-4 w-full mt-4">
            <Input
              label="Organization Name"
              value={organizationName}
              onChange={(value) => setOrganizationName(value)}
              placeholder="Enter your organization name"
              isLoading={isLoading}
            />
            <Button
              type="button"
              variant="primaryBlur"
              className="mt-2 w-fit"
              size="xs"
              onClick={() =>
                updateTeamMutation.mutate({ name: organizationName })
              }
              isLoading={updateTeamMutation.isLoading}
            >
              Update
            </Button>
          </div>
          <div className="mt-4 w-full">
            <h3 className="text-md dark:text-white font-medium pt-4 mt-4 border-t dark:border-t-zinc-800 w-full">
              Manage team
            </h3>
            <p className="text-sm text-zinc-500 dark:text-zinc-400 py-4">
              {isLegacyTeamPlan
                ? "Your legacy Team plan includes unlimited users."
                : isEnterprisePlan
                ? enterpriseSeatInfo && (
                    <>
                      Your Enterprise plan includes up to{" "}
                      {enterpriseSeatInfo.totalSeats} seats.
                      {enterpriseSeatInfo.limitReached &&
                        " You've reached your seat limit. Contact sales to add more seats."}
                      {!enterpriseSeatInfo.limitReached &&
                        enterpriseSeatInfo.remainingSeats &&
                        ` You have ${enterpriseSeatInfo.remainingSeats} seat${
                          enterpriseSeatInfo.remainingSeats !== 1 ? "s" : ""
                        } remaining.`}
                    </>
                  )
                : "Your Team plan includes up to 9 users. Contact us to upgrade to Enterprise for more users."}
            </p>
            <InputLabel label="Team members" />
            <div className="w-full mb-4 flex items-center justify-between space-x-2">
              <Input
                value={searchTerm}
                onChange={(value) => setSearchTerm(value)}
                placeholder="Search by name or email"
                startIcon={<TbSearch />}
                isLoading={isLoading}
              />
              <InviteMembersButton
                disabled={
                  !isLegacyTeamPlan && allMembers.length >= maxTeamSize
                }
                maxTeamSize={maxTeamSize}
                currentTeamSize={allMembers.length}
                isEnterprise={isEnterprisePlan}
                enterpriseInfo={enterpriseSeatInfo}
              />
            </div>

            {filteredMembers?.length > 0 && (
              <>
                <span className="text-xs text-zinc-500">
                  {filteredMembers.length} members
                </span>
                <table className="w-full">
                  <tbody>
                    {filteredMembers.map((teamUser, index) => (
                      <tr
                        key={teamUser.id}
                        className={`${
                          index > 0 ? "border-t border-zinc-150" : ""
                        }`}
                      >
                        <td className="p-2">
                          <div className="flex items-center">
                            <Avatar className="w-6 h-6 mr-2">
                              <AvatarImage src="" />
                              <AvatarFallback
                                className={cn(
                                  "text-zinc-50 text-4xs",
                                  usernameToColor(teamUser.fullName)
                                )}
                              >
                                {teamUser.fullName
                                  .split(" ")
                                  .map((name) => name[0])
                                  .slice(0, 2)
                                  .join("")}
                              </AvatarFallback>
                            </Avatar>
                            <div className="flex flex-col ml-2">
                              <span className="text-sm font-medium text-zinc-900 dark:text-white">
                                {teamUser.fullName}
                              </span>
                              <span className="text-xs text-zinc-500 leading-tight">
                                {teamUser.username}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="p-2 text-center">
                          <span className="text-xs text-zinc-900 dark:text-white">
                            {teamUser.status ||
                              (teamUser.org_role === 1 ? "Admin" : "Member")}
                          </span>
                        </td>
                        <td className="p-2 text-right">
                          <ConfirmRemoveUserDialog
                            userName={teamUser.fullName}
                            userEmail={teamUser.username}
                            userId={teamUser.id}
                            orgId={teamUser.orgId}
                            isPending={teamUser.status === "Invited"}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
