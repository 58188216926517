import { formatPercentage } from "../utils";

import { formatNumber } from "../utils";

import { TableCell } from "@/components/Elements/Table";

import { TableBody } from "@/components/Elements/Table";

import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Elements/Table";
import { cn } from "@/lib/merge";
import { ContentOpportunityQuery } from "../types";
import GrowthMetric from "./GrowthMetric";
import PositionChange from "./PositionChange";
import ScoreDisplay from "./ScoreDisplay";
import StatusBadge from "./StatusBadge";

interface QueryOpportunitiesTableProps {
  opportunities: ContentOpportunityQuery[];
  onClick?: (opportunity: ContentOpportunityQuery) => void;
}

export default function QueryOpportunitiesTable({
  opportunities,
  onClick,
}: QueryOpportunitiesTableProps) {
  const hasRelevanceScore = opportunities.some(
    // @ts-ignore
    (opportunity) => typeof opportunity.relevance_score === "number"
  );

  return (
    <div className="rounded-md border dark:border-zinc-800 overflow-x-auto">
      <Table>
        <TableHeader className="bg-zinc-50 dark:bg-zinc-800">
          <TableRow>
            <TableHead className="w-[300px] text-zinc-900 dark:text-white">
              Query
            </TableHead>
            <TableHead className="text-zinc-900 dark:text-white">
              Status
            </TableHead>
            <TableHead className="text-zinc-900 dark:text-white">
              Score
            </TableHead>
            {hasRelevanceScore && (
              <TableHead className="text-zinc-900 dark:text-white">
                Relevance
              </TableHead>
            )}
            <TableHead className="text-zinc-900 dark:text-white">Pos</TableHead>
            <TableHead className="text-zinc-900 dark:text-white whitespace-nowrap">
              Pos Δ
            </TableHead>
            <TableHead className="text-zinc-900 dark:text-white">
              Clicks
            </TableHead>
            <TableHead className="text-zinc-900 dark:text-white whitespace-nowrap">
              Clicks Δ
            </TableHead>
            <TableHead className="text-zinc-900 dark:text-white">
              Impr
            </TableHead>
            <TableHead className="text-zinc-900 dark:text-white whitespace-nowrap">
              Impr Δ
            </TableHead>
            <TableHead className="text-zinc-900 dark:text-white">CTR</TableHead>
            <TableHead className="text-zinc-900 dark:text-white whitespace-nowrap">
              CTR Δ
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {opportunities.map((opportunity) => (
            <TableRow
              key={opportunity.query}
              onClick={() => onClick?.(opportunity)}
              className={cn(
                "cursor-pointer",
                onClick && "hover:bg-zinc-100 dark:hover:bg-zinc-800"
              )}
            >
              <TableCell className="font-medium truncate max-w-[280px]">
                {opportunity.query}
              </TableCell>
              <TableCell>
                <StatusBadge status={opportunity.status} />
              </TableCell>
              <TableCell>
                <ScoreDisplay
                  score={opportunity.opportunity_score}
                  maxScore={100}
                  isOpportunityScore={true}
                />
              </TableCell>
              {hasRelevanceScore && (
                <TableCell>
                  <ScoreDisplay
                    // @ts-ignore
                    score={opportunity.relevance_score}
                    maxScore={100}
                  />
                </TableCell>
              )}
              <TableCell>{opportunity.position.toFixed(1)}</TableCell>
              <TableCell>
                <PositionChange positionDiff={opportunity.position_diff} />
              </TableCell>
              <TableCell>{formatNumber(opportunity.clicks)}</TableCell>
              <TableCell>
                <GrowthMetric growth={opportunity.clicks_growth} />
              </TableCell>
              <TableCell>{formatNumber(opportunity.impressions)}</TableCell>
              <TableCell>
                <GrowthMetric growth={opportunity.impressions_growth} />
              </TableCell>
              <TableCell>{formatPercentage(opportunity.ctr)}</TableCell>
              <TableCell>
                <GrowthMetric growth={opportunity.ctr_growth} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
