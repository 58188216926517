import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements/Tooltip";
import { cn } from "@/utils/style";
import React from "react";
import { getOpportunityScoreColorClasses } from "../utils";

interface OpportunityScoreWithTooltipProps {
  score: number;
  /** Size variant of the score display - 'sm' for tables, 'xs' for sidebar (default) */
  size?: "xs" | "sm";
}

/**
 * A reusable component to display an opportunity score with a tooltip
 * The score is color-coded based on its value:
 * - 0-49: Red
 * - 50-70: Yellow/Amber
 * - 71-100: Green
 */
export const OpportunityScoreWithTooltip: React.FC<
  OpportunityScoreWithTooltipProps
> = ({ score, size = "xs" }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            className={cn(
              "font-medium inline-flex items-center justify-center px-1.5 py-0.5 rounded-full cursor-help text-center",
              getOpportunityScoreColorClasses(score),
              size === "xs" ? "text-2xs min-w-[45px]" : "text-xs min-w-[55px]"
            )}
          >
            {score}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          Content opportunity score: higher means more impactful
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
