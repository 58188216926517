interface GrowthMetricProps {
  growth: number;
  formatValue?: (value: number) => string;
}

export default function GrowthMetric({
  growth,
  formatValue = (value: number) => `${((value - 1) * 100).toFixed(0)}%`,
}: GrowthMetricProps) {
  // For growth metrics, value > 1 means improvement
  const isImproved = growth > 1;
  const isUnchanged = growth === 1;

  // Format the growth value (default: percentage change)
  const formattedValue = formatValue(growth);

  if (isUnchanged) {
    return <span className="text-gray-500">0%</span>;
  }

  return (
    <span
      className={
        isImproved
          ? "text-green-600 dark:text-green-400"
          : "text-red-600 dark:text-red-400"
      }
    >
      {isImproved ? "+" : ""}
      {formattedValue}
    </span>
  );
}
