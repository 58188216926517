import {
  ArticleMetadata,
  Input,
  PasteAssetButton,
} from "@/components/Elements";
import { Skeleton } from "@/components/Elements/Skeleton";
import { useBingSearchResults } from "@/features/documents/api/getBingSearchResults";
import {
  useHandlePasteCitation,
  useHandlePasteContent,
} from "@/features/documents/utils/pasting";
import { useGoogleNewsResults } from "@/features/serp/api/getGoogleNewsResults";
import { useDocumentStore } from "@/stores/document";
import { useEffect, useRef, useState } from "react";
import { VirtualizedList } from "./Questions";

interface NewsItem {
  title: string;
  unix_timestamp?: number;
  source_link: string;
  is_google_news: boolean;
  url: string;
}

function News() {
  const { document } = useDocumentStore();
  // docId is not used, removing it to fix lint warning
  const query = document.query;
  const [filter, setFilter] = useState("");
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const handlePasteContent = useHandlePasteContent();
  const handlePasteCitation = useHandlePasteCitation();
  const parentRef = useRef();

  const { data: googleNewsResults, isLoading: isGoogleNewsLoading } =
    useGoogleNewsResults({
      queries: [{ query: query, country_code: "countryUS" }],
    });

  const { data: bingSearchResults, isLoading: isBingLoading } =
    useBingSearchResults({
      queries: [
        {
          query: query,
          country: null,
          count: 50,
          type: "web",
        },
      ],
    });

  useEffect(() => {
    const allNewsItems = [];
    if (googleNewsResults) {
      allNewsItems.push(...Object.values(googleNewsResults).flat());
    }
    if (bingSearchResults) {
      const bingItems = Object.values(bingSearchResults)
        .filter(result => result && result.webPages && result.webPages.value)
        .flatMap((result) =>
          result.webPages.value.map((item) => ({
            title: item.name,
            unix_timestamp: undefined,
            source_link: item.url,
            is_google_news: false,
            url: item.url,
          }))
        );
      allNewsItems.push(...bingItems);
    }
    setNewsItems(allNewsItems);
  }, [googleNewsResults, bingSearchResults]);

  const filteredNews = newsItems.filter(
    (item) =>
      item &&
      item.title &&
      item.title.toLowerCase().includes(filter.toLowerCase())
  );

  const isLoading = isGoogleNewsLoading || isBingLoading || !newsItems.length;

  const renderLoadingSkeleton = () =>
    Array.from({ length: 10 }).map((_, i) => (
      <div
        key={i}
        className="group mt-2 p-2 cursor-pointer relative items-center rounded-md bg-white transition-shadow hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-black/5 hover:bg-white"
      >
        <div className="absolute inset-0 rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20" />
        <Skeleton className="w-full h-4" />
      </div>
    ));

  const renderNewsItem = (item: NewsItem, index: number) => {
    return (
      <div
        key={index}
        className="flex flex-col mb-2 group/asset-text p-2 cursor-pointer relative rounded-md ring-1 ring-inset ring-zinc-900/7.5 dark:ring-white/10 hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-zinc-50/5"
      >
        <div className="flex justify-between w-full">
          <div className="flex flex-col w-full space-y-1 pl-2">
            <ArticleMetadata
              url={item.url}
              sourceLink={item.source_link}
              urlHost={getSourceUrl(item.url)}
              isGoogleNews={item.is_google_news}
            />
            <div className="flex items-center justify-between">
              <p className="transition-all text-2xs text-zinc-900 dark:text-white">
                {item.title}
              </p>
            </div>
          </div>
          <div className="flex items-center ml-2">
            <PasteAssetButton
              handlePasteContent={handlePasteContent}
              handlePasteCitation={handlePasteCitation}
              headerText={item.title}
              url={item.url}
              className="invisible group-hover/asset-text:visible"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex items-center justify-between px-4 my-2">
        <Input
          value={filter}
          onChange={(value) => setFilter(value)}
          placeholder="Filter by keyword..."
          className="text-xs"
        />
      </div>
      <ul className="space-y-2 pl-4 pr-1">
        {isLoading ? (
          <div className="pr-3">{renderLoadingSkeleton()}</div>
        ) : (
          <VirtualizedList
            items={filteredNews}
            parentRef={parentRef}
            renderItem={renderNewsItem}
          />
        )}
      </ul>
    </div>
  );
}

function getSourceUrl(url: string): string {
  // Implement the logic to extract the domain from the URL
  return new URL(url).hostname;
}

export default News;
