import { axios } from "@/lib/axios";
import storage from "@/utils/storage";
import { useQuery } from "react-query";

export type Subscription = {
  subscription_start_date?: number | null;
  subscription_renewal_date?: number | null;
  subscription_cancel_date?: number | null;
  subscription_end_date?: number | null;
  service_plan?: boolean;
  add_on?: boolean;
  subscription_length?: number;
  plan?: string | null;
  appsumo?: boolean;
  status?:
    | "active_canceled"
    | "canceled"
    | "inactive"
    | "active"
    | "paused"
    | "pending_authentication";
  day_count?: number;
  billing_cycle?: string;
  isSubscriptionValid?: boolean;
  remaining_documents?: number;
  documents_purchased?: number;
};

export const getSubscription = async (): Promise<Subscription> => {
  // Check if refresh token exists before making the API call
  const refreshToken = storage.getRefreshToken();
  if (!refreshToken) {
    // Return a default subscription object for Free trial users
    return {
      subscription_start_date: null,
      service_plan: false,
      add_on: false,
      subscription_length: 0,
      plan: null,
      appsumo: false,
      status: "inactive",
      isSubscriptionValid: false,
    };
  }

  try {
    const data = await axios.post("/stripe/subscription_info");
    return data;
  } catch (error) {
    console.log("Error getting subscription:", error);

    // Return a default subscription object for Free trial users
    return {
      subscription_start_date: null,
      service_plan: false,
      add_on: false,
      subscription_length: 0,
      plan: null,
      appsumo: false,
      status: "inactive",
      isSubscriptionValid: false,
    };
  }
};

export const updateSubscriptionFrase = async (
  subscriptionInfo?: Subscription
) => {
  // Assuming the Java endpoint expects the subscription info in the request body
  const data = await axios.post(
    "/stripe/update_subscription_frase",
    subscriptionInfo
  );
  return data;
};

export const useSubscriptionUpdate = () => {
  return useQuery("subscriptionUpdate", () => updateSubscriptionFrase({}));
};

export const useSubscription = ({
  config,
  enabled = true,
}: {
  config?: any;
  enabled?: boolean;
} = {}) => {
  const query = useQuery("subscription", getSubscription, {
    ...config,
    enabled: enabled && storage.getRefreshToken() !== null,
    retry: 0, // Prevent automatic retries
  });

  if (query.data?.plan?.includes("answers")) {
    window.location.href = "https://answers.frase.io";
  }

  return query;
};
