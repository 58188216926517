import {
  Button,
  SemiCircleProgress,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";
import { Skeleton } from "@/components/Elements/Skeleton";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useGoogleSearchResults } from "@/features/documents/api/getGoogleSearchResults";
import { useUrlBatch } from "@/features/documents/api/getUrlBatch";
import { useUpdateDocument } from "@/features/documents/api/updateDocument";
import {
  filterClusters,
  filterTopics,
  scoreSERP,
  scoreTopics,
  sortByTopicScore,
} from "@/features/documents/utils/topics";
import { Checkout } from "@/features/settings/routes/Checkout";
import {
  calculateRemainingAddonCredits,
  hasAddonFeature,
  useGetAiArticleUsage,
  wasArticleCreditConsumed,
} from "@/features/subscription/api/getAiArticleLimit";
import { getPlanNickname } from "@/features/subscription/utils/getPlanNickname";
import { useDocumentStore } from "@/stores/document";
import { useEditorStore } from "@/stores/editor";
import { useNotificationStore } from "@/stores/notifications";
import { useSerpStore } from "@/stores/serp";
import { marked } from "marked";
import React, { useEffect, useState } from "react";
import { TbAlertCircle, TbInfoCircle, TbSparkles } from "react-icons/tb";
import { useQueryClient } from "react-query";
import { useConsumeArticleCredit } from "../api/consumeArticleCredit";
import { convertJsonToMarkdown } from "../utils/aiArticle";
import { PreviewEditor } from "./AiDocumentPreviewDialog";

export const WizardSuccessMessage = ({
  title,
  aiArticleMetadata,
  aiArticleMarkdown,
  hasFullAccess,
  onNavigateToPreview = () => {},
  renderNavigationButton = true,
}: {
  title?: string;
  aiArticleMetadata?: {
    query: string;
    id: number;
    title: string;
    generated: boolean;
    accepted: boolean;
    has_full_access?: boolean;
    credit_type?: string; // 'rank_ready', 'add_on', or 'enterprise'
  };
  aiArticleMarkdown?: object;
  hasFullAccess?: boolean;
  onNavigateToPreview?: () => void;
  renderNavigationButton?: boolean;
}) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationStore();

  // State to track if payment was successful
  const [showCheckout, setShowCheckout] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [isLoadingSerp, setIsLoadingSerp] = useState(false);

  // Fetch current subscription data to check for available credits
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription();

  // Fetch AI article usage data to check if this article already had a credit applied
  const { data: aiArticleUsageData, isLoading: isLoadingUsageData } =
    useGetAiArticleUsage();

  // Check if this specific article has already had a credit consumed for it
  const isArticleCreditAlreadyConsumed = wasArticleCreditConsumed(
    aiArticleMetadata?.id,
    aiArticleUsageData
  );

  // Check if monthly purchase limit has been reached (max 40 purchasable documents by default)
  const purchaseLimit = 40;
  const individualCreditsLimit = 40;

  // We'll get individual credits used values after proper typing below

  // Check if user has addon feature in their subscription
  const hasAddon = hasAddonFeature(subscriptionData);

  // Calculate remaining addon credits if user has addon
  const remainingAddonCredits = hasAddon
    ? calculateRemainingAddonCredits(aiArticleUsageData)
    : 0;

  // Fixed: We no longer need this variable since we directly use remainingRankReadyCredits
  // and remainingAddonCredits in our logic

  // Cast aiArticleUsageData to any to access these properties safely until proper typing is added
  const monthlyPurchases = (aiArticleUsageData as any)?.monthly_purchases || 0;
  const individualCreditsUsed = (aiArticleUsageData as any)?.individual_credits_used || 0;
  
  // Check if user has space available within their individual credits limit
  const hasCreditsCapacity = individualCreditsUsed < individualCreditsLimit;

  // Calculate remaining rank-ready credits (monthly_purchases - individual_credits_used)
  const remainingRankReadyCredits = monthlyPurchases - individualCreditsUsed;
    
  // Check if user has any remaining rank-ready credits
  const hasRemainingRankReadyCredits = remainingRankReadyCredits > 0;

  // Only show the apply credit button if they have available credits and capacity
  // Credits can be from addon or remaining rank-ready credits
  const hasCreditsAvailable =
    ((hasAddon && remainingAddonCredits > 0) || hasRemainingRankReadyCredits) && hasCreditsCapacity;
    
  console.log('Credit debug info:', {
    hasAddon,
    remainingAddonCredits,
    hasRemainingRankReadyCredits,
    remainingRankReadyCredits,
    hasCreditsCapacity,
    hasCreditsAvailable,
    monthlyPurchases,
    individualCreditsUsed
  });

  // purchaseLimitReached determines if they can buy more credits, but shouldn't prevent
  // using existing credits
  const purchaseLimitReached = monthlyPurchases >= purchaseLimit;

  // Set up the consume article credit mutation
  const {
    mutate: consumeCredit,
    isLoading: isConsumingCredit,
    isSuccess: creditConsumedInSession,
  } = useConsumeArticleCredit();

  // Combined state to track whether credit has been consumed (either in this session or previously)
  const creditConsumed =
    creditConsumedInSession || isArticleCreditAlreadyConsumed;

  const { document: fraseDocument } = useDocumentStore();
  const { serp } = useSerpStore();
  const { urls, topics, articles, clusters } = serp[fraseDocument.id] || {
    urls: [],
    topics: [],
    articles: [],
    clusters: [],
    serpLoaded: false,
  };

  // Convert markdown to HTML for preview - include title for preview
  const markdownWithTitle = convertJsonToMarkdown(
    aiArticleMarkdown || [],
    false
  );

  // State to track if preview content is ready
  const [isPreviewReady, setIsPreviewReady] = useState(false);

  // Effect to handle preview content readiness
  useEffect(() => {
    if (markdownWithTitle) {
      setIsPreviewReady(true);
    }
  }, [markdownWithTitle]);

  // Handle manual credit consumption via button click
  const handleApplyCredit = () => {
    if (!aiArticleMetadata?.id) return;

    // Use the mutation from our hook
    consumeCredit(
      { articleId: aiArticleMetadata.id },
      {
        onSuccess: (data) => {
          // Show success notification
          // Get the credit type from the response - the backend should return this
          const creditType = data?.creditType || 'rank_ready';
          let creditTypeDisplay = 'Rank-Ready AI Document';
          
          if (creditType === 'add_on') {
            creditTypeDisplay = 'Add-On';
          } else if (creditType === 'enterprise') {
            creditTypeDisplay = 'Enterprise';
          }
          
          addNotification({
            type: "success",
            title: "Credit applied",
            message: `A ${creditTypeDisplay} credit has been applied to this document.`,
          });
        },
        onError: (error: any) => {
          // Show error notification
          addNotification({
            type: "error",
            title: "Error",
            message:
              error?.response?.data?.message ||
              "Failed to apply document credit. Please try again.",
          });
        },
      }
    );
  };

  // Query SERP data
  const googleSearchResultsQuery = useGoogleSearchResults({
    searchQuery: aiArticleMetadata?.query,
    document: fraseDocument,
    config: {
      enabled: false, // Disable auto-fetching
    },
    isAiArticleRequest: true,
  });

  const urlBatchQuery = useUrlBatch({
    urls: urls,
    document: fraseDocument,
    config: {
      enabled: false, // Disable auto-fetching
    },
  });

  // Load SERP data when component mounts
  useEffect(() => {
    const loadSerpData = () => {
      setIsLoadingSerp(true);

      googleSearchResultsQuery
        .refetch()
        .then((resp) => {
          if (resp.status === "error") {
            setIsLoadingSerp(false);
            return;
          }

          urlBatchQuery
            .refetch()
            .then(() => {
              setIsLoadingSerp(false);
            })
            .catch(() => {
              setIsLoadingSerp(false);
              console.error("Error loading URL batch data");
            });
        })
        .catch(() => {
          setIsLoadingSerp(false);
          console.error("Error loading Google search results");
        });
    };

    loadSerpData();
  }, [fraseDocument.id]); // Only reload when document ID changes

  const calculateTopicScore = () => {
    // Don't calculate if SERP data is still loading
    if (isLoadingSerp) {
      return null;
    }

    // First, get the initial valid articles for SERP scoring
    const initialValidArticles = (articles || [])?.filter(
      (article) => article.isValid
    );

    if (
      topics &&
      topics.length > 0 &&
      initialValidArticles &&
      initialValidArticles.length > 0 &&
      clusters &&
      clusters.length > 0
    ) {
      let scoredArticles = scoreSERP(
        topics,
        initialValidArticles,
        aiArticleMetadata
      );

      // Since we don't have access to the editor here, we'll use the markdown content
      const text = markdownWithTitle || "";
      const headerNodes = text
        .split("\n")
        .filter((line) => line.startsWith("#"))
        .map((line) => line.replace(/^#+\s*/, ""));
      const textNodes = text.split("\n");

      const scoredTopicsWithEditor = scoreTopics(
        scoredArticles,
        topics,
        clusters,
        aiArticleMetadata,
        "longTail", // Default to longTail type
        headerNodes,
        textNodes,
        title || ""
      );

      const processedTopics = filterTopics(
        scoredTopicsWithEditor.topics,
        "longTail",
        "all",
        aiArticleMetadata
      );

      const processedClusters = filterClusters(
        scoredTopicsWithEditor.clusters,
        "all",
        aiArticleMetadata
      );

      // Now calculate competitor ranking using the scored articles
      const validArticles = scoredArticles
        .filter((article) => article.isValid)
        .sort((a, b) => {
          if (a.isCustomImport && !b.isCustomImport) return -1;
          if (!a.isCustomImport && b.isCustomImport) return 1;
          return a.index - b.index;
        });

      let competitors = [...validArticles];
      const userDoc = {
        title: title || "",
        topic_score: scoredTopicsWithEditor.score,
        url: "",
        word_count: text.split(/\s+/).length,
        isUserDoc: true,
      };
      competitors.push(userDoc);
      competitors = competitors.sort(sortByTopicScore);
      const userRank =
        competitors.findIndex((article) => article.isUserDoc) + 1;
      const totalCompetitors = competitors.length;
      const percentageBetter = Math.round(
        ((totalCompetitors - userRank) / totalCompetitors) * 100
      );

      return {
        score: scoredTopicsWithEditor.score,
        avgScore: scoredTopicsWithEditor.avg_score,
        topScore: scoredTopicsWithEditor.top_score,
        topics: processedTopics,
        clusters: processedClusters,
        userRank,
        totalCompetitors,
        percentageBetter,
        wordCount: userDoc.word_count,
      };
    }

    return null;
  };

  const topicScoreData = calculateTopicScore();
  const score = topicScoreData?.score ?? aiArticleMetadata?.topic_score ?? 0;
  const avgScore =
    topicScoreData?.avgScore ?? aiArticleMetadata?.avg_topic_score ?? 0;
  const handlePaymentSuccess = async () => {
    setIsPolling(true);
    let attempts = 0;
    const maxAttempts = 100; // 100 attempts * 1 second = 100 seconds max wait

    const pollArticleAccess = async () => {
      if (attempts >= maxAttempts) {
        setIsPolling(false);
        setShowCheckout(false);
        return;
      }

      // Invalidate and refetch article data
      await queryClient.invalidateQueries([
        "fetchGptArticle",
        { id: aiArticleMetadata?.id },
      ]);
      const articleData = await queryClient.fetchQuery([
        "fetchGptArticle",
        { id: aiArticleMetadata?.id },
      ]);

      if (articleData?.has_full_access) {
        setIsPolling(false);
        setShowCheckout(false);
        addNotification({
          type: "success",
          title: "Article ready",
          message: "Your article is now ready for you to access.",
        });
      } else {
        attempts++;
        setTimeout(pollArticleAccess, 1000);
      }
    };

    await pollArticleAccess();
  };

  const renderTopicScore = () => {
    return (
      <div className="flex flex-col w-full items-center mx-auto ">
        <div className="flex flex-col items-center gap-1">
          <div className="flex items-center gap-1.5">
            <h1 className="text-base font-semibold text-zinc-800 whitespace-nowrap dark:text-white">
              Topic Score
            </h1>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="floatingIcon"
                    className="text-zinc-800 dark:text-zinc-200"
                    buttonIcon={<TbInfoCircle />}
                  />
                </TooltipTrigger>
                <TooltipContent className="max-w-xs">
                  <p>
                    Content Score measures how well your article covers key
                    topics that rank on Google. A higher score means better
                    chances of ranking.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>

        <div className="relative w-full mt-4">
          <div className="transition-all text-zinc-800 dark:text-zinc-200 z-50 h-16 w-36 mx-auto">
            {score ? (
              <SemiCircleProgress progress={score} label="Your Score" />
            ) : (
              <Skeleton className="h-20 w-36 rounded-t-full" />
            )}
          </div>
        </div>

        {isLoadingSerp ? (
          <div className="flex flex-col items-center gap-4 text-center mt-8 w-full">
            <div className="flex flex-col items-center gap-2">
              <Skeleton className="h-6 w-96" />
              <Skeleton className="h-6 w-96" />
              <Skeleton className="h-6 w-96" />
              <Skeleton className="h-6 w-96" />
            </div>
          </div>
        ) : (
          topicScoreData &&
          typeof topicScoreData.percentageBetter === "number" &&
          topicScoreData.percentageBetter >= 0 && (
            <div className="flex flex-col items-center gap-4 text-center mt-8 w-full">
              <div className="flex flex-col items-center gap-4">
                <div className="text-zinc-600 dark:text-zinc-400 text-base font-medium max-w-xl mx-auto">
                  <p className="text-center font-semibold mb-3 text-lg">
                    Your Rank-Ready Article is complete!
                  </p>
                  <p className="text-center text-md">
                    {topicScoreData?.wordCount ? (
                      <>
                        It's {topicScoreData.wordCount} words and covers many
                        key topics.
                      </>
                    ) : (
                      <Skeleton className="inline-block h-4 w-40" />
                    )}{" "}
                    Your Frase Topic Score is{" "}
                    {score ? (
                      <strong className="font-bold">
                        {Math.floor(score)}%
                      </strong>
                    ) : (
                      <Skeleton className="inline-block h-4 w-8" />
                    )}
                    , yet it outperforms{" "}
                    {typeof topicScoreData?.percentageBetter === "number" ? (
                      <strong className="font-bold">
                        {topicScoreData.percentageBetter}%
                      </strong>
                    ) : (
                      <Skeleton className="inline-block h-4 w-8" />
                    )}{" "}
                    of top search results for{" "}
                    <span className="text-emerald-600 dark:text-emerald-400">
                      {aiArticleMetadata?.query || "this search"}
                    </span>
                    .
                  </p>
                  {!hasFullAccess &&
                    !creditConsumed &&
                    (hasRemainingRankReadyCredits || hasAddon) && (
                      <p className="text-center mt-3">
                        A higher score indicates better ranking potential. Use
                        the Frase Optimization tool to enhance your content's
                        performance.
                      </p>
                    )}
                </div>

                {/* Show the save to document button if either hasFullAccess is true OR credit was consumed (in this session or previously) */}
                {(hasFullAccess || creditConsumed) && (
                  <SaveToDocumentButton
                    aiArticleMetadata={aiArticleMetadata}
                    aiArticleMarkdown={aiArticleMarkdown}
                    onNavigateToPreview={onNavigateToPreview}
                  />
                )}

                {/* Show Apply Credit button when not full access but user has credits and hasn't used one for this article yet */}
                {!hasFullAccess &&
                  !creditConsumed &&
                  (hasRemainingRankReadyCredits || (hasAddon && remainingAddonCredits > 0)) && (
                    <div className="flex flex-col items-center gap-4">
                      <Button
                        onClick={handleApplyCredit}
                        disabled={isConsumingCredit}
                        variant="aiBlur"
                        size="sm"
                        isLoading={isConsumingCredit}
                      >
                        {isConsumingCredit
                          ? "Applying credit..."
                          : "Apply document credit"}
                      </Button>

                      <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">
                        {hasRemainingRankReadyCredits ? (
                          <>
                            You have {remainingRankReadyCredits}{" "}
                            document credit
                            {remainingRankReadyCredits !== 1
                              ? "s"
                              : ""}{" "}
                            remaining.
                          </>
                        ) : hasAddon && remainingAddonCredits > 0 ? (
                          <>
                            You have {remainingAddonCredits} add-on credit
                            {remainingAddonCredits !== 1 ? "s" : ""} remaining
                            this month.
                          </>
                        ) : (
                          <>Applying a document credit to this article.</>
                        )}
                      </p>
                    </div>
                  )}

                {/* Show purchase button when no full access, no credits of any type, and user hasn't already applied a credit to this article */}
                {!hasFullAccess &&
                  !creditConsumed &&
                  !(hasRemainingRankReadyCredits) &&
                  !(hasAddon && remainingAddonCredits > 0) && (
                    <div className="flex flex-col items-center gap-4">
                      <div className="bg-emerald-50 dark:bg-emerald-900/20 rounded-lg p-4 w-[600px] border border-emerald-100 dark:border-emerald-800">
                        <div className="flex flex-col gap-1.5">
                          <div className="flex items-center gap-1 mb-1">
                            <div className="h-px flex-1 bg-emerald-100 dark:bg-emerald-800" />
                            <p className="text-sm font-medium text-emerald-600 dark:text-emerald-400 whitespace-nowrap">
                              Why Buy This Article?
                            </p>
                            <div className="h-px flex-1 bg-emerald-100 dark:bg-emerald-800" />
                          </div>

                          <ul className="text-sm text-zinc-700 dark:text-zinc-300 space-y-2.5">
                            {!topicScoreData ? (
                              <>
                                <li>
                                  <Skeleton className="h-5 w-full" />
                                </li>
                                <li>
                                  <Skeleton className="h-5 w-11/12" />
                                </li>
                                <li>
                                  <Skeleton className="h-5 w-10/12" />
                                </li>
                              </>
                            ) : (
                              <div className="flex flex-col gap-2.5">
                                <li className="flex items-start gap-2">
                                  <span className="text-emerald-500 dark:text-emerald-400 mt-0.5">
                                    ✓
                                  </span>
                                  <span>
                                    <strong>Proven Ranking Potential:</strong>{" "}
                                    {score ? (
                                      `${Math.floor(score)}%`
                                    ) : (
                                      <Skeleton className="inline-block h-4 w-8" />
                                    )}{" "}
                                    Topic Score vs.{" "}
                                    {avgScore ? (
                                      `${Math.floor(avgScore)}%`
                                    ) : (
                                      <Skeleton className="inline-block h-4 w-8" />
                                    )}{" "}
                                    competitor average
                                  </span>
                                </li>
                                <li className="flex items-start gap-2">
                                  <span className="text-emerald-500 dark:text-emerald-400 mt-0.5">
                                    ✓
                                  </span>
                                  <span>
                                    <strong>Time Saver:</strong> Skip hours of
                                    research and writing—publish immediately.
                                  </span>
                                </li>
                                <li className="flex items-start gap-2">
                                  <span className="text-emerald-500 dark:text-emerald-400 mt-0.5">
                                    ✓
                                  </span>
                                  <span>
                                    <strong>Authority Booster:</strong> Signal
                                    expertise to readers and search engines
                                    alike.
                                  </span>
                                </li>
                              </div>
                            )}
                          </ul>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2 mt-1">
                        {/* Show Apply Credit button if they have individual credits left, even if purchase limit is reached */}
                        {hasCreditsAvailable ? (
                          <div className="flex flex-col items-center gap-4">
                            <Button
                              onClick={handleApplyCredit}
                              disabled={isConsumingCredit}
                              variant="aiBlur"
                              size="sm"
                              isLoading={isConsumingCredit}
                            >
                              {isConsumingCredit
                                ? "Applying credit..."
                                : "Apply document credit"}
                            </Button>

                            <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">
                              You have{" "}
                              {individualCreditsLimit - individualCreditsUsed}{" "}
                              individual document credits available.
                            </p>
                          </div>
                        ) : purchaseLimitReached ? (
                          <div className="flex flex-col gap-2 items-center">
                            <Button
                              onClick={() =>
                                window.open(
                                  "https://www.frase.io/contact-us/",
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                              size="sm"
                              variant="primaryBlur"
                              className="w-fit"
                            >
                              Contact Sales
                            </Button>
                            <p className="text-xs text-zinc-600 dark:text-zinc-400 mt-2 max-w-[500px] bg-zinc-100 dark:bg-zinc-800 p-2 rounded border border-zinc-200 dark:border-zinc-700 flex items-start gap-2">
                              <TbAlertCircle
                                className="flex-shrink-0 ml-1 mt-1"
                                size={16}
                              />
                              <span>
                                You've reached the limit of {purchaseLimit}{" "}
                                purchasable Rank-Ready AI articles this month.
                                Contact sales to learn about our Enterprise plan
                                with higher limits.
                              </span>
                            </p>
                          </div>
                        ) : (
                          <Checkout
                            planDetails={{
                              name: "1 Article Credit",
                              title: "1 Article Credit",
                              price: 3.5,
                              stripeName: "addon_1",
                            }}
                            title="Purchase 1 Article Credit"
                            actionType="one-time"
                            successMessage={{
                              title: "Purchase successful",
                              message:
                                "You have successfully purchased the article.",
                              showNotification: false,
                            }}
                            defaultIncludeAddOn={false}
                            onSuccess={handlePaymentSuccess}
                            navigateOnSuccess={false}
                            articleId={aiArticleMetadata?.id.toString()}
                            triggerButton={
                              <Button
                                onClick={() => setShowCheckout(true)}
                                size="md"
                                variant="primaryBlur"
                                disabled={isPolling}
                                isLoading={isPolling}
                              >
                                {isPolling
                                  ? "Processing purchase..."
                                  : "Buy this article for $3.50"}
                              </Button>
                            }
                          />
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center space-y-4 w-full h-full overflow-y-scroll">
      <div className="mt-8"></div>
      {renderTopicScore()}
      <div
        className="bg-zinc-50 dark:bg-zinc-800 flex flex-col border-l border-r border-t w-1/2 overflow-hidden max-w-[700px] rounded-tl-lg rounded-tr-lg relative dark:border-zinc-700 py-8"
        style={{
          maskImage: "linear-gradient(to bottom, black 60%, transparent 90%)",
          WebkitMaskImage:
            "linear-gradient(to bottom, black 30%, transparent 90%)",
          maxHeight: "700px",
        }}
      >
        {isPreviewReady && markdownWithTitle ? (
          <PreviewEditor
            html={markdownWithTitle}
            className="mt-0 w-auto mx-10 h-[500px] max-h-[500px] overflow-hidden"
          />
        ) : (
          <div className="flex flex-col gap-4 px-10">
            <Skeleton className="h-8 w-3/4" />
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-5/6" />
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-4/5" />
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Button that directly saves the AI document to the editor without showing a preview dialog
 */
const SaveToDocumentButton = ({
  aiArticleMetadata,
  aiArticleMarkdown,
  onNavigateToPreview,
}) => {
  const { document: fraseDocument } = useDocumentStore();
  const { setEditor } = useEditorStore();
  const queryClient = useQueryClient();
  const [isLoadingSerp, setIsLoadingSerp] = useState(false);
  const { data: subscriptionData } = useSubscription({});
  const { serp } = useSerpStore();

  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
    isResolvingConflict: true,
  });

  // Check if user is on Team plan
  const isTeamPlan =
    subscriptionData?.plan === "Team" ||
    subscriptionData?.plan === "team" ||
    getPlanNickname(subscriptionData?.plan || "Free").includes("Team");

  const isEnterprisePlan =
    subscriptionData?.plan === "Enterprise" ||
    subscriptionData?.plan === "enterprise" ||
    getPlanNickname(subscriptionData?.plan || "Free").includes("Enterprise");

  // Convert markdown to HTML for insertion
  const markdown = convertJsonToMarkdown(aiArticleMarkdown || [], true);
  const htmlString = marked.parse(markdown || "");

  const handleSaveToDocument = React.useCallback(() => {
    const newTab = {
      name: "AI Document",
      html: htmlString,
      title: aiArticleMetadata?.title || "",
    };

    // For Team plan users, ensure we set the query in the document
    // so SERP processing will work correctly
    // For non-Team plans, include the full metadata update
    // For Team plans, only clear AI article metadata but DON'T set serp_loaded=true yet
    // serp_loaded will be set in handleUpdateDocumentFirstSerpLoad after saving
    const updatedDocument = {
      ...fraseDocument,
      text: [...fraseDocument.text, newTab],
      metadata: {
        ...fraseDocument.metadata,
        ai_article: undefined,
        ai_article_started: false,
      },
    };

    updateDocumentMutation.mutateAsync(updatedDocument).then(() => {
      setEditor((editorState) => {
        editorState.activeTabIndex = updatedDocument.text.length - 1;
      });

      // Only call navigate function if not processing SERP
      // Pass location state to indicate we're coming from WizardSuccessMessage
      onNavigateToPreview({ state: { fromWizardSuccess: true } });
    });
  }, [
    fraseDocument,
    aiArticleMetadata,
    updateDocumentMutation,
    onNavigateToPreview,
    htmlString,
    isTeamPlan,
    isEnterprisePlan,
  ]);

  return (
    <Button
      variant="aiBlur"
      onClick={handleSaveToDocument}
      startIcon={<TbSparkles />}
      isLoading={updateDocumentMutation.isLoading}
    >
      Review and optimize
    </Button>
  );
};
