import { axios } from "@/lib/axios";
import { useMutation } from "react-query";

export const deletePaymentIntentSecret = async (): Promise<void> => {
  await axios.post("/stripe/clearPaymentIntentClientSecret");
};

export const useDeletePaymentIntentSecret = () => {
  return useMutation({
    mutationFn: deletePaymentIntentSecret,
    onError: (error) => {
      console.error("Error clearing payment intent client secret:", error);
    },
  });
};
