import { Outline } from "@/components/AdvancedEditor/plugins/OutlinePlugin/Outline";
import { useEffect, useRef, useState } from "react";

type Heading = {
  header: string;
  url: string;
  user_header_tag: string;
  instruction: string;
  id: number;
  parent?: number;
};

export const addParentToHeadings = (headings: Heading[]): Heading[] => {
  let lastSeenHeaderIdAtEachLevel: { [key: number]: number | null } = {};

  let parsedHeadings = headings.map((heading, index) => {
    return {
      ...heading,
      id: index,
    };
  });

  return parsedHeadings
    .map((heading, index) => {
      const currentLevel = parseInt(
        (heading.user_header_tag && heading.user_header_tag[1]) || "2"
      );
      let parentId = null;

      // Look for the closest parent with a heading level just above the current one
      for (let i = currentLevel - 1; i >= 1; i--) {
        if (lastSeenHeaderIdAtEachLevel[i] !== undefined) {
          parentId = lastSeenHeaderIdAtEachLevel[i];
          break;
        }
      }

      // Update the last seen header id for this level
      lastSeenHeaderIdAtEachLevel[currentLevel] = heading.id;

      // Reset any lower levels since we've started a new section
      for (let i = currentLevel + 1; i <= 6; i++) {
        lastSeenHeaderIdAtEachLevel[i] = null;
      }

      return {
        ...heading,
        parent: parentId,
      };
    })
    .slice(0, 29); // Limit to 30 headings (including title)
};

export const OutlineStep = ({
  setInstructions,
  title,
  globalInstructions,
  setGlobalInstructions,
  setIsGeneratingInstructions,
  headings,
  setModifiedHeadings,
  selectedLanguage,
  selectedTopics,
  urls,
  isSticky,
  containerRef,
}) => {
  const [localHeadings, setLocalHeadings] = useState(
    () => headings.slice(0, 29) || []
  );
  const [shouldTriggerGeneration, setShouldTriggerGeneration] = useState(false);
  const outlineRef = useRef(null);

  useEffect(() => {
    setInstructions((currentInstructions) => {
      const newInstructions = localHeadings.map((heading) => {
        // Use the parsed instructions directly from the heading
        return {
          heading: heading.header,
          instructions: heading.instructions || [""],
        };
      });

      return newInstructions;
    });
  }, [localHeadings]);

  useEffect(() => {
    const headingsWithParentsAndInstructions = addParentToHeadings(
      headings
    ).map((heading) => {
      // Parse instructions if present, otherwise default to an empty array
      const instructions = heading.instruction
        ? JSON.parse(heading.instruction)
        : [];
      return { ...heading, instructions };
    });
    setLocalHeadings(headingsWithParentsAndInstructions);
  }, [headings]);

  // This effect watches for external triggers to generate instructions
  useEffect(() => {
    const generateInstructionsTrigger = (event) => {
      if (event && event.detail && event.detail.trigger === true) {
        setShouldTriggerGeneration(true);
      }
    };

    // Add event listener for external triggers
    window.addEventListener(
      "generateInstructions",
      generateInstructionsTrigger
    );

    // Clean up
    return () => {
      window.removeEventListener(
        "generateInstructions",
        generateInstructionsTrigger
      );
    };
  }, []);

  // Handle the generation process when the button in AiDocumentWizardForm is clicked
  useEffect(() => {
    if (shouldTriggerGeneration && outlineRef.current) {
      // Find and trigger the generation button in the Outline component
      const generationButton = outlineRef.current.querySelector(
        'button[data-action="generate-instructions"]'
      );
      if (generationButton) {
        generationButton.click();
      }
      setShouldTriggerGeneration(false);
    }
  }, [shouldTriggerGeneration]);

  return (
    <div className="flex flex-col items-center justify-between p-4 h-full w-full">
      <div className="flex flex-col justify-between w-[600px] max-w-[700px] mx-auto">
        <h2 className="text-xl font-semibold text-zinc-800 dark:text-zinc-100">
          Outline & Instructions
        </h2>
        <p className="text-sm my-4 font-normal text-zinc-600 dark:text-zinc-300">
          Content structure is critical for optimizing your content. Craft an
          outline with clear headings and sub-headings to ensure smooth
          readability. Include specific instructions under each heading to guide
          Frase's AI in creating content tailored to your requirements.
        </p>
      </div>
      <div className="flex w-full h-full pb-20" ref={outlineRef}>
        <Outline
          panelWidth={300}
          variant="wizard"
          headings={localHeadings}
          onSelectedAssetsChange={(assets) => {
            setModifiedHeadings(assets);
          }}
          title={title}
          selectedLanguage={selectedLanguage}
          topics={selectedTopics}
          urls={urls}
          global_instructions={globalInstructions}
          setIsGeneratingInstructions={setIsGeneratingInstructions}
          isSticky={isSticky}
          containerRef={containerRef}
        />
      </div>
    </div>
  );
};
