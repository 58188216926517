import { useIsDarkMode } from "@/utils/isDarkMode";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useRef, useState } from "react";
import { FraseDocument } from "../../../../features/documents";
import { useEditorStore } from "../../../../stores/editor";
import { useSerpStore } from "../../../../stores/serp";
import { useTopicsStore } from "../../../../stores/topics";
import { useActiveTab } from "../../hooks/useActiveTab";

const styles = `
  ::highlight(topic-highlight-amber) {
    background-color: #fef3c7;
    border-bottom: 2px solid #f59e0b;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #f59e0b;
  }
  ::highlight(topic-highlight-emerald) {
    background-color: #d1fae5;
    border-bottom: 2px solid #10b981;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #10b981;
  }
  ::highlight(topic-highlight-rose) {
    background-color: #fef2f2;
    border-bottom: 2px solid #f43f5e;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #f43f5e;
  }
  ::highlight(topic-highlight-zinc) {
    background-color: #f4f4f5;
    border-bottom: 2px solid #a1a1aa;
    cursor: pointer;    
    text-decoration: underline;
    text-decoration-color: currentColor;
  }
  .dark ::highlight(topic-highlight-amber) {
    background-color: rgba(250, 204, 21, 0.4);
    border-bottom: 2px solid #f59e0b;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #f59e0b;
  }
  .dark ::highlight(topic-highlight-emerald) {
    background-color: rgba(6, 95, 70, 0.5);
    border-bottom: 2px solid #10b981;
    color: white;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #10b981;
  }
  .dark ::highlight(topic-highlight-rose) {
    background-color: rgba(159, 18, 57, 0.4);
    border-bottom: 2px solid #fb7185;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #fb7185;
  }
  .dark ::highlight(topic-highlight-zinc) {
    background-color: #3f3f46;
    border-bottom: 2px solid #27272a;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #27272a;
  }
`;

interface TopicRange extends Range {
  topic: {
    user_count: number;
    frequency: number;
    bg_color: string;
  };
}

const DEFAULT_DELAY_DURATION = 700;

export function TopicHighlightPlugin({
  fraseDocument,
}: {
  fraseDocument: FraseDocument;
}) {
  const [editor] = useLexicalComposerContext();
  const docId = fraseDocument.id;
  const { topics: topicsStore } = useTopicsStore();
  const { editor: editorStore } = useEditorStore();
  const { serp } = useSerpStore();
  const { activeTab } = useActiveTab();
  const { topics } = topicsStore[docId] || { topics: [] };
  const { highlightTopics } = editorStore;
  const isDarkMode = useIsDarkMode();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const mentionsRef = useRef({ your: 0, competitor: 0 });
  const activeRangesRef = useRef<TopicRange[]>([]);
  const [isOpenDelayed, setIsOpenDelayed] = useState(true);
  const showTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const skipDelayTimerRef = useRef<NodeJS.Timeout | null>(null);
  const currentRangeRef = useRef<TopicRange | null>(null);

  const highlightTopicsInEditor = useCallback(() => {
    const docSerp = serp[docId];
    const shouldHighlight =
      topics?.length > 0 &&
      highlightTopics &&
      editorStore.optimizeViewVisible &&
      docSerp?.topics?.length > 0 &&
      activeTab === "optimize"; // Only highlight when optimize tab is active

    // Always clear existing highlights first
    CSS.highlights?.clear();

    // Return early if we shouldn't highlight
    if (!shouldHighlight) {
      return;
    }

    const editorElement = editor.getRootElement();
    if (!editorElement) return;

    const ranges: TopicRange[] = [];
    const treeWalker = document.createTreeWalker(
      editorElement,
      NodeFilter.SHOW_TEXT
    );

    // Create a map of terms for O(1) lookup
    const termsMap = new Map(
      topics
        .filter((t) => !t.blacklist)
        .map((t) => [t.sing_lower.toLowerCase(), t])
    );

    let currentNode = treeWalker.nextNode();
    while (currentNode) {
      const text = currentNode.textContent?.toLowerCase() || "";
      termsMap.forEach((topic, term) => {
        const termLower = term.toLowerCase();
        let startPos = 0;
        while (startPos < text.length) {
          const index = text.indexOf(termLower, startPos);
          if (index === -1) break;

          if (currentNode) {
            const range = new Range() as TopicRange;
            range.setStart(currentNode, index);
            range.setEnd(currentNode, index + term.length);
            range.topic = topic;
            ranges.push(range);
          }

          startPos = index + term.length;
        }
      });
      currentNode = treeWalker.nextNode();
    }

    // Store ranges for tooltip handling
    activeRangesRef.current = ranges;

    // Group ranges by color for better performance
    const rangesByColor = new Map<string, Range[]>();
    ranges.forEach((range) => {
      const color = range.topic.bg_color;
      if (!rangesByColor.has(color)) {
        rangesByColor.set(color, []);
      }
      rangesByColor.get(color)?.push(range);
    });

    // Create highlights for each color group
    if (CSS.highlights) {
      rangesByColor.forEach((colorRanges, color) => {
        const highlight = new Highlight(...colorRanges);
        CSS.highlights.set(`topic-highlight-${color}`, highlight);
      });
    }
  }, [
    editor,
    topics,
    highlightTopics,
    editorStore.optimizeViewVisible,
    serp,
    docId,
    activeTab,
  ]);

  const showTooltip = useCallback((range: TopicRange) => {
    const tooltip = tooltipRef.current;
    if (!tooltip) return;

    // Update the content first and store in ref
    mentionsRef.current = {
      your: range.topic.user_count,
      competitor: range.topic.frequency,
    };

    // Update position and show immediately
    const rect = range.getBoundingClientRect();
    tooltip.style.left = `${rect.left + window.scrollX}px`;
    tooltip.style.top = `${rect.bottom + window.scrollY + 4}px`;
    tooltip.style.display = "block";

    // Small delay before showing to ensure smooth transition
    requestAnimationFrame(() => {
      tooltip.style.opacity = "1";
    });

    // Clear skip delay timer when showing tooltip
    if (skipDelayTimerRef.current) {
      clearTimeout(skipDelayTimerRef.current);
      skipDelayTimerRef.current = null;
    }
  }, []);

  const hideTooltip = useCallback(() => {
    const tooltip = tooltipRef.current;
    if (!tooltip) return;

    // Hide immediately
    tooltip.style.opacity = "0";
    tooltip.style.display = "none";

    // Reset the delay state when tooltip is hidden
    setIsOpenDelayed(true);
    currentRangeRef.current = null;

    // Remove the skip delay timer when hiding tooltip
    if (skipDelayTimerRef.current) {
      clearTimeout(skipDelayTimerRef.current);
      skipDelayTimerRef.current = null;
    }
  }, []);

  // Handle topic highlighting
  useEffect(() => {
    // Run highlighting logic whenever conditions change
    highlightTopicsInEditor();

    return () => {
      CSS.highlights?.clear();
    };
  }, [
    editor,
    topics,
    highlightTopics,
    editorStore.optimizeViewVisible,
    highlightTopicsInEditor,
    serp,
    activeTab,
  ]);

  // Reset delay on document change
  useEffect(() => {
    setIsOpenDelayed(true);
    return () => {
      setIsOpenDelayed(true);
    };
  }, [fraseDocument.id]);

  // Handle mouse events for tooltips
  useEffect(() => {
    if (!editor.getRootElement()) return;

    const handleMouseMove = (e: MouseEvent) => {
      const activeRange = activeRangesRef.current.find((range) => {
        const rect = range.getBoundingClientRect();
        return (
          e.clientX >= rect.left &&
          e.clientX <= rect.right &&
          e.clientY >= rect.top &&
          e.clientY <= rect.bottom
        );
      });

      if (activeRange) {
        // Mouse entered a new range
        if (currentRangeRef.current !== activeRange) {
          // Clear any existing timeouts
          if (hideTimeoutRef.current) {
            clearTimeout(hideTimeoutRef.current);
            hideTimeoutRef.current = null;
          }

          if (showTimeoutRef.current) {
            clearTimeout(showTimeoutRef.current);
          }

          if (isOpenDelayed) {
            showTimeoutRef.current = setTimeout(() => {
              showTooltip(activeRange);
              setIsOpenDelayed(false);
              showTimeoutRef.current = null;
            }, DEFAULT_DELAY_DURATION);
          } else {
            showTooltip(activeRange);
          }

          currentRangeRef.current = activeRange;
        }
      } else if (currentRangeRef.current) {
        // Mouse left the current range
        if (showTimeoutRef.current) {
          clearTimeout(showTimeoutRef.current);
          showTimeoutRef.current = null;
        }

        hideTimeoutRef.current = setTimeout(() => {
          hideTooltip();
          hideTimeoutRef.current = null;
        }, 200);

        currentRangeRef.current = null;
      }
    };

    const root = editor.getRootElement();
    root?.addEventListener("mousemove", handleMouseMove);

    return () => {
      root?.removeEventListener("mousemove", handleMouseMove);
      if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current);
      if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current);
      if (skipDelayTimerRef.current) clearTimeout(skipDelayTimerRef.current);
    };
  }, [editor, isOpenDelayed, showTooltip, hideTooltip]);

  // Add styles to document
  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <div
      ref={tooltipRef}
      className="absolute z-[57] hidden shadow-glowLg dark:shadow-lg overflow-hidden font-normal px-2 py-1 text-xs text-zinc-900 dark:text-white rounded-md backdrop-blur-lg bg-white/80 dark:bg-zinc-900/50 ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5"
    >
      Your Mentions: {mentionsRef.current.your} / Competitor Mentions:{" "}
      {mentionsRef.current.competitor}
    </div>
  );
}
