import { motion } from "framer-motion";
import {
  NavLink as Link,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";

import {
  AvatarMenu,
  Button,
  NewDocumentButton,
  NewDocumentButtonAction,
  SearchButton,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";
import { useNavigationStore } from "@/components/Layout/MobileNavigation";
import { useUser } from "@/features/auth";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useGetChannelsForUser } from "@/features/documents/api/channels";
import { useDocuments } from "@/features/documents/api/getDocuments";
import { useHelpCenterStore } from "@/features/help/components/HelpCenterDialog";
import { useTemplatesForOrg } from "@/features/templates/api/getTemplates";
import { useIsDocumentRoute } from "@/hooks/useIsDocumentRoute";
import { FeatureFlag, isFeatureEnabled } from "@/utils/featureFlags";
import { cn } from "@/utils/style";
import { DocumentIcon as DocumentIconOutline } from "@heroicons/react/24/outline";
import {
  ArchiveBoxIcon,
  ChartBarIcon,
  DocumentIcon,
  FolderIcon,
} from "@heroicons/react/24/solid";

import { useMemo, useRef, useState } from "react";
import {
  TbBolt,
  TbCircleArrowUpFilled,
  TbLayoutGrid,
  TbMessage,
  TbPlug,
  TbSparkles,
  TbTrendingUp,
} from "react-icons/tb";
import { useCreateFolder } from "../../features/documents/api/createFolder";
import { FolderInput, FolderItem } from "../../features/folders";
import { ShortcutTag } from "../Elements/Tag/ShortcutTag";
import { IntegrationsDialog } from "./IntegrationsDialog";

interface BeamerConfig {}

declare var beamer_config: BeamerConfig;
declare var Beamer: any;

export const NavLink = ({
  href,
  children,
  className,
  target,
  variant,
  Logo,
  badge,
  onClick,
}) => {
  const { openHelpCenter } = useHelpCenterStore();
  const location = useLocation();
  const active = href === location.pathname;

  return (
    <Link
      to={href}
      target={target === "_blank" ? "_blank" : undefined}
      aria-current={active ? "page" : undefined}
      id={children === "Updates" ? "beamer-button" : undefined}
      onClick={(e) => {
        e.stopPropagation();

        if (children === "Updates") {
          if (
            typeof beamer_config != "undefined" &&
            typeof Beamer != "undefined"
          ) {
            Beamer.show();
          }
          e.preventDefault();
        }

        if (children === "Help & Support") {
          openHelpCenter();
          e.preventDefault();
        }

        if (onClick) {
          onClick(e);
        }
      }}
      className={cn(
        "flex items-center gap-2 py-1 ml-2 text-xs w-full group-hover:cursor-pointer",
        className
      )}
    >
      {Logo && (
        <Logo
          className={cn(
            "w-3.5 h-3.5 flex-shrink-0 text-zinc-600 dark:text-zinc-400",
            variant === "primary"
              ? "fill-zinc-600 dark:fill-zinc-400"
              : "fill-transparent dark:fill-transparent"
          )}
        />
      )}
      <span
        className={cn(
          "text-xs font-medium truncate",
          variant === "secondary" &&
            "font-medium text-zinc-800 dark:text-zinc-200"
        )}
      >
        {children}
      </span>
      {badge && (
        <div className="flex items-center font-semibold text-[10px] bg-emerald-500/20 text-emerald-800 px-1.5 rounded dark:bg-emerald-900 dark:text-emerald-200 h-5 leading-none">
          {badge.toUpperCase()}
        </div>
      )}
    </Link>
  );
};

export const NavigationGroup = ({
  group,
  className,
  collapsible,
  variant,
  onClick,
}: {
  group: {
    title: string;
    href: string;
    logo: any;
    links?: any[];
    target?: string;
    component?: any;
    badge: string;
  };
  className?: string;
  collapsible?: boolean;
  variant?: "primary" | "secondary";
  onClick?: (e: any) => void;
}) => {
  const location = useLocation();
  const viewActive = group.href === location.pathname;

  return (
    <div
      className={cn(
        "flex-col items-center rounded-md group ml-3",
        viewActive
          ? "bg-zinc-900/5 dark:bg-zinc-700 "
          : " hover:bg-zinc-900/5 dark:hover:bg-white/5"
      )}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center w-full">
          <h2
            className={cn(
              "flex text-xs text-zinc-900 dark:text-white mr-2 w-full group"
            )}
          >
            <NavLink
              {...group}
              className={className}
              variant={variant}
              Logo={group.logo}
              badge={group.badge}
              onClick={onClick}
            >
              {group.title}
            </NavLink>
          </h2>
        </div>
      </div>
    </div>
  );
};

const MAX_NAV_WIDTH = 320;
const DEFAULT_NAV_WIDTH = 220;
const CLOSE_ACTION_WIDTH = 50;

export function Navigation(props) {
  const [width, setWidth] = useState(DEFAULT_NAV_WIDTH);
  const [isResizing, setIsResizing] = useState(false);
  const [hasMoved, setHasMoved] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [closedDueToOnboarding, setClosedDueToOnboarding] = useState(false);
  const [folderName, setFolderName] = useState("");
  const { isNavOpen, closeNav, isPanelOpen, closePanel, openNav } =
    useNavigationStore();
  const isDocumentRoute = useIsDocumentRoute();
  const isTemplatesRoute =
    useMatch("/app/templates/:templateHash")?.pattern.path ===
    "/app/templates/:templateHash";
  const { data: user, isLoading } = useUser();
  const isAdmin = user?.org_role === 1;
  const userId = user?.id;
  const { data: subscriptionData } = useSubscription({});
  const hasCompletedOnboarding = user?.onBoarded;
  const isUserVerified = user?.verified;
  const scrollDivRef = useRef(null);
  const [isIntegrationsDialogOpen, setIntegrationsDialogOpen] = useState(false);

  const showBorder = useMemo(() => {
    const current = scrollDivRef.current;
    return current ? current.scrollHeight > current.clientHeight : false;
  }, [scrollDivRef.current?.scrollHeight, scrollDivRef.current?.clientHeight]);

  const documentsQuery = useDocuments({
    org_id: user?.orgId || null,
    user_id: isAdmin ? null : userId,
    size: 20,
    page: 1,
    config: {
      enabled: user?.orgId !== undefined,
    },
  });

  const templatesQuery = useTemplatesForOrg({
    org_id: user?.orgId.toString() || "",
    config: {
      enabled: isTemplatesRoute,
    },
  });

  const createFolderMutation = useCreateFolder({});
  const channelsQuery = useGetChannelsForUser({});
  const isSubscriptionInactive = subscriptionData?.status === "inactive";
  const navigate = useNavigate();

  const channelRoutes = channelsQuery.data?.map((channel) => ({
    id: channel?.id,
    title: channel?.name,
    href: `/app/folders/${channel?.id}`,
    logo: FolderIcon,
  }));

  const handleIntegrationsClick = () => {
    setIntegrationsDialogOpen(true);
  };

  const handleFolderOperation = (operation: string) => {
    if (folderName !== "") {
      if (operation === "create") {
        createFolderMutation.mutate({ name: folderName });
        setShowInput(false);
      }
    }
  };

  const navigation = [
    {
      title: "Documents",
      href: "/app/documents",
      logo: DocumentIcon,
      links: channelRoutes,
    },
    {
      title: "Templates",
      href: "/app/templates",
      logo: TbLayoutGrid,
      links: [],
    },
    {
      title: "AI Tools",
      href: "/app/ai-tools",
      logo: TbSparkles,
      active: false,
      links: [],
    },
    {
      title: "SEO Analytics",
      href: "/app/seo-analytics",
      logo: ChartBarIcon,
      links: [],
    },
  ];

  if (isFeatureEnabled(FeatureFlag.OPPORTUNITY_DASHBOARD)) {
    navigation.push({
      title: "Content Opportunities",
      href: "/app/content-opportunities",
      logo: TbTrendingUp,
      links: [],
    });
  }

  const secondaryNavigation = [
    {
      title: "Integrations",
      href: "#",
      logo: TbPlug,
      badge: "New",
    },
    {
      title: "Updates",
      href: "/app/settings",
      logo: TbBolt,
    },
    {
      title: "Help & Support",
      href: "/app/help",
      logo: TbMessage,
    },
  ];

  const handleResize = (e) => {
    setIsResizing(true);
    setHasMoved(true);

    if (e.clientX >= DEFAULT_NAV_WIDTH && e.clientX <= MAX_NAV_WIDTH) {
      setWidth(e.clientX);
    }

    if (e.clientX > MAX_NAV_WIDTH) {
      setWidth(MAX_NAV_WIDTH);
    }

    if (e.clientX < DEFAULT_NAV_WIDTH && e.clientX > CLOSE_ACTION_WIDTH) {
      setWidth(DEFAULT_NAV_WIDTH);
    }

    if (e.clientX < DEFAULT_NAV_WIDTH && e.clientX < CLOSE_ACTION_WIDTH) {
      setWidth(DEFAULT_NAV_WIDTH);
      handleResizeEnd();
      closeNav();
    }
  };

  const handleResizeEnd = () => {
    document.removeEventListener("mousemove", handleResize);
    document.removeEventListener("mouseup", handleResizeEnd);
    setTimeout(() => {
      setIsResizing(false);
      setHasMoved(false);
    }, 100);
  };

  const handleResizeStart = () => {
    document.addEventListener("mousemove", handleResize);
    document.addEventListener("mouseup", handleResizeEnd);
  };

  const navVariants = {
    open: {
      x: 0,
      zIndex: 9999,
      width: width,
      opacity: 1,
      display: "flex",
      transition: {
        type: "easeOut",
        duration: 0.2,
        delay: 0,
      },
    },
    closed: {
      x: "-100%",
      zIndex: -50,
      width: 0,
      opacity: 0,
      transition: {
        type: "easeOut",
        duration: 0.2,
        delay: 0,
      },
      transitionEnd: {
        display: "none",
      },
    },
    isResizing: {
      x: 0,
      width: width - 1,
      opacity: 1,
      zIndex: 50,
      transition: {
        type: "easeOut",
        duration: 0,
        delay: 0,
      },
    },
  };

  const panelVariants = {
    panelOpen: {
      x: 8,
      position: "fixed",
      zIndex: 50,
      width: width,
      opacity: 100,
      transition: {
        type: "easeOut",
        duration: 0.2,
      },
    },
    panelClosed: {
      x: "-100%",
      position: "fixed",
      zIndex: 10,
      width: width,
      opacity: 100,
      transition: {
        type: "easeOut",
        duration: 0.2,
      },
    },
  };

  function UpgradeBadge() {
    return (
      <div className="flex flex-col justify-center border border-emerald-600/40 dark:border-emerald-700/40 rounded-md p-3 mb-4 mx-3 bg-hero-gradient-linear shadow-sm dark:shadow-lg">
        <div className="flex text-sm font-medium text-zinc-800 dark:text-zinc-200 mb-1">
          <TbCircleArrowUpFilled className="h-4 w-4 mr-2 mt-1 text-emerald-600" />
          <span>Upgrade plan</span>
        </div>
        <p className="text-xs text-zinc-600 dark:text-zinc-400 mb-1">
          Create more documents with Frase's AI & SEO tools.
        </p>
        <Button
          variant="aiBlur"
          className="text-xs font-bold w-full"
          onClick={() => {
            navigate("/app/settings/plans");
          }}
        >
          Upgrade
        </Button>
      </div>
    );
  }

  const renderSecondaryActions = () => {
    return (
      <div
        className={cn(
          "mt-auto pt-3",
          showBorder && "border-t border-zinc-200 dark:border-zinc-700"
        )}
      >
        {isSubscriptionInactive && <UpgradeBadge />}

        <div className="flex flex-col mr-3 mb-3">
          {secondaryNavigation.map((group, groupIndex) => (
            <NavigationGroup
              key={groupIndex}
              group={group}
              variant="secondary"
              onClick={
                group.title === "Integrations"
                  ? handleIntegrationsClick
                  : undefined
              }
            />
          ))}
        </div>
      </div>
    );
  };

  const renderNavigationContent = () => (
    <>
      <div className="pt-4">
        <AvatarMenu fullName={user?.fullName} isLoading={isLoading} />
      </div>

      <div className="flex justify-between gap-2 pt-3 px-3 w-full">
        <NewDocumentButton
          trigger={
            <NewDocumentButtonAction
              className="w-full"
              buttonVariant={"outlineBlur"}
            />
          }
        />
        <SearchButton disableKeyEvent />
      </div>

      <div
        ref={scrollDivRef}
        className="mt-3 flex flex-col h-full justify-between overflow-y-scroll overflow-x-hidden"
      >
        <div>
          <div className="space-y-[1px]">
            {navigation.map((group, groupIndex) => (
              <NavigationGroup
                key={groupIndex}
                group={group}
                variant="primary"
              />
            ))}
          </div>
          {isDocumentRoute ? (
            <div className="pt-4 overflow-ellipsis">
              <p className="text-2xs text-zinc-500 font-medium pl-5 mb-1">
                Recent documents
              </p>
              {documentsQuery.data?.documents?.map((document) => (
                <NavigationGroup
                  key={document.hash}
                  collapsible={false}
                  group={{
                    title: document.metadata?.name || document.query,
                    href: `/app/documents/${document.hash}`,
                    logo: DocumentIconOutline,
                  }}
                  className="overflow-ellipsis whitespace-nowrap overflow-hidden w-[80%] group-hover:text-zinc-900 dark:group-hover:text-white"
                  variant="secondary"
                />
              ))}
            </div>
          ) : isTemplatesRoute ? (
            <div className="pt-4 overflow-ellipsis">
              <p className="text-2xs text-zinc-500 font-medium pl-5 mb-1">
                Recent templates
              </p>
              {templatesQuery.data?.map((template) => (
                <NavigationGroup
                  key={template.id}
                  collapsible={false}
                  group={{
                    title: template.text[0].title,
                    href: `/app/templates/${template.hash}`,
                    logo: DocumentIconOutline,
                  }}
                  className="overflow-ellipsis whitespace-nowrap overflow-hidden w-[80%] group-hover:text-zinc-900 dark:group-hover:text-white"
                  variant="secondary"
                />
              ))}
            </div>
          ) : (
            <div className="pt-4 overflow-ellipsis space-y-[1px]">
              <p className="text-2xs text-zinc-500 font-medium pl-5">
                Your folders
              </p>
              <div className="ml-2">
                <FolderInput />
              </div>

              <div className="ml-2">
                <FolderItem
                  id={"archive"}
                  title={"Archive"}
                  key={"archive"}
                  link={{
                    title: "Archive",
                    href: "/app/folders/archive",
                    logo: ArchiveBoxIcon,
                  }}
                  folderActive={location.pathname === "/app/folders/archive"}
                  hideDropdown={true}
                />
              </div>
              <div className="ml-2 flex flex-col space-y-[1px]">
                {channelRoutes?.map((link) => (
                  <FolderItem
                    id={link.id}
                    title={link.title}
                    key={link.href}
                    link={link}
                    folderActive={link.href === location.pathname}
                    hideDropdown={false}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {renderSecondaryActions()}

      {!isPanelOpen && (
        <TooltipProvider delayDuration={500} skipDelayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div
                className={cn(
                  "absolute h-screen top-0 bottom-0 after:absolute after:h-screen after:top-0 after:bottom-0",
                  "w-[8px] after:w-[1px] hover:after:w-[2px] active:after:w-[2px] hover:after:z-[99999] active:after:z-[99999]",
                  "select-none cursor-col-resize after:cursor-col-resize after:select-none",
                  "after:bg-zinc-400 dark:after:bg-zinc-600 hover:after:bg-zinc-400 dark:hover:after:bg-zinc-500 active:after:bg-zinc-400 dark:active:after:bg-zinc-500",
                  "after:opacity-30 hover:after:opacity-100 active:after:opacity-100"
                )}
                style={{
                  left: width - 1,
                  zIndex: 0,
                }}
                onMouseDown={handleResizeStart}
                onMouseUp={handleResizeEnd}
                onClick={() => {
                  if (!isResizing && !hasMoved) {
                    closeNav();
                  }
                }}
              />
            </TooltipTrigger>
            <TooltipContent side={"right"}>
              <div className="flex flex-col p-1">
                <span>
                  <strong>Drag</strong> to resize
                </span>
                <span>
                  <strong>Click</strong> to collapse
                  <ShortcutTag>{"["}</ShortcutTag>
                </span>
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </>
  );

  const renderNavigation = (isPanel) => (
    <motion.div
      {...props}
      variants={isPanel ? panelVariants : navVariants}
      animate={() => {
        if (isResizing) return "isResizing";
        return isPanel
          ? isPanelOpen
            ? "panelOpen"
            : "panelClosed"
          : isNavOpen
          ? "open"
          : "closed";
      }}
      className={cn(
        "flex flex-col h-full bg-zinc-50 dark:bg-zinc-800 z-[0]",
        isPanel &&
          "mt-14 rounded-md backdrop-blur-lg shadow-glowLg dark:shadow-xl ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none bg-zinc-50 dark:bg-zinc-800"
      )}
      style={{ width }}
      onMouseLeave={isPanel ? closePanel : undefined}
    >
      {renderNavigationContent()}
    </motion.div>
  );

  return (
    <>
      {renderNavigation(false)}
      {renderNavigation(true)}
      <IntegrationsDialog
        isOpen={isIntegrationsDialogOpen}
        onClose={() => setIntegrationsDialogOpen(false)}
      />
    </>
  );
}
