import { axios } from "@/lib/axios";
import { useMutation, useQueryClient } from "react-query";

export type ConsumeArticleCreditParams = {
  articleId: number;
};

export type ConsumeArticleCreditResponse = {
  success: boolean;
  message: string;
};

export const consumeArticleCredit = async ({
  articleId,
}: ConsumeArticleCreditParams): Promise<ConsumeArticleCreditResponse> => {
  try {
    const { data } = await axios.post("/consumeArticleCredit", { articleId });
    return data;
  } catch (error) {
    console.error("Error consuming article credit:", error);
    throw error;
  }
};

export const useConsumeArticleCredit = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: ConsumeArticleCreditParams) => consumeArticleCredit(params),
    {
      // When the mutation is successful, invalidate relevant queries
      onSuccess: (data, variables) => {
        // Invalidate the article data to reflect full access
        queryClient.invalidateQueries([
          "fetchGptArticle",
          { id: variables.articleId },
        ]);

        // Invalidate subscription data to update credit count
        queryClient.invalidateQueries(["subscription"]);

        // Invalidate aiArticleUsage to update article usage stats
        queryClient.invalidateQueries("aiArticleUsage");
      },
    }
  );
};
