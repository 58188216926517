import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { FraseDocument } from "../types";

export interface BingSearchQuery {
  query: string;
  country: string | null;
  count: number;
  type: string;
}

export interface BingSearchValue {
  displayUrl: string;
  snippet: string;
  name: string;
  dateLastCrawled: string;
  id: string;
  url: string;
}

export interface BingSearchWebPages {
  totalEstimatedMatches: number;
  webSearchUrl: string;
  value: BingSearchValue[];
}

export interface BingSearchResult {
  id: any;
  url: BingSearchResult;
  name: any;
  title: any;
  datePublished: any;
  date_created: any;
  snippet: string;
  value: any;
  webPages: BingSearchWebPages;
  rankingResponse: Record<string, unknown>;
  _type: string;
}

export interface ProcessedBingSearchResult {
  url: string;
  title: string;
  description: string;
}

type QueryKey = string;

export type BingSearchResults = {
  [key in QueryKey]: BingSearchResult;
};

export const getBingSearchResults = ({
  queries,
}: {
  queries: BingSearchQuery[];
}): Promise<BingSearchResults> => {
  return axios.post(`/getBingQuery`, {
    queries: queries,
  });
};

type QueryFnType = typeof getBingSearchResults;

type UseBingSearchResultsOptions = {
  queries: BingSearchQuery[];
  config?: QueryConfig<QueryFnType>;
};

export const useBingSearchResults = ({
  queries,
  config,
}: UseBingSearchResultsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["bingSearchResults"],
    queryFn: () => getBingSearchResults({ queries }),
  });
};
