import { Badge } from "@/components/Elements/Badge";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/Elements/Tabs";
import { useState } from "react";

/**
 * Represents a single tab item with its content
 */
interface TabItem {
  value: string;
  label: string;
  icon: React.ReactNode;
  count?: number;
  content: React.ReactNode;
  badgeColor?: "red" | "amber" | "green" | "blue" | "info";
}

/**
 * Props for the TabContainer component
 */
interface TabContainerProps {
  defaultValue: string;
  value?: string;
  onValueChange?: (value: string) => void;
  tabs: TabItem[];
  compact?: boolean;
}

/**
 * A container component that renders a set of tabs with their content
 * Each tab can have an icon, label, and optional count badge
 */
export default function TabContainer({
  defaultValue,
  value,
  onValueChange,
  tabs,
  compact = false,
}: TabContainerProps) {
  // Add state to track the current tab value
  const [internalValue, setInternalValue] = useState(defaultValue);

  // Use controlled or uncontrolled value
  const currentTab = value !== undefined ? value : internalValue;

  // Handle value change
  const handleValueChange = (newValue: string) => {
    setInternalValue(newValue);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  // Helper function to get badge color classes
  const getBadgeColorClasses = (color: TabItem["badgeColor"] = "info") => {
    switch (color) {
      case "red":
        return "bg-red-100 dark:bg-red-900/30 text-red-600 dark:text-red-400";
      case "amber":
        return "bg-amber-100 dark:bg-amber-900/30 text-amber-600 dark:text-amber-400";
      case "green":
        return "bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400";
      case "blue":
        return "bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400";
      default:
        return ""; // Default badge styling will be used
    }
  };

  return (
    <Tabs
      value={currentTab}
      onValueChange={handleValueChange}
      className="flex flex-col gap-4"
    >
      <TabsList>
        {tabs.map((tab) => (
          <TabsTrigger
            key={tab.value}
            value={tab.value}
            className={`flex items-center ${
              compact ? "gap-1 py-0.5 px-2" : "gap-2"
            }`}
          >
            {tab.icon}
            <span>{tab.label}</span>
            {tab.count !== undefined && (
              <Badge
                variant={tab.badgeColor === "info" ? "info" : "default"}
                className={`${
                  compact ? "ml-0.5 text-2xs px-1" : "ml-1 text-xs px-1.5"
                } rounded-full ${getBadgeColorClasses(tab.badgeColor)}`}
              >
                {tab.count}
              </Badge>
            )}
          </TabsTrigger>
        ))}
      </TabsList>

      {tabs.map((tab) => (
        <TabsContent
          key={tab.value}
          value={tab.value}
          className="!h-auto !pb-4 !overflow-y-visible" // Override problematic styles with !important
        >
          {tab.content}
        </TabsContent>
      ))}
    </Tabs>
  );
}
