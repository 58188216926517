import { Button } from "@/components/Elements";
import { ConfirmationDialog } from "@/components/Elements/ConfirmationDialog";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { getSubscription } from "@/features/auth/api/getSubscription";
import { getTierByPlanName } from "@/features/subscription/utils/tiers";
import { queryClient } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";
import { loadStripe } from "@stripe/stripe-js";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateUserSubscription } from "../api/updateUserSubscription";

interface SubscriptionChangeDialogProps {
  isOpen: boolean;
  close: () => void;
  open: () => void;
  title: string;
  description: string | React.ReactNode;
  confirmButtonText: string;
  plan: {
    stripeName: string;
    displayName?: string;
  };
  hasAddOn?: boolean;
  pricingPeriod?: "monthly" | "yearly";
}

export const SubscriptionChangeDialog = ({
  isOpen,
  close,
  open,
  title,
  description,
  confirmButtonText,
  plan,
  hasAddOn = false,
  pricingPeriod,
}: SubscriptionChangeDialogProps) => {
  const updateSubscriptionMutation = useUpdateUserSubscription();
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const [hasTracked, setHasTracked] = useState(false);
  const trackEvent = useTrackEvent();

  const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

  const handleConfirm = async () => {
    try {
      setIsLoading(true);

      // Get initial subscription info to compare later
      const initialSubscription = await getSubscription();
      const isInitialState =
        initialSubscription.plan === plan.stripeName &&
        initialSubscription.add_on === hasAddOn;

      const plans = [{ plan: plan.stripeName }];
      if (hasAddOn) {
        plans.push({
          plan: pricingPeriod === "monthly" ? "addon" : "addon_yearly_357",
        });
      }

      // First attempt the subscription update
      const updateData = await updateSubscriptionMutation.mutateAsync({
        plans,
      });

      let attemptedPayment = false;

      // Check if we need to handle payment authentication from the update response
      if (updateData.requires_payment) {
        if (updateData.client_secret) {
          attemptedPayment = true;
          const stripe = await stripePromise;
          if (!stripe) {
            throw new Error("Stripe failed to load");
          }

          const { error } = await stripe.confirmCardPayment(
            updateData.client_secret
          );
          if (error) {
            console.error("Payment authentication failed:", error);
          }
        } else {
          setIsLoading(false);
          addNotification({
            type: "error",
            title: "Payment Required",
            message:
              "Your payment method was declined. Please update your payment information to continue with the subscription change.",
          });
          return;
        }
      }

      // Poll for subscription status to confirm the update
      const pollInterval = 1000; // 1 second
      const maxAttempts = 30; // 30 seconds total
      let attempts = 0;

      while (attempts < maxAttempts) {
        const subscriptionData = await getSubscription();

        const isDesiredState =
          subscriptionData.plan === plan.stripeName &&
          subscriptionData.add_on === hasAddOn;

        // If subscription is in desired state and not in a pending state, we're done
        if (
          isDesiredState &&
          subscriptionData.status !== "past_due" &&
          subscriptionData.status !== "pending_authentication" &&
          subscriptionData.status !== "incomplete" &&
          !isInitialState // Only show success if this wasn't the initial state
        ) {
          const currentTier = getTierByPlanName(plan.stripeName);
          const initialTier = getTierByPlanName(initialSubscription.plan);

          // Check if this is just a billing period change
          const wasMonthly = initialSubscription.plan.includes('_monthly_');
          const isMonthly = plan.stripeName.includes('_monthly_');
          const isOnlyBillingPeriodChange = wasMonthly !== isMonthly && 
            currentTier?.name === initialTier?.name;

          if (isOnlyBillingPeriodChange) {
            // Get prices using their respective periods
            const currentPrice = currentTier?.[isMonthly ? 'monthly' : 'yearly']?.price || 0;
            const previousPrice = initialTier?.[wasMonthly ? 'monthly' : 'yearly']?.price || 0;

            // For billing period changes, yearly is always considered an upgrade
            const isUpgrade = !isMonthly; // true if switching to yearly, false if switching to monthly

            await trackEvent.mutate({
              event: isUpgrade ? "billing_period_upgrade" : "billing_period_downgrade",
              properties: JSON.stringify({
                current_plan: plan.stripeName,
                previous_plan: initialSubscription.plan,
                current_price: currentPrice,
                previous_price: previousPrice
              }),
            });
          } else {
            // For regular plan changes, use the same period for fair comparison
            const currentPrice = currentTier?.[pricingPeriod || "monthly"]?.price || 0;
            const initialPrice = initialTier?.[pricingPeriod || "monthly"]?.price || 0;

            await trackEvent.mutate({
              event: currentPrice > initialPrice ? "customer_upgrade" : "customer_downgrade",
              properties: JSON.stringify({
                current_plan: plan.stripeName,
                previous_plan: initialSubscription.plan,
                current_price: currentPrice,
                previous_price: initialPrice
              }),
            });
          }

          // Success case
          addNotification({
            type: "success",
            title: "Success",
            message: "Your subscription has been updated successfully.",
          });

          queryClient.invalidateQueries("subscription");
          close();
          setIsLoading(false);
          return;
        }

        // Only show payment failed if we actually attempted a payment and we're not in pending_authentication
        if (
          attemptedPayment &&
          subscriptionData.status !== "pending_authentication" &&
          !isDesiredState
        ) {
          setIsLoading(false);
          addNotification({
            type: "error",
            title: "Payment Failed",
            message:
              "Please update your payment information to continue with the subscription change.",
          });
          return;
        }

        // Wait before next poll
        await new Promise((resolve) => setTimeout(resolve, pollInterval));
        attempts++;
      }

      // If we reach here, polling timed out
      if (attempts >= maxAttempts) {
        setIsLoading(false);
        addNotification({
          type: "error",
          title: "Change timeout",
          message: "The process took too long. Please refresh and try again.",
        });
        throw new Error("Process timed out");
      }
    } catch (error) {
      console.error("Subscription change failed:", error);
      setIsLoading(false);
      addNotification({
        type: "error",
        title: "Error",
        message:
          "Failed to update subscription. Please try again or contact support.",
      });
    }
  };

  const handleTrackPopup = async () => {
    if (!hasTracked) {
      const subscriptionData = await getSubscription();
      if (!subscriptionData) return;

      const currentTier = getTierByPlanName(subscriptionData.plan);
      const targetTier = getTierByPlanName(plan.stripeName);
      const currentPrice = currentTier?.[pricingPeriod || "monthly"]?.price || 0;
      const targetPrice = targetTier?.[pricingPeriod || "monthly"]?.price || 0;

      // Check if this is just a billing period change by comparing the plan names
      const isCurrentMonthly = subscriptionData.plan.includes('_monthly_');
      const isTargetMonthly = plan.stripeName.includes('_monthly_');
      const isOnlyBillingPeriodChange = isCurrentMonthly !== isTargetMonthly && 
        currentTier?.name === targetTier?.name;

      trackEvent.mutate({
        event: isOnlyBillingPeriodChange ? "billing_period_popup" : (targetPrice > currentPrice ? "upgrade_popup" : "downgrade_popup"),
        properties: JSON.stringify({
          current_plan: subscriptionData.plan,
          target_plan: plan.stripeName,
          current_price: currentPrice,
          target_price: targetPrice
        }),
      });
      setHasTracked(true);
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleTrackPopup();
    } else {
      setHasTracked(false);
    }
  }, [isOpen]);

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      close={() => {
        if (!isLoading) {
          queryClient.invalidateQueries(["subscription"]);
          close();
        }
      }}
      open={open}
      title={title}
      body={description}
      triggerButton={<></>}
      isLoading={isLoading}
      confirmButton={
        <Button
          onClick={handleConfirm}
          isLoading={isLoading}
          disabled={isLoading}
        >
          {confirmButtonText}
        </Button>
      }
      closeOnClickOutside={!isLoading}
    />
  );
};
