import { Button } from "@/components/Elements";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import * as React from "react";
import { useEffect } from "react";
import { TbX } from "react-icons/tb";
import Joyride, { Step as OriginalStep } from "react-joyride";

type ProductTourProps = {
  steps: ExtendedStep[];
  stepIndex?: number;
  run: boolean;
  setRun: React.Dispatch<React.SetStateAction<boolean>>;
};

export interface ExtendedStep extends OriginalStep {
  shouldShowNextButton?: boolean;
  shouldShowCloseButton?: boolean;
  nextButtonDisabled?: boolean;
  nextButtonText?: string;
  titleIcon?: React.ReactNode;
  customStyles?: React.CSSProperties;
  segmentAction?: string;
}

const joyrideStyles = {
  options: {
    arrowColor: "rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(255, 255, 255, 1)",
    overlayColor: "rgba(0, 0, 0, 0.5)",
    primaryColor: "#000",
    textColor: "#333",
    zIndex: 999999,
  },
  buttonNext: {
    color: "#000",
  },
  buttonBack: {
    color: "#000",
  },
  // Adding styles similar to Popover.tsx
  beacon: {
    zIndex: 999999,
  },
  beaconInner: {
    backgroundColor: "#000",
    opacity: 0.7,
  },
  beaconOuter: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    border: "2px solid #000",
  },
  tooltipContainer: {
    lineHeight: 1.4,
    textAlign: "start",
    boxShadow: "none",
    shadow: "none",
    filter: "none !important",
  },
  tooltipTitle: {
    fontSize: "1.125rem", // 18px
  },
  tooltipContent: {
    padding: "1.25rem 0.625rem", // 20px 10px
  },
  tooltipFooter: {
    alignItems: "start",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "0.9375rem", // 15px
  },
  tooltipFooterSpacer: {
    flex: 1,
  },
  buttonSkip: {
    color: "#333",
    fontSize: "0.875rem", // 14px
  },
  overlay: {
    backgroundColor: "rgb(255 255 255 / 0.8)",
  },
  spotlight: {
    backgroundColor: "gray",
    padding: 0,
    margin: 0,
    border: "rgba(0, 0, 0, 0.1) solid 1.5px",
  },
  floaterStyles: {
    arrow: {
      color: "rgba(5, 150, 105, 1)",
      zIndex: 999,
    },
    options: {
      zIndex: 1000000,
    },
  },
};

const ProductTour: React.FC<ProductTourProps & { showTooltip?: boolean }> = ({
  steps,
  run,
  setRun,
  showTooltip = true,
  stepIndex = -1,
  onNext,
  onClose,
}) => {
  const trackEvent = useTrackEvent();
  const tourRef = React.useRef(null);
  const [prevStepIndex, setPrevStepIndex] = React.useState(stepIndex);

  const handleTrackClose = () => {
    trackEvent.mutate({
      event: "close_product_tour",
      properties: JSON.stringify({}),
    });
  };

  const handleTrackStep = (step: ExtendedStep) => {
    trackEvent.mutate({
      event: "product_tour_step",
      properties: JSON.stringify({
        action: step.segmentAction,
      }),
    });
  };

  useEffect(() => {
    if (prevStepIndex >= 0 && stepIndex > prevStepIndex) {
      handleTrackStep(steps[prevStepIndex]);
    }
    setPrevStepIndex(stepIndex);
  }, [stepIndex]);

  return (
    <div>
      <Joyride
        tooltipComponent={
          showTooltip
            ? ({ step }: { step: ExtendedStep }) => (
                <div
                  ref={tourRef}
                  className="rounded-md w-[400px] bg-emerald-600 dark:bg-zinc-900/50 ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none p-4 shadow-glowLg"
                  style={step.customStyles}
                >
                  {step.shouldShowCloseButton && (
                    <Button
                      variant="none"
                      buttonIcon={<TbX className="w-4 h-4" />}
                      tooltipContent="Close"
                      onClick={() => {
                        handleTrackClose();
                        onClose();
                      }}
                      className="absolute top-2 right-2 rounded-md inline-block h-7 w-7 min-w-8 min-h-8 align-middle items-center p-1 bg-transparent hover:bg-emerald-700 text-white dark:text-white hover:stroke-zinc-900 opacity-50 hover:opacity-100 hover:text-zinc-200 dark:hover:bg-zinc-700 dark:hover:stroke-white dark:hover:text-white focus:outline-none z-10"
                    ></Button>
                  )}
                  <div className="flex">
                    <div className="flex items-center justify-center">
                      {step.titleIcon}
                      <h3 className="text-md font-semibold text-white">
                        {step.title}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-start justify-start mt-2 text-white">
                    <p
                      className="text-sm"
                      dangerouslySetInnerHTML={{
                        __html: step.content,
                      }}
                    ></p>
                  </div>
                  {step.shouldShowNextButton && (
                    <div className="flex items-center justify-end mt-4">
                      <Button
                        size="2xs"
                        variant="none"
                        onClick={() => {
                          onNext();
                        }}
                        disabled={step.nextButtonDisabled ? true : false}
                        className="rounded-md backdrop-blur-lg bg-zinc-100 dark:bg-zinc-900/50 shadow-sm dark:shadow-lg ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-10 ring-opacity-5 py-1 px-3 text-zinc-800 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-200 hover:text-zinc-900 dark:text-white dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none"
                      >
                        {step.nextButtonText}
                      </Button>
                    </div>
                  )}
                </div>
              )
            : null
        }
        floaterProps={{
          styles: {
            arrow: {
              length: showTooltip ? 8 : 0,
              spread: showTooltip ? 16 : 0,
            },
          },
        }}
        styles={joyrideStyles}
        steps={steps}
        stepIndex={stepIndex}
        run={run}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        spotlightClicks={false}
        disableScrollParentFix={true}
        callback={(data) => {
          if (data.status === "finished" || data.status === "skipped") {
            setRun(false);
          }
        }}
      />
    </div>
  );
};

export default ProductTour;
