import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { SiteDashboardData, SiteDashboardResponse } from "../types";

export const getSiteDashboard = async ({
  site_url,
  weeks,
}: {
  site_url: string;
  weeks?: string;
}): Promise<SiteDashboardData> => {
  const response: SiteDashboardResponse = await axios.post(
    "/content-recommendations/site-dashboard",
    { site_url, weeks }
  );

  // Check if the response indicates an error
  if (response.status === "error") {
    throw new Error(
      response.message || "An error occurred fetching site dashboard"
    );
  }

  return response.data as SiteDashboardData;
};

type QueryFnType = typeof getSiteDashboard;

type UseGetSiteDashboardOptions = {
  site_url: string;
  weeks?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useGetSiteDashboard = ({
  site_url,
  weeks,
  config,
}: UseGetSiteDashboardOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["contentOpportunities", "siteDashboard", site_url, weeks],
    queryFn: () => getSiteDashboard({ site_url, weeks }),
  });
};
